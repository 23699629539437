import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Paper,
  TableRow,
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableBody,
} from "@material-ui/core";
import {
  createMuiTheme,
  responsiveFontSizes,
  makeStyles,
} from "@material-ui/core";
import { getTurnosByFecha_Caja_Sucursal, cancelarTurno } from "api";
import swal from "sweetalert2";
import { format, isBefore, addDays } from "date-fns";
import "./tablaResultados.css";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tablecell: {
    fontSize: "12px",
  },
});

const TablaResultados = (props) => {
  const cancelarTurnoProps = props.cancelarTurno;
  let theme = createMuiTheme();
  theme = responsiveFontSizes(theme);
  const classes = useStyles();
  const [turnos, setTurnos] = useState([]);
  const sucursalId = props.params.sucursalId;
  const tipoCajaId = props.params.tramiteId;
  let fecha = props.params.fecha;
  const enSucursal = "";

  async function getTurnosFunc(date, idSucursal, CajaId) {
    const res = await getTurnosByFecha_Caja_Sucursal(
      date,
      idSucursal,
      CajaId,
      date,
      enSucursal
    );
    setTurnos(res.data);
  }

  const [success, setSuccess] = useState(false);
  const [table, setTable] = useState(true);

  function OkMessage(a, b, c) {
    setSuccess(false);
    setTable(true);
  }

  useEffect(() => {
    try {
      getTurnosFunc(fecha, sucursalId, tipoCajaId);
      OkMessage(sucursalId, fecha, tipoCajaId);
    } catch (err) {
      console.log(err);
    }
  }, [sucursalId, fecha, tipoCajaId]);

  const swalTunoYaAtendido = () => {
    swal
      .fire({
        title: "Este turno ya ha sido atendido",
        icon: "warning",
        confirmButtonColor: "#009bdb",
        confirmButtonText: "Volver",
      })
      .then((result) => {
        // history.push("/");
        console.log("ya se atendio");
      });
  };
  async function cancelarButtonFunc(idTurno) {
    try {
      await cancelarTurno(idTurno).then((response) => {
        setUserNombre(response);
        getTurnosFunc(fecha, sucursalId, tipoCajaId);
        setTable(true);
        setSuccess(true);
      });
    } catch (e) {
      if (e.response.status === 400) {
        console.log(e, "error al borrar turno");
        swalTunoYaAtendido();
      }
    }
  }
  function cancelarButton(id) {
    cancelarButtonFunc(id);
  }
  const [idTurno, setIdTurno] = useState();

  const [deshabilitarButton, setDeshabilitarButton] = useState(false);
  const [confirmar, setConfirmar] = useState();
  const [userNombre, setUserNombre] = useState("");
  //const fechaActual = useRef(new Date());

  const today = new Date();
  const todayFormat = format(today, "yyyy-MM-dd");
  const hourNow = format(today, "HH:mm:ss");
  const fechaConsultada = addDays(new Date(fecha), 1);
  const fechaConsultadaFormat = format(fechaConsultada, "yyyy-MM-dd");

  const turnosEnTablaConAccionCancelar = turnos?.content?.map((turno) => {
    let diaVencido = isBefore(fechaConsultada, today);
    if (
      !diaVencido &&
      fechaConsultadaFormat === todayFormat &&
      turno.hora > hourNow &&
      turno.horaInicioAtencion !== null
    ) {
      return { ...turno, disabled: true };
    } else if (
      !diaVencido &&
      fechaConsultadaFormat === todayFormat &&
      turno.hora > hourNow
    ) {
      return { ...turno, disabled: false };
    } else if (
      !diaVencido &&
      fechaConsultadaFormat === todayFormat &&
      turno.hora < hourNow
    ) {
      return { ...turno, disabled: true };
    } else if (!diaVencido) {
      return { ...turno, disabled: false };
    } else if (diaVencido) return { ...turno, disabled: true };
  });

  useEffect(() => {
    isBefore(fechaConsultada, today);
  }, [fechaConsultada, hourNow]);

  function handleCancel(id) {
    swal
      .fire({
        title: "CONFIRMAR OPERACIÓN",
        text: "Está seguro/a que desea cancelar el turno?",
        icon: "warning",
        confirmButtonColor: "#009bdb",
        confirmButtonText: "Sí",
        showCancelButton: true,
        cancelButtonText: "No",
        cancelButtonColor: "#b7b7b7",
      })
      .then((result) => {
        if (result.value) {
          cancelarButton(id);
          swal.fire({
            icon: "success",
            title: "El Turno ha sido Cancelado",
            text: `Se ha cancelado el turno, se notificó la cancelación al email registrado.`,
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#009bdb",
          });
          setTable(true);
        }
      });
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignSelf: "center",
      }}
    >
      <br />
      {table === true && (
        <div>
          <TableContainer component={Paper} sx={{ maxHeight: "400px" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead variant="head">
                <TableRow className={classes.tablecell}>
                  <TableCell
                    id="tableCell"
                    align="center"
                    className={classes.tablecell}
                  >
                    Id Turno
                  </TableCell>
                  <TableCell
                    id="tableCell"
                    align="center"
                    className={classes.tablecell}
                  >
                    Trámite
                  </TableCell>
                  <TableCell
                    id="tableCell"
                    align="center"
                    className={classes.tablecell}
                  >
                    Hora
                  </TableCell>
                  <TableCell
                    id="tableCell"
                    align="center"
                    className={classes.tablecell}
                  >
                    Contacto
                  </TableCell>
                  <TableCell
                    id="tableCell"
                    align="center"
                    className={classes.tablecell}
                  >
                    Documento
                  </TableCell>
                  <TableCell
                    id="tableCell"
                    align="center"
                    className={classes.tablecell}
                  >
                    E-mail
                  </TableCell>
                  <TableCell
                    id="tableCell"
                    align="center"
                    className={classes.tablecell}
                  >
                    Cuenta Servicios
                  </TableCell>
                  <TableCell
                    id="tableCell"
                    align="center"
                    className={classes.tablecell}
                  >
                    Telefono
                  </TableCell>
                  <TableCell
                    id="tableCell"
                    align="center"
                    className={classes.tablecell}
                  >
                    Turno
                  </TableCell>
                  <TableCell
                    id="tableCell"
                    align="center"
                    className={classes.tablecell}
                  >
                    Titular
                  </TableCell>

                  {cancelarTurno === true && (
                    <TableCell
                      id="tableCell"
                      align="center"
                      className={classes.tablecell}
                    ></TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {turnosEnTablaConAccionCancelar &&
                  turnosEnTablaConAccionCancelar.map(
                    ({
                      id,
                      name,
                      caja,
                      hora,
                      cliente,
                      tipoTurno,
                      cancelado,
                      disabled,
                    }) =>
                      cancelado === false && (
                        <TableRow key={name} className={classes.tablecell}>
                          <TableCell
                            id="tableCell"
                            component="th"
                            scope="row"
                            align="center"
                            className={classes.tablecell}
                          >
                            {id}
                          </TableCell>
                          <TableCell
                            id="tableCell"
                            align="center"
                            className={classes.tablecell}
                          >
                            {caja.tipo.nombre}
                          </TableCell>
                          <TableCell
                            id="tableCell"
                            align="center"
                            className={classes.tablecell}
                          >
                            {hora}
                          </TableCell>
                          <TableCell
                            id="tableCell"
                            align="center"
                            className={classes.tablecell}
                          >
                            {`${cliente.nombre.toUpperCase()} ${cliente.apellido.toUpperCase()}`}
                          </TableCell>
                          <TableCell
                            id="tableCell"
                            align="center"
                            className={classes.tablecell}
                          >
                            {cliente.dni}
                          </TableCell>
                          <TableCell
                            id="tableCell"
                            align="center"
                            className={classes.tablecell}
                          >
                            {cliente.email}
                          </TableCell>
                          <TableCell
                            id="tableCell"
                            align="center"
                            className={classes.tablecell}
                          >
                            {cliente.cuenta}
                          </TableCell>
                          <TableCell
                            id="tableCell"
                            align="center"
                            className={classes.tablecell}
                          >
                            {cliente.telefono}
                          </TableCell>
                          <TableCell
                            id="tableCell"
                            align="center"
                            className={classes.tablecell}
                          >
                            {tipoTurno}
                          </TableCell>
                          <TableCell
                            id="tableCell"
                            align="center"
                            className={classes.tablecell}
                          >
                            {cliente.titularCuenta}
                          </TableCell>
                          {cancelarTurnoProps === true && (
                            <TableCell>
                              <Button
                                id={"buttonCancelar"}
                                disabled={disabled}
                                variant="contained"
                                onClick={() => {
                                  setIdTurno(id);
                                  setConfirmar(true);
                                  handleCancel(id);
                                }}
                                style={{
                                  backgroundColor: disabled
                                    ? "#D3D3D3"
                                    : "#F86436",
                                  color: "white",
                                }}
                              >
                                Cancelar
                              </Button>
                            </TableCell>
                          )}
                        </TableRow>
                      )
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};
export default TablaResultados;
