import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Container,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import { withRouter, Link } from "react-router-dom";
import Header from "usuariosOnline/components/Header/Header";
import TablaResultados from "backOfficeUsuariosCau/components/TablaResultados/TablaResultados";
import {
  getSucursalesByTipoCaja,
  getSucursales,
  getTipoCaja,
  getFeriados,
} from "api";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { sucursalesReportesFiltradaArray } from "const/constantes";
import { filterSucursales } from "backOfficeUsuariosCau/components/utils/filterSucursales";
import ButtonEscape from "const/ButtonEscape";

function BuscarTurno(props) {
  const title = "Buscar Turno";
  const welcome = <Typography variant="string">Bienvenido!</Typography>;
  const [clientWidth, setClientWidth] = React.useState("");
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
    },
    textField: {
      marginTop: theme.spacing(6),
    },
  }));
  const classes = useStyles();

  const validation = Yup.object({
    sucursalId: Yup.string().required("requerido"),
    fecha: Yup.string().required("requerido"),
  });

  const formik = useFormik({
    initialValues: {
      sucursalId: "",
      fecha: "",
      tramiteId: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      onClick(values);
    },
  });
  const [sucursales, setSucursales] = useState([]);
  const [tipoDecajas, setTipoDecajas] = useState([]);

  async function getTipoCajaFunc() {
    const res = await getTipoCaja();
    setTipoDecajas(res.data);
  }

  async function getSucursalesFunc(caja) {
    var suc = [];
    if (formik.values.tramiteId !== "") {
      const res = await getSucursalesByTipoCaja(caja);
      suc = res.data;
    }
    if (formik.values.tramiteId === "") {
      const res = await getSucursales(true);
      suc = res.data;
    }

    setSucursales(filterSucursales(sucursalesReportesFiltradaArray, suc));
  }

  const tipoCajaId = formik.values.tramiteId;
  useEffect(() => {
    try {
      getTipoCajaFunc();
      getSucursalesFunc(tipoCajaId);
      setClientWidth(document.documentElement.clientWidth);
      window.history.replaceState(null, null, "/backOffice");
      getFeriadosFunc();
    } catch (err) {
      console.log(err);
    }
  }, [tipoCajaId]); // eslint-disable-line react-hooks/exhaustive-deps

  const [showResults, setShowResults] = React.useState(false);
  const onClick = () => {
    setShowResults(true);
    if (formik.values.sucursalId !== "" && formik.values.fecha !== "") {
      setShowButton(!showButton);
    }
  };

  const [feriados, setFeriados] = useState([]);

  async function getFeriadosFunc() {
    const res = await getFeriados();
    setFeriados(res.data);
  }

  function populateFeriados(holiday) {
    const feriadoData = [];
    if (holiday) {
      for (let a = 0; a < holiday.length; a++) {
        const day = holiday[a].fecha.replace(/-/g, "/");
        feriadoData.push(new Date(day));
      }
    }
    return feriadoData;
  }
  const [showButton, setShowButton] = useState(true);
  const width = clientWidth > 415 ? "35%" : "80%";

  return (
    <Container fixed>
      <div>
        <Header title={title} welcome={welcome} button={<ButtonEscape />} />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Grid
          style={{
            width: "100%",
          }}
          container
        >
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            item
            xs={1}
          >
            {" "}
            <Link
              to="/backOfficeL"
              style={{ textDecoration: "none", marginLeft: "75%" }}
            >
              <Button
                style={{
                  backgroundColor: "blue",
                  color: "white",

                  textTransform: "none",
                }}
                variant="contained"
              >
                Volver
              </Button>
            </Link>
          </Grid>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            item
            xs={10}
          >
            <form
              onSubmit={formik.handleSubmit}
              style={{ alignSelf: "center", width: width, marginTop: "2%" }}
            >
              <FormControl className={classes.formControl}>
                <InputLabel style={{ fontSize: "16pt", fontFamily: "Roboto" }}>
                  Tipo Trámite ( opcional)
                </InputLabel>
                <Select
                  id="tramiteId"
                  name="tramiteId"
                  value={formik.values.tramiteId}
                  onChange={formik.handleChange}
                >
                  {tipoDecajas &&
                    tipoDecajas.map((tipo) => (
                      <MenuItem key={`tipo_${tipo.id}`} value={tipo.id}>
                        {tipo.nombre}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel style={{ fontSize: "16pt", fontFamily: "Roboto" }}>
                  Centro de Atención (obligatorio)
                </InputLabel>
                <Select
                  className={classes.textField}
                  id="sucursalId"
                  name="sucursalId"
                  value={formik.values.sucursalId}
                  onChange={formik.handleChange}
                  helperText={formik.errors.sucursalId || "*Campo requerido"}
                  error={formik.errors.sucursalId}
                >
                  {sucursales &&
                    sucursales.map((sucursal) => (
                      <MenuItem
                        key={`sucursal_${sucursal.id}`}
                        value={sucursal.id}
                      >
                        {` ${sucursal.nombre} - ${sucursal.localidad.nombre} - ${sucursal.direccion}`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl className={classes.formControl}>
                <InputLabel style={{ fontSize: "16pt", fontFamily: "Roboto" }}>
                  Fecha (obligatorio)
                </InputLabel>
                <TextField
                  className={classes.textField}
                  id="fecha"
                  type="date"
                  value={formik.values.fecha}
                  selected={formik.values.fecha}
                  onChange={formik.handleChange}
                  helperText={formik.errors.fecha}
                  error={formik.errors.fecha}
                  excludeDates={populateFeriados(feriados)}
                />
              </FormControl>

              {showButton && (
                <div>
                  <Button
                    style={{
                      marginLeft: "35%",
                      fontSize: "16pt",
                      textTransform: "none",
                      fontWeight: "bold",
                      fontFamily: "Roboto",
                      backgroundColor: "#0055a6",
                      marginTop: "30px",
                      width: "30%",
                      marginBottom: "30px",
                    }}
                    id="buttom"
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={onClick}
                  >
                    Buscar
                  </Button>
                </div>
              )}
            </form>
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          marginBottom: "80px",
        }}
      >
        <Container
          fixed
          style={{
            marginTop: "80px",
          }}
        >
          {showButton === false &&
            showResults === true &&
            formik.values.sucursalId !== "" &&
            formik.values.fecha !== "" && (
              <TablaResultados params={formik.values} buscarTurnos={true} />
            )}
        </Container>
      </div>
    </Container>
  );
}
export default withRouter(BuscarTurno);
