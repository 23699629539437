import React, { useEffect, useState } from "react";
import { Grid, Container } from "@material-ui/core";
import Header from "usuariosOnline/components/Header/Header";
import { emitirReportesString } from "const/constantes";
import "./BackOfficeLogueado.css";
import BotonConLink from "backOfficeUsuariosCau/components/BotonConLink/BotonConLink";
import SesionExpirada from "backOfficeUsuariosCau/components/SesionExpirada/SesionExpirada";
import { usuarioAtencionActiva } from "api";
import UsuarioBloqueadoRealizandoTramite from "backOfficeUsuariosCau/components/UsuarioBloqueadoRealizandoTramite/UsuarioBloqueadoRealizandoTramite";
import { botonesBackofficeHabilitados } from "../utils/botonesBackofficeHabilitados";
import ButtonEscape from "const/ButtonEscape";

export const BackOfficeLoguado = (props) => {
  const [habilitadoAdmin, setHabilitadoAdmin] = useState();
  const [habilitadoReporte, setHabilitadoReporte] = useState();
  const [tramiteEnCurso, setTramiteEnCurso] = useState(false);

  async function usuarioInfoTramiteEnCurso() {
    const res = await usuarioAtencionActiva();
    setTramiteEnCurso(res.data.bloqueo);
  }

  useEffect(() => {
    usuarioInfoTramiteEnCurso();
  }, []);

  useEffect(() => {
    try {
    } catch (err) {
      console.loo(err);
    }
  }, []);

  const reportes = sessionStorage.getItem(emitirReportesString);

  const logeado = sessionStorage.getItem("logeado");

  const texto = logeado === "false" ? "Volver" : "Salir";
  const welcome = "Bienvenido !";

  return (
    <Container fixed>
      <div>
        <Header welcome={welcome} button={<ButtonEscape />} mainPage={true} />
      </div>

      {logeado === "true" && (
        <>
          <div>
            {tramiteEnCurso && <UsuarioBloqueadoRealizandoTramite />}
            <SesionExpirada timer={900000} />
            <Grid
              container
              direction="column"
              style={{
                marginTop: "2%",
                marginLeft: "20%",
              }}
              className="grid__responsive"
            >
              {botonesBackofficeHabilitados.map(
                (btn) =>
                  btn.accion === "true" && (
                    <BotonConLink
                      key={btn.id}
                      botonNombre={btn.title}
                      link={btn.link}
                      color="#0055a6"
                    />
                  )
              )}

              <br />
              <br />
              <br />
              {reportes === "true" && (
                <BotonConLink
                  botonNombre=" Reportes"
                  link="/reportes"
                  color="#1e90ff"
                />
              )}
            </Grid>
            <br />
            <br />
          </div>
        </>
      )}

      {(logeado === "false" || logeado === null) && (
        <div style={{ marginLeft: "10%", marginTop: "4%" }}>
          <h2>Por favor iniciar la sesión .</h2>
        </div>
      )}
    </Container>
  );
};

export default BackOfficeLoguado;
