import {
  administrarBreakString,
  verAgendaString,
  deshabilitarCAUString,
  actualizarPuestosString,
  actualizarFeriadosString,
  turnoInSituString,
  administrarTiemposDeAtencionString,
  administrarPuestosTotalesString,
  bloquearHorariosString,
  verTurnosEnElDiaString,
} from "const/constantes";
import { format } from "date-fns";

const fecha = format(new Date(), "dd/MM/yyyy");

export const botonesBackofficeHabilitados = [
  {
    id: 1,
    name: "verAgenda",
    title: "Ver Agenda (Ver, Agregar y Cancelar)",
    accion: sessionStorage.getItem(verAgendaString),
    link: "/cancelarTurno",
  },
  {
    id: 2,
    name: "actualizarPuestos",
    title: "Actualizar Puestos",
    accion: sessionStorage.getItem(actualizarPuestosString),
    link: "/actualizarPuestos",
  },
  {
    id: 3,
    name: "deshabiltarCAU",
    title: "Habilitar/Deshabilitar CAU",
    accion: sessionStorage.getItem(deshabilitarCAUString),
    link: "/cancelarCAU",
  },
  {
    id: 4,
    name: "administarFeriados",
    title: "Administrar Feriados - No Laborables",
    accion: sessionStorage.getItem(actualizarFeriadosString),
    link: "/actualizarFeriados",
  },
  {
    id: 5,
    name: "administrarTiemposDeAtencion",
    title: "Tiempos de atención",
    accion: sessionStorage.getItem(administrarTiemposDeAtencionString),
    link: "/administrarTiemposDeAtencion",
  },
  {
    id: 6,
    name: "administrarPuestosTotales",
    title: " Puestos Totales",
    accion: sessionStorage.getItem(administrarPuestosTotalesString),
    link: "/administrarPuestosTotales",
  },
  {
    id: 7,
    name: "bloquearHorarios",
    title: " Bloquear Horarios",
    accion: sessionStorage.getItem(bloquearHorariosString),
    link: "/bloquearHorarios",
  },
  {
    id: 8,
    name: "turnoInSitu",
    title: "Turno Espontáneo",
    accion: sessionStorage.getItem(turnoInSituString),
    link: "/turnoEnCau",
  },
  {
    id: 9,
    name: "administrarBreak",
    title: " Administrar Break",
    accion: sessionStorage.getItem(administrarBreakString),
    link: "/administrarBreak",
  },

  {
    id: 10,
    name: "verAtencionDeTurnosEnElDia",
    title: `Atención de turnos al ${fecha}`,
    accion: sessionStorage.getItem(verTurnosEnElDiaString),
    link: "/verTurnosEnElDia",
  },
  {
    id: 11,
    name: "programarTurno",
    title: `Programar Turno`,
    accion: sessionStorage.getItem(turnoInSituString),
    link: "/programarTurno",
  },
];
