import swal from "sweetalert2";
import { actualUrl } from "backOfficeUsuariosCau/components/BackOffice/BackOffice";

export function swalErrorToken() {
  swal
    .fire({
      position: "center",
      icon: "warning",
      title: "Sesion Expirada",
      text: "Su sesion ha expirado, por favor vuelva a loguearse",
      confirmButtonColor: "#009bdb",
      confirmButtonText: "OK",
    })
    .then((result) => {
      if (result.isConfirmed) {
        //sessionStorage.setItem("logeado", false);
        window.location.href = actualUrl;
        sessionStorage.clear();
        localStorage.clear();
      }
    });
}
