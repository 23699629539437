import React from "react";
import { Button, Box } from "@material-ui/core";
import { Link } from "react-router-dom";

const BotonConLink = ({ botonNombre, link, color }) => {
  return (
    <Box mt={2} style={{ width: "100%" }}>
      <Link style={{ textDecoration: "none", color: "white" }} to={link}>
        <Button
          style={{
            backgroundColor: color,
            color: "white",
            width: "60%",
          }}
          variant="contained"
          className="button_responsive"
        >
          {botonNombre}
        </Button>
      </Link>
    </Box>
  );
};

export default BotonConLink;
