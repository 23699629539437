import React, { useState, useEffect, useRef } from "react";
import swal from "sweetalert2";
import { finalizarTurnoAtencion } from "api";
import { red } from "@material-ui/core/colors";
import { format, formatDistanceToNow, intervalToDuration } from "date-fns";

const Cronometro = ({
  numeroTurno,
  setTurnoActivo,
  reload,
  setReload,
  setShowResults,
}) => {
  const [timer, setTimer] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [horarioInicioDeAtencion, setHorarioInicioDeAtencion] = useState("");
  const [
    horarioInicioDeAtencionSinFormato,
    setHorarioInicioDeAtencionSinFormato,
  ] = useState("");
  const increment = useRef(null);
  const nuevoNumero = useRef(numeroTurno);

  useEffect(() => {
    handleReset();
    handleStart();
  }, []);

  const handleStart = () => {
    setIsActive(true);
    setIsPaused(true);
    increment.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  const handlePause = () => {
    clearInterval(increment.current);
    setIsPaused(false);
  };

  const handleResume = () => {
    setIsPaused(true);
    increment.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  const handleReset = () => {
    clearInterval(increment.current);
    setIsActive(false);
    setIsPaused(false);
    setTimer(0);
  };

  const formatTime = () => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours} : ${getMinutes} : ${getSeconds}`;
  };

  useEffect(() => {
    window.onbeforeunload = confirmExit;
    function confirmExit() {
      return "show warning";
    }
  }, []);

  const finalizarAtencioAsync = async () => {
    await finalizarTurnoAtencion();
    setReload(!reload);
    setTurnoActivo(false);
    setShowResults(true);
    console.log(reload);
  };

  const swalFinalizarTurno = () => {
    swal
      .fire({
        title: "<h3 style='color:rgb(179, 18, 18)'>Confirmar la Operación</h3>",
        html: "¿Esta seguro/a que desea finalizar el tramite?",
        icon: "warning",
        confirmButtonText: "Si",
        showCancelButton: true,
        cancelButtonText: "No",
      })
      .then((result) => {
        if (result.isConfirmed) {
          handlePause();
          finalizarAtencioAsync();
          /* setReload(!reload);
          setTurnoActivo(false);
          finalizarTurnoAtencion();
          setShowResults(true);
          console.log(reload); */
          swal.fire({
            title: `<h3 style="font-weight: 500; color: rgb(20, 20, 20);">El tramite con el Número ${nuevoNumero.current} fue finalizado</h3>`,
            confirmButtonText: "Ver listado de trámites",
            confirmButtonColor: "rgb(248, 100, 54)",
            timer: 5000,
            html: `
                  <div className="finalizar" style="display: flex; align-items: center; justify-content: center; gap: 4px; margin-top: 10px;">
                    <svg
                        className="finalizar__reloj"
                        version="1.1"
                        id="Capa_1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 1200 1200"
                        enable-background="new 0 0 1200 1200"
                        style="width:50px;"
                        width="50px"
                      >
                        <g>
                          <path
                            fill="rgb(16, 117, 16)"
                            d="M600,188.09c-227.13,0-411.914,184.784-411.914,411.91c0,227.133,184.784,411.917,411.914,411.917
                                    S1011.914,827.133,1011.914,600C1011.914,372.873,827.13,188.09,600,188.09z M871.688,626.761H573.239V327.238
                                    c0-14.779,11.982-26.761,26.761-26.761s26.761,11.982,26.761,26.761v246.002h244.927c14.779,0,26.761,11.982,26.761,26.761
                                    S886.466,626.761,871.688,626.761z"
                          />
                          <path
                            fill="rgb(16, 117, 16)"
                            d="M600,50C296.73,50,50,296.727,50,600s246.73,550,550,550s550-246.727,550-550S903.27,50,600,50z
                                    M600,1065.438c-256.641,0-465.435-208.794-465.435-465.438c0-256.638,208.794-465.432,465.435-465.432
                                    S1065.435,343.362,1065.435,600C1065.435,856.644,856.641,1065.438,600,1065.438z"
                          />
                        </g>
                      </svg>
                    <div style="color: rgb(16, 117, 16); font-size: 25px; font-weight: bold;">
                      ${formatTime2()}
                    </div>
                    </div>
                    `,
          });
        } else if (result.isDenied) {
          // setDuracionTurno("");
        }
      });
  };

  useEffect(() => {
    const horaIncio = format(new Date(), "HH:mm:ss");
    setHorarioInicioDeAtencion(horaIncio);
    const horaDeInicioSinFormato = format(new Date(), "yyyy-MM-dd HH:mm:ss");
    setHorarioInicioDeAtencionSinFormato(horaDeInicioSinFormato);
  }, []);

  const now = new Date(format(new Date(), "yyyy-MM-dd HH:mm:ss"));
  const miHorario = new Date(horarioInicioDeAtencionSinFormato);
  const diff = now.getTime() - miHorario.getTime();
  const diffInSeconds = Math.floor(diff / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);

  const formatTime2 = () => {
    const seconds = `${Math.floor(diff / 1000)}`;
    const getSeconds = `0${seconds % 60}`.slice(-2);
    const minutes = `${Math.floor(seconds / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const hours = `${Math.floor(minutes / 60)}`;
    const getHours = `0${Math.floor(hours / 3600)}`.slice(-2);

    return `${getHours} : ${getMinutes} : ${getSeconds}`;
  };

  return (
    <div className="cronometro-dentro">
      <div>Se inició trámite con el Número {nuevoNumero.current}</div>
      <div>Hora {horarioInicioDeAtencion} hs</div>
      <div className="cronometro-media">
        <div>
          <svg
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            viewBox="0 0 1200 1200"
            enable-background="new 0 0 1200 1200"
            width={"40px"}
          >
            <g>
              <path
                fill="rgb(16, 117, 16)"
                d="M600,188.09c-227.13,0-411.914,184.784-411.914,411.91c0,227.133,184.784,411.917,411.914,411.917
                        S1011.914,827.133,1011.914,600C1011.914,372.873,827.13,188.09,600,188.09z M871.688,626.761H573.239V327.238
                        c0-14.779,11.982-26.761,26.761-26.761s26.761,11.982,26.761,26.761v246.002h244.927c14.779,0,26.761,11.982,26.761,26.761
                        S886.466,626.761,871.688,626.761z"
              />
              <path
                fill="rgb(16, 117, 16)"
                d="M600,50C296.73,50,50,296.727,50,600s246.73,550,550,550s550-246.727,550-550S903.27,50,600,50z
                        M600,1065.438c-256.641,0-465.435-208.794-465.435-465.438c0-256.638,208.794-465.432,465.435-465.432
                        S1065.435,343.362,1065.435,600C1065.435,856.644,856.641,1065.438,600,1065.438z"
              />
            </g>
          </svg>
        </div>
        <p className="cronometro-time">
          {/*  <p>{formatTime()}</p> */}
          <p>{formatTime2()}</p>
        </p>
      </div>
      <div className="cronometro-buttons">
        <button className="cronometro-button" onClick={swalFinalizarTurno}>
          Finalizar Tramite
        </button>
      </div>
    </div>
  );
};

export default Cronometro;
