import React, { useState, useEffect } from "react";
import { Grid, Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import Header from "usuariosOnline/components/Header/Header";
import {
  getSucursales,
  getSucursalesByTipoCaja,
  getFeriados,
  getTipoCaja,
} from "api";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  Typography,
} from "@material-ui/core";
import TablaResultados from "backOfficeUsuariosCau/components/TablaResultados/TablaResultados";
import { makeStyles } from "@material-ui/core/styles";
import { filterSucursales } from "backOfficeUsuariosCau/components/utils/filterSucursales";
import { sucursalesVerAgendaFiltradaArray } from "const/constantes";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "./CancelarTurno.css";
import SesionExpirada from "backOfficeUsuariosCau/components/SesionExpirada/SesionExpirada";
import ButtonEscape from "const/ButtonEscape";

const CancelarTurno = () => {
  const welcome = "Bienvenido !";
  const title = "Ver, Cancelar y Agregar Turnos";

  const [clientWidth, setClientWidth] = React.useState("");
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
    },
    textField: {
      marginTop: theme.spacing(6),
    },
  }));
  const classes = useStyles();

  const validation = Yup.object({
    sucursalId: Yup.string().required("requerido"),
    fecha: Yup.string().required("requerido"),
  });

  const formik = useFormik({
    initialValues: {
      sucursalId: "",
      fecha: "",
      tramiteId: "",
    },
    validationSchema: validation,
  });
  const [sucursales, setSucursales] = useState([]);
  const [tipoDecajas, setTipoDecajas] = useState([]);

  async function getTipoCajaFunc() {
    const res = await getTipoCaja(true);
    setTipoDecajas(res.data);
  }

  async function getSucursalesFunc(caja) {
    var suc = [];
    if (formik.values.tramiteId != "") {
      const res = await getSucursalesByTipoCaja(caja);
      suc = res.data;
    }
    if (formik.values.tramiteId == "") {
      const res = await getSucursales(true);
      suc = res.data;
    }

    setSucursales(filterSucursales(sucursalesVerAgendaFiltradaArray, suc));
  }

  const [tipoCajaId, setTipoCajaId] = useState();

  useEffect(() => {
    try {
      setClientWidth(document.documentElement.clientWidth);
      getFeriadosFunc();
      getTipoCajaFunc();
      getSucursalesFunc(tipoCajaId);
    } catch (err) {
      console.log(err);
    }
  }, [tipoCajaId]); // eslint-disable-line react-hooks/exhaustive-deps

  const [showResults, setShowResults] = React.useState(false);
  const onClick = () => {
    formik.handleSubmit();
    setShowResults(true);
    if (formik.values.sucursalId != "" && formik.values.fecha != "") {
      setShowButton(!showButton);
    }
  };

  const [feriados, setFeriados] = useState([]);

  async function getFeriadosFunc() {
    const res = await getFeriados();
    setFeriados(res.data);
  }

  function populateFeriados(holiday) {
    const feriadoData = [];
    if (holiday) {
      for (let a = 0; a < holiday.length; a++) {
        const day = holiday[a].fecha.replace(/-/g, "/");
        feriadoData.push(new Date(day));
      }
    }
    return feriadoData;
  }
  const [showButton, setShowButton] = useState(true);
  // const width = clientWidth > 415 ? "35%" : "80%";

  return (
    <Container fixed>
      <div>
        <Header welcome={welcome} button={<ButtonEscape />} />
      </div>

      <div
        style={{
          marginTop: "2%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <SesionExpirada timer={900000} />
        <Grid
          container
          style={{ marginLeft: "2%" }}
          className="grid__responsive-title-cancelar-cau"
        >
          <Grid item xs={2}>
            <Link to="/backOfficeL" style={{ textDecoration: "none" }}>
              <Button
                startIcon={<ArrowBackIcon />}
                style={{
                  textTransform: "none",
                  backgroundColor: "white",
                  color: "#009BDB",
                }}
              >
                Volver
              </Button>
            </Link>
          </Grid>
          <Grid item xs={10}>
            <p
              style={{
                marginTop: "5px",
                fontSize: "14px",
                marginLeft: "11.5%",
              }}
            >
              <b style={{ fontSize: "19px" }}>Agenda de Turnos</b> (Ver, Agregar
              y Cancelar)
            </p>
          </Grid>
        </Grid>
        <br />
        <Grid container>
          <Grid item xs={7}></Grid>
          <Grid item xs={5} className="grid__nuevo-turno-cancelar-cau">
            <Link
              to="/programarTurno"
              style={{
                textDecoration: "none",

                alignItems: "center",
              }}
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{
                  backgroundColor: "#009BDB",
                  alignItems: "center",
                  width: "35%",
                }}
                className="btn__nuevo-turno-cancelar-cau"
              >
                <Typography
                  variant="p"
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Roboto",
                    textTransform: "none",
                    color: "white",
                  }}
                >
                  Nuevo Turno
                </Typography>
              </Button>
            </Link>
          </Grid>
        </Grid>
        <br />
        <Grid container>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            item
            xs={12}
          >
            <form
              onSubmit={formik.handleSubmit}
              style={{ alignSelf: "center", width: "45%", marginTop: "2%" }}
              className="form__responsive-cancerlar-cau"
            >
              <FormControl className={classes.formControl}>
                <InputLabel
                  style={{ fontSize: "16pt", fontFamily: "Roboto" }}
                  className="label__cancelar-cau"
                >
                  Tipo Trámite (opcional)
                </InputLabel>
                <Select
                  id="tramiteId"
                  name="tramiteId"
                  value={formik.values.tramiteId}
                  onChange={formik.handleChange}
                >
                  {tipoDecajas &&
                    tipoDecajas.map((tipo) => (
                      <MenuItem
                        onClick={() => setTipoCajaId(tipo.id)}
                        key={`tipo_${tipo.id}`}
                        value={tipo.id}
                      >
                        {tipo.nombre}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel
                  style={{ fontSize: "16pt", fontFamily: "Roboto" }}
                  className="label__cancelar-cau"
                >
                  Centro de Atención (obligatorio)
                </InputLabel>
                <Select
                  className={classes.textField}
                  id="sucursalId"
                  name="sucursalId"
                  value={formik.values.sucursalId}
                  onChange={formik.handleChange}
                  helperText={formik.errors.sucursalId || "*Campo requerido"}
                  error={formik.errors.sucursalId}
                >
                  {sucursales &&
                    sucursales.map((sucursal) => (
                      <MenuItem
                        key={`sucursal_${sucursal.id}`}
                        value={sucursal.id}
                      >
                        {sucursal.localidad.nombre === "GRANDES USUARIOS"
                          ? ` ${sucursal.nombre} - ${sucursal.localidad.nombre}`
                          : ` ${sucursal.nombre} - ${sucursal.localidad.nombre} - ${sucursal.direccion}`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl className={classes.formControl}>
                <InputLabel
                  style={{ fontSize: "16pt", fontFamily: "Roboto" }}
                  className="label__cancelar-cau"
                >
                  Fecha (obligatorio)
                </InputLabel>
                <TextField
                  className={classes.textField}
                  id="fecha"
                  type="date"
                  value={formik.values.fecha}
                  selected={formik.values.fecha}
                  onChange={formik.handleChange}
                  helperText={formik.errors.fecha}
                  error={formik.errors.fecha}
                  excludeDates={populateFeriados(feriados)}
                />
              </FormControl>
            </form>
          </Grid>
        </Grid>
        <br />
        <Grid container>
          <Grid item xs={9}></Grid>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            item
            xs={3}
            className="container__buscar-cancelar-cau "
          >
            {showButton == true && (
              <Button
                style={{
                  marginRight: "150%",
                  alignSelf: "center",
                  backgroundColor: "#0055a6",
                  color: "white",
                  width: "35%",
                }}
                onClick={onClick}
                variant="contained"
                className="btn__buscar-cancelar-turno"
              >
                Buscar
              </Button>
            )}
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          marginBottom: "80px",
        }}
      >
        <Container
          fixed
          style={{
            marginTop: "70px",
          }}
        >
          {showButton == false &&
            showResults == true &&
            formik.values.sucursalId != "" &&
            formik.values.fecha != "" && (
              <TablaResultados params={formik.values} cancelarTurno={true} />
            )}
        </Container>
      </div>
    </Container>
  );
};
export default CancelarTurno;
