import React from "react";
import { TextField } from "@material-ui/core";

const Input = (props) => {
  return (
    <div>
      <TextField
        inputProps={{
          autoComplete: "off",
        }}
        style={{ fontFamily: "Roboto" }}
        autoComplete="nope"
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name={props.name}
        label={props.label}
        type={props.type || "text"}
        placeholder={props.placeholder || props.label}
        value={props.value}
        onChange={props.onChange}
        helperText={props.helperText}
        error={props.error}
      />
      {props.hasError && (
        <span className="invalid-feedback">{props.error}</span>
      )}
    </div>
  );
};

export default Input;
