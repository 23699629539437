import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Paper, Button, Divider, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import swal from "sweetalert2";
import Header from "usuariosOnline/components/Header/Header";
import FormContacto from "usuariosOnline/components/FormContacto/FormContacto";
import { resetCajaValues } from "store/slices/cajaSlice";
import { resetUserValues } from "store/slices/userSlice";
import { resetTurnoValues } from "store/slices/turnoSlice";
import { resetTurnoConfirmadoValues } from "store/slices/turnoConfirmadoSlice";
import SelectTipoCaja from "usuariosOnline/components/SelectTipoCaja/SelectTipoCaja";
import { createTurno } from "api";
import { setTurnoConfirmado } from "store/slices/turnoConfirmadoSlice";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SesionExpirada from "backOfficeUsuariosCau/components/SesionExpirada/SesionExpirada";
import FormPaso3TurnoProgramadoCau from "./FormPaso3TurnoProgramadoCau";
import { filterSucursales } from "backOfficeUsuariosCau/components/utils/filterSucursales";

const welcome = "Bienvenido !";
const button = (
  <Link
    to="/backOffice"
    style={{ textDecoration: "none", textTransform: "none" }}
  >
    <Button
      variant="contained"
      style={{
        textTransform: "none",
        color: "white",
        backgroundColor: "#F86436",
      }}
    >
      Salir
    </Button>
  </Link>
);
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  button: {
    marginTop: theme.spacing(1),
    border: "1px solid",
    fontFamily: "Roboto",
  },
  nextButton: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(3),
    fontFamily: "Roboto",
    color: "white",
    background: "#009bdb",
    "&:hover": {
      background: "#009bdb",
    },
  },
  buttonBack: {
    marginTop: theme.spacing(1),
    fontFamily: "Roboto",
    marginRight: theme.spacing(2),
    color: "white",
    background: "#009bdb",
    "&:hover": {
      background: "#009bdb",
    },
  },
  newTurnButton: {
    marginTop: theme.spacing(1),
    fontFamily: "Roboto",
    color: "white",
    background: "#009bdb",
    "&:hover": {
      background: "#009bdb",
    },
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  buttonBackContainer: {
    display: "flex",
    marginLeft: "6%",
    marginBottom: "-13%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "-20%",
    },
  },
}));

function getSteps() {
  return ["Datos de contacto", "Tipo de trámite", "Centro de Atención"];
}

function getStepContent(step, userStep) {
  switch (step) {
    case 0:
      return <FormContacto />;
    case 1:
      return (
        (userStep && <SelectTipoCaja />) || "Complete información de contacto"
      );
    case 2:
      return userStep && <FormPaso3TurnoProgramadoCau />;

    default:
      return "Unknown step";
  }
}
const ProgramarTurnoEnCau = () => {
  const userStep = useSelector((state) => state.user.submitted);
  const tipoCajaStep = useSelector((state) => state.caja.submitted);
  const ultimoStep = useSelector((state) => state.turno.submitted);
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const handleNext = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (ultimoStep) {
      await createTurnoFunc(
        JSON.parse(localStorage.getItem("objetCrearTurno"))
      );
    }
  };

  const dispatch = useDispatch();
  const handleReset = () => {
    setActiveStep(0);
    dispatch(resetUserValues());
    dispatch(resetCajaValues());
    dispatch(resetTurnoValues());
    dispatch(resetTurnoConfirmadoValues());
  };

  const handleCancel = () => {
    swal
      .fire({
        title: "Cancelar Operación",
        text: "¿Está seguro que desea salir de esta operación?",
        icon: "warning",
        confirmButtonColor: "#009bdb",
        confirmButtonText: "Sí",
        showCancelButton: true,
        cancelButtonText: "No",
        cancelButtonColor: "#b7b7b7",
        animation: true,
      })
      .then((result) => {
        if (result.value) {
          handleReset();
          window.location.reload();
        }
      });
  };
  const checkStep = (step) => {
    if (step === 0 && !userStep) {
      return true;
    } else if (step === 1 && !tipoCajaStep) {
      return true;
    } else if (step === 2 && !ultimoStep) {
      return true;
    } else {
      return false;
    }
  };

  async function createTurnoFunc(obj) {
    try {
      const res = await createTurno(obj);
      const { id } = res.data;
      dispatch(setTurnoConfirmado(id));
      localStorage.removeItem("objetCrearTurno");
      swal.fire({
        position: "center",
        icon: "success",
        title: "El turno ha sido agendado éxitosamente",
        showConfirmButton: false,
        timer: 3000,
      });
    } catch (e) {}
  }

  return (
    <Container>
      <Header welcome={welcome} button={button} />
      <div>
        <SesionExpirada />
        <div style={{ display: "flex" }}>
          <div>
            <Link
              to="/backOfficeL"
              style={{ textDecoration: "none", textTransform: "none" }}
            >
              <Button
                startIcon={<ArrowBackIcon />}
                style={{
                  textTransform: "none",
                  backgroundColor: "white",
                  color: "#009BDB",
                  marginLeft: "21%",
                  marginTop: "25%",
                }}
                onClick={handleReset}
              >
                Volver
              </Button>
            </Link>
          </div>
          <h1
            style={{
              fontFamily: "Roboto",
              paddingLeft: "10%",
              fontWeight: 100,
            }}
            className="title__turno-insitu"
          >
            Programá un Turno desde el CAU
          </h1>
        </div>
      </div>
      <div style={{ paddingLeft: "10%" }}>{/*  <Divider /> */}</div>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        <Divider />
      </div>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Paper square elevation={0} className={classes.resetContainer}>
              <div style={{ display: "flex", justifyContent: "center" }}></div>
              <p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className={classes.buttonBackContainer}>
                    {" "}
                    <Link
                      to="/backOfficeL"
                      style={{ textDecoration: "none", textTransform: "none" }}
                    >
                      <Button
                        variant="contained"
                        className={classes.buttonBack}
                        onClick={handleReset}
                      >
                        Volver
                      </Button>
                    </Link>
                  </div>
                  <Button
                    onClick={handleReset}
                    className={classes.newTurnButton}
                    variant="contained"
                    color="primary"
                  >
                    Sacar Otro Turno
                  </Button>
                </div>
              </p>
            </Paper>
          </div>
        ) : (
          <div>
            <div>{getStepContent(activeStep, userStep)}</div>

            <div className={classes.actionsContainer}>
              <div
                style={{ marginTop: "2%", alignSelf: "flex-end", width: "60%" }}
                className="botonera__insitu"
              >
                <Button
                  variant="outlined"
                  onClick={handleCancel}
                  className={classes.button}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  className={classes.nextButton}
                  disabled={checkStep(activeStep)}
                >
                  Siguiente
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default ProgramarTurnoEnCau;
