import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

const ButtonEscape = () => {
  return (
    <Link
      to="/backOffice"
      style={{ textDecoration: "none", textTransform: "none" }}
      onClick={() => {
        sessionStorage.clear();
        localStorage.clear();
      }}
    >
      <Button
        variant="contained"
        style={{
          textTransform: "none",
          color: "white",
          backgroundColor: "#F86436",
        }}
      >
        Salir
      </Button>
    </Link>
  );
};

export default ButtonEscape;
