import axios from "axios";
import { swalErrorToken } from "backOfficeUsuariosCau/components/utils/swalErrorToken";

const dataHeader = sessionStorage.token ? `Bearer ${sessionStorage.token}` : "";

/* const tokenLocal =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyQ3JlZGVudGlhbHMiOnsidXN1YXJpbyI6IkNhcm9sIE5BVklBIiwiaWQiOiIxQ0M2QUVDMS1ERjlDLTRGQUYtQjA1OS03RDk5ODJFNEFGRjQifSwic3ViIjoiQ2Fyb2wgTkFWSUEiLCJBY2NlcHRlZFRlcm1zQ29uZCI6dHJ1ZSwiaWF0IjoxNzE5NDk2MjI2LCJleHAiOjE3MTk1Mzk0MjZ9.5pDs4rZj4aaPMF3huNrVMMnAhGDcsqE9zkaL0HnUT4E";
const dataHeader = sessionStorage.token ? `Bearer ${tokenLocal}` : ""; */

export const api = axios.create({
  baseURL: "/api/v1/",
  headers: {
    "Content-Type": "application/json",
    Authorization: dataHeader,
    usuario: sessionStorage.username,
    emailAtencion: sessionStorage.emailAtencion,
  },
});

api.interceptors.request.use(
  function (config) {
    // Haz algo antes que la petición se ha enviada
    // console.log("estoy usando interceptor");
    return config;
  },
  function (error) {
    // Haz algo con el error de la petición
    // console.log("el error del interceptor");
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      console.log("error de la response del interceptor", error.response);
      swalErrorToken();
    }
    return Promise.reject(error);
  }
);

export const getSucursales = async (backoffice) => {
  if (backoffice === true) {
    const url = `sucursales/findAll?quitarDeshabilitadas=false`;
    return await api.get(url);
  } else {
    const url = `sucursales/findAll`;
    return await api.get(url);
  }
};

export const getSucursaleById = async (id) => {
  const url = `sucursales/${id}`;
  return await api.get(url);
};

export const enableSucursal = async (id, enable) => {
  const url = `sucursales/enableSucursal?sucursalId=${id}&enabled=${enable}`;
  return await api.post(url);
};

export const getFeriados = async () => {
  const url = `feriados`;
  return await api.get(url);
};

export const getFeriadosPaginables = async (page, size) => {
  const url = `feriados/findAll?page=${page}&size=${size}`;
  return await api.get(url);
};
export const getDiasFull = async (
  fechaFin,
  fechaInicio,
  sucursalId,
  tipoCajaId
) => {
  const url = `turnos/fechasOcupadas?fechaFin=${fechaFin}&fechaInicio=${fechaInicio}&sucursalId=${sucursalId}&tipoCajaId=${tipoCajaId}`;
  return await api.get(url);
};

export const getTipoCaja = async (habilitado = "") => {
  if (habilitado === "") {
    return await api.get(`tiposCaja`);
  } else {
    return await api.get(`tiposCaja?habilitado=${habilitado}`);
  }
};

export const getTurnosDisponibles = async (fecha, sucursalId, tipoCajaId) => {
  const url = `turnos/disponibles?fecha=${fecha}&sucursalId=${sucursalId}&tipoCajaId=${tipoCajaId}`;
  return await api.get(url);
};

export const createTurno = async (values) => {
  const url = `turnos/create`;
  const request = {
    fecha: values.fecha,
    hora: values.hora,
    cajaId: values.cajaId,
    dni: values.dni,
    nombre: values.nombre,
    apellido: values.apellido,
    telefono: values.telefono,
    email: values.email,
    cuentaContrato: values.cuentaContrato,
    titularCuenta: values.titularCuenta,
    tipoTurno: values.tipoTurno,
    observaciones: values.observaciones,
  };
  return await api.post(url, request, {
    headers: {
      XFF: localStorage.getItem("ip"),
    },
  });
};

export const getTurnoConfirmado = async (dni, turnoId) => {
  const url = `turnos/consulta?dni=${dni}&turnoId=${turnoId}`;
  return await api.get(url);
};

export const getCajaById = async (cajaId) => {
  const url = `cajas/${cajaId}`;
  return await api.get(url);
};

export const getSucursalesByTipoCaja = async (tipoCajaId, backoffice) => {
  if (backoffice === true) {
    const url = `sucursales/findSucursalesByTipoCaja?tipoCajaId=${tipoCajaId}&quitarDeshabilitadas=false`;
    return await api.get(url);
  } else {
    const url = `sucursales/findSucursalesByTipoCaja?tipoCajaId=${tipoCajaId}&quitarDeshabilitadas=true`;
    return await api.get(url);
  }
};
export const printTurno = async (
  sucursalesId,
  fechaDesde,
  fechaHasta,
  tipoCajasId,
  validateOnSucursal
) => {
  const url = `turnos/print?sucursalesId=${sucursalesId}&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}&tipoCajasId=${tipoCajasId}&tipoTurno=${validateOnSucursal}`;
  return await api.get(url, { responseType: "blob" });
};
export const getRecaptcha = async (response) => {
  const url = `captcha/tokenValidate?g-recaptcha-response=${response}`;
  return await api.get(url);
};
export const findCajasBySucursalTipoTramite = async (tramiteId, sucursalId) => {
  const url = `cajas/findBySucursalTipoTramite?tramiteId=${tramiteId}&sucursalId=${sucursalId}`;
  return await api.get(url);
};

export const updateCaja = async (values) => {
  const url = `cajas/update`;
  const request = {
    id: values.id,
    cantidadTotal: values.cantidadTotal,
    cantidadHabilitada: values.cantidadHabilitada,
    duracion: values.duracion,
  };
  return await api.put(url, request);
};

export const updateCantidadHablitadaDias = async (values) => {
  const url = `cajas/update/`;
  const request = {
    id: values.id,
    cantidadHabilitadaLunes: values.lunes,
    cantidadHabilitadaMartes: values.martes,
    cantidadHabilitadaMiercoles: values.miercoles,
    cantidadHabilitadaJueves: values.jueves,
    cantidadHabilitadaViernes: values.viernes,
  };
  return await api.put(url, request);
};

export const eliminarFeriado = async (feriadoId) => {
  const url = `feriados/delete/${feriadoId}`;
  return await api.delete(url);
};
export const createFeriado = async (values) => {
  const url = `feriados/create`;
  const request = {
    fecha: values.fecha,
    descripcion: values.descripcion,
  };

  return await api.post(url, request);
};
export const getHorariosBloqueados = async (sucursalId, tipoCaja, fecha) => {
  const url = `sucursales/${sucursalId}/tiposCaja/${tipoCaja}/horariosBloqueados?fechaDesde=${fecha}&fechaHasta=${fecha}`;

  return await api.get(url);
};

export const deleteBloqueo = async (sucursalId, tipoCaja, idBloqueo) => {
  const url = `sucursales/${sucursalId}/tiposCaja/${tipoCaja}/horariosBloqueados/${idBloqueo}`;
  return await api.delete(url);
};
export const turnosAfectados = async (
  fechaFin,
  fechaInicio,
  horaFin,
  horaInicio,
  sucursalId,
  tipoCajaId
) => {
  const url = `turnos/turnosAfectados?fechaFin=${fechaFin}&fechaInicio=${fechaInicio}&horaFin=${horaFin}&horaInicio=${horaInicio}&sucursalId=${sucursalId}&tipoCajaId=${tipoCajaId}`;
  return await api.get(url);
};

export const cancelarTurno = async (turnoId) => {
  const url = `turnos/cancelTurno?turnoId=${turnoId}`;
  return await api.delete(url);
};

export const getTurnoByFechaAndCancelado = async (fecha, cancelado) => {
  const url = `turnos?fecha=${fecha}&cancelado=${cancelado}`;
  return await api.get(url);
};

export const getTurnosByFecha_Caja_Sucursal = async (
  fechaDesde,
  sucursalId,
  tipoCajasId,
  fechaHasta,
  enSucursal
) => {
  const url = `turnos/pagina?fechaDesde=${fechaDesde}&sucursalesId=${sucursalId}&tipoCajasId=${tipoCajasId}&size=999&fechaHasta=${fechaHasta}&tipoTurno=${enSucursal}`;

  return await api.get(url);
};
export const imprimirCSV = async (
  fechaDesde,
  fechaHasta,
  sucursalesId,
  tipoCajasId,
  validateOnSucursal
) => {
  const url = `turnos/generacsv?fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}&sucursalesId=${sucursalesId}&tipoCajasId=${tipoCajasId}&tipoTurno=${validateOnSucursal}`;
  return await api.get(url, { responseType: "arraybuffer" });
};
export const getTurnoByFecha = async (fecha) => {
  const url = `turnos?fecha=${fecha}`;
  return await api.get(url);
};

export const proximoTurnoDisponibleApi = async (
  sucursalesArray,
  tipoTramiteArray
) => {
  const url = `reports/proximoTurnoDisponible?sucursales=${sucursalesArray}&tiposCajas=${tipoTramiteArray}`;
  return await api.get(url);
};

export const imprimirProximoTurnoDisponiblePdf = async (
  sucursalesArray,
  tipoTramiteArray
) => {
  const url = `reports/proximoTurnoDisponible/print?sucursales=${sucursalesArray}&tiposCajas=${tipoTramiteArray}`;
  return await api.get(url, { responseType: "blob" });
};
export const getBloqueoHorario = async (
  sucursalId,
  tipoCajaID,
  fechaDesde,
  fechaHasta,
  tipoBloqueo
) => {
  const url = `sucursales/${sucursalId}/tiposCaja/${tipoCajaID}/horariosBloqueados?fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}&tipoBloqueo=${tipoBloqueo}`;
  return await api.get(url);
};
export const deleteBloqueoHorario = async (sucursalId, tipoCajaID, id) => {
  const url = `sucursales/${sucursalId}/tiposCaja/${tipoCajaID}/horariosBloqueados/${id}`;
  return await api.delete(url);
};
export const addBloque = async (values) => {
  const url = `cajas/horariosBloqueados`;
  const request = {
    descripcion: values.descripcion,
    fechaDesde: values.fechaDesde,
    fechaHasta: values.fechaHasta,
    horaDesde: values.horaDesde,
    horaHasta: values.horaHasta,
    caja: {
      id: values.caja,
    },
    tipoBloqueo: {
      id: values.tipoBloqueo,
    },
  };
  return await api.post(url, request);
};

export const getData = async () => {
  const res = await axios.get("https://geolocation-db.com/json/");
  return res.data.IPv4;
};

export const tomarTurnoAtencion = async (id) => {
  const url = `tomarTurno?turnoId=${id}`;
  return await api.post(url);
};

export const finalizarTurnoAtencion = async () => {
  // consultar por ID ?turnoId=${id}
  const url = `finalizarTurno`;
  return await api.post(url);
};

export const usuarioAtencionActiva = async () => {
  const url = `usuarioInfo`;
  return await api.get(url);
};

export const generaCsvAtencionTurnosEnElDia = async (
  fechaDesde,
  fechaHasta,
  sucursalesId,
  tipoCajasId
) => {
  const url = `turnos/generaCsvAtencion?fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}&sucursalesId=${sucursalesId}&tipoCajasId=${tipoCajasId}`;
  return await api.get(url, { responseType: "arraybuffer" });
};

export const generaPdfAtencionTurnosEnElDia = async (
  fechaDesde,
  fechaHasta,
  sucursalesId,
  tipoCajasId
) => {
  const url = `turnos/generaPdfAtencion?fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}&sucursalesId=${sucursalesId}&tipoCajasId=${tipoCajasId}`;
  return await api.get(url, { responseType: "blob" });
};

export const generaXlsReporteIndicadoresEspecialesDetallado = async (
  sucursalesId,
  tipoCajasId,
  fechaDesde,
  fechaHasta
) => {
  const url = `reports/indicadores-especiales?sucursalesId=${sucursalesId}&tipoCajasId=${tipoCajasId}&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}`;
  return await api.get(url, { responseType: "blob" });
};
