export function formatDate(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (day === "32") day = "01";

  return [year, month, day].join("-");
}

export function formatHour(hour) {
  let d = new Date(hour),
    hora = "" + d.getHours(),
    minutos = "" + d.getMinutes(),
    segundos = "" + d.getSeconds();

  if (hora.length < 2) hora = "0" + hora;
  if (minutos.length < 2) minutos = "0" + minutos;
  if (segundos.length < 2) segundos = "0" + segundos;

  return [hora, minutos, segundos].join(":");
}
