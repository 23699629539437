import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerComponent = (props) => {

    return (
        <div>
            <DatePicker
              style={props.style}
              id={props.id}
              locale={props.locale}
              selected={props.selected}
              name={props.name}
              placeholderText={props.placeholderText}
              onChange={props.onChange}
              dateFormat={props.dateFormat}
              filterDate={props.filterDate}
              minDate={props.minDate}
              maxDate={props.maxDate}
              showDisabledMonthNavigation
              inline={props.inline}
              excludeDates={props.excludeDates}
            />
        </div>
    );
}
export default DatePickerComponent ;