import React, { useState, useEffect } from "react";
import { Grid, Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import Header from "usuariosOnline/components/Header/Header";
import swal from "sweetalert2";
import {
  eliminarFeriado,
  createFeriado,
  getTurnoByFechaAndCancelado,
  cancelarTurno,
  getFeriadosPaginables,
} from "api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, FormControl, Button, Box } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ClearIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Pagination } from "@material-ui/lab";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import "./AdministrarFeriados.css";
import SesionExpirada from "backOfficeUsuariosCau/components/SesionExpirada/SesionExpirada";
import ButtonEscape from "const/ButtonEscape";

const AdministrarFeriados = () => {
  const welcome = "Bienvenido !";

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
    },
    textField: {
      marginTop: theme.spacing(6),
    },
    formControlSelect: {
      margin: theme.spacing(1),
      minWidth: 60,
    },
  }));
  const classes = useStyles();

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "rgba(192,192,192,0.3)",
      color: theme.palette.common.black,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  const StyledTableRow = withStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.action.white,
    },
  }))(TableRow);

  const validation = Yup.object({
    descripcion: Yup.string().required("requerido"),
    fecha: Yup.string().required("requerido"),
  });

  const formik = useFormik({
    initialValues: {
      descripcion: "",
      fecha: "",
    },
    validationSchema: validation,
  });

  useEffect(() => {
    try {
      setClientWidth(document.documentElement.clientWidth);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const [clientWidth, setClientWidth] = React.useState("");
  const [agregarSector, setAgregarSector] = React.useState(false);
  const [deshabilitarAgregarButton, setDeshabilitarAgregarButton] =
    React.useState(false);
  const [feriados, setFeriados] = useState([]);
  const [totalPages, setTotalPages] = useState("");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  let listaDeTurnosAfectadosIds = [];

  const contadorTurnosFeriadoConfirmado = (data) =>
    data?.filter((item, index) => {
      return data.indexOf(item) === index;
    });

  async function getFeriadosPaginablesFunc(page, size) {
    const res = await getFeriadosPaginables(page - 1, size);
    setFeriados(res.data.content);
    setSize(res.data.size);
    setTotalPages(res.data.totalPages);
  }

  let fechaFormateada = formik.values.fecha.split("-").reverse().join("/");

  async function eliminarFeriadosFunc(id) {
    await eliminarFeriado(id)
      .then((response) => {
        getFeriadosPaginablesFunc();
        alert(
          "Se eliminó el feriado",
          "success",
          alertTitleSuccess("Éxito"),
          false,
          null,
          null,
          null,
          null
        );
      })
      .catch((err) => {
        if (err.response.status !== 401) {
          alert(
            "Hubo un error, por favor intente otra vez!",
            "warning",
            alertTitle("Atención"),
            null,
            null,
            null,
            null,
            null
          );
        }
      });
  }

  function eliminarFeriadosBoton(value) {
    const eliminarMessage =
      "¿Está seguro que desea eliminar esta fecha de la lista?";
    alert(
      eliminarMessage,
      "warning",
      alertTitle("Confirmar la Operación"),
      true,
      "eliminar",
      null,
      null,
      null,
      value
    );
  }

  async function cancelarTurnosFunc(ids) {
    await cancelarTurno(ids)
      .then((response) => {
        console.log("exito eliminado los turnos");
      })
      .catch((err) => {
        console.log("error eliminado los turnos");
      });
  }

  async function createFeriadosFunc(obj) {
    await createFeriado(obj)
      .then((response) => {
        cancelarTurnosFunc(listaDeTurnosAfectadosIds);
        getFeriadosPaginablesFunc();
        setAgregarSector(false);
        formik.setFieldValue("descripcion", "");
        formik.setFieldValue("fecha", "");
        setDeshabilitarAgregarButton(false);
        let contadorTurnosAfectados = contadorTurnosFeriadoConfirmado(
          listaDeTurnosAfectadosIds
        );
        alert(
          `Se cancelaron ${contadorTurnosAfectados.length} turnos para la fecha ${fechaFormateada} ¡Se agregó el feriado! `,
          "success",
          alertTitleSuccess("Éxito"),
          false,
          null,
          null,
          null,
          null
        );
      })
      .catch((err) => {
        const errorMessage = err.response.message;
        if (err.response.status == 403) {
          alert(
            `¡Ya existe feriado con la fecha ${fechaFormateada}!`,
            "warning",
            alertTitle("Atención"),
            null,
            null,
            null,
            null,
            null
          );
        }
      });
  }

  function agregarFeriadoBoton() {
    const agregarMessage =
      "¿Está seguro de que desea agregar esa fecha de la lista?";

    if (formik.values.fecha == "" || formik.values.descripcion == "") {
      formik.setFieldError("fecha", " ");
      formik.setFieldError("descripcion", " ");
    }
    if (formik.values.fecha != "" && formik.values.descripcion != "") {
      alert(
        agregarMessage,
        "warning",
        alertTitle("Confirmar la Operación"),
        true,
        "agregar",
        null,
        null,
        null,
        null
      );
    }
  }

  async function turnosAfectadosFunc() {
    let cantidadTurnos;
    await getTurnoByFechaAndCancelado(formik.values.fecha, false)
      .then((response) => {
        if (response.data.length > 0) {
          cantidadTurnos = response.data.length;
          response.data.map((item) => {
            listaDeTurnosAfectadosIds.push(item.id);
          });
        } else {
          cantidadTurnos = "0";
        }
      })
      .catch((err) => {
        console.log("error");
      });

    const turnosMessage = `Usted está agregando el día ${fechaFormateada} como feriado / no laborable, Turnos agendados en la fecha seleccionada ${cantidadTurnos}, ¿Desea continuar con la operación?`;
    const obj = {
      fecha: formik.values.fecha,
      descripcion: formik.values.descripcion,
    };
    alert(
      turnosMessage,
      "warning",
      alertTitle("Confirmar la Operación"),
      true,
      "confirmarFeriado",
      null,
      null,
      null,
      obj
    );
  }

  function alert(message, icon, title, form, tipo, a, b, c, value) {
    swal
      .fire({
        title: title,
        text: message,
        icon: icon,
        confirmButtonColor: "#009bdb",
        confirmButtonText: form ? "Sí" : "Ok",
        showCancelButton: form ? true : false,
        cancelButtonText: "No",
        cancelButtonColor: "#b7b7b7",
      })
      .then((result) => {
        if (tipo == "eliminar" && form == true) {
          if (result.value) {
            eliminarFeriadosFunc(value);
          } else {
            console.log(" no eliminar");
          }
        }
        if (tipo == "agregar" && form == true) {
          if (result.value) {
            turnosAfectadosFunc();
          } else {
            console.log(" no agregar");
          }
        }
        if (tipo == "confirmarFeriado" && form == true) {
          if (result.value) {
            createFeriadosFunc(value);
          } else {
            console.log(" no confirmarFeriado");
          }
        }
      });
  }
  function alertTitle(fName) {
    var val;
    val = fName;
    return val.fontcolor("#FF7F50"); // returning string from function
  }
  function alertTitleSuccess(fName) {
    var val;
    val = fName;
    return val.fontcolor("green"); // returning string from function
  }
  function populateFeriados(holiday) {
    const feriadoData = [];
    if (holiday) {
      for (let a = 0; a < holiday.length; a++) {
        const day = holiday[a].fecha.replace(/-/g, "/");
        feriadoData.push(new Date(day));
      }
    }
    return feriadoData;
  }
  const handleChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 400);
  };
  const handleChangeFilas = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  useEffect(() => {
    try {
      getFeriadosPaginablesFunc(page, size);
    } catch (err) {
      console.log(err);
    }
  }, [page, size]);

  return (
    <Container fixed>
      <div>
        <Header welcome={welcome} button={<ButtonEscape />} />
      </div>

      <div
        style={{
          marginTop: "3%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {" "}
        <SesionExpirada timer={900000} />
        <Grid container style={{ marginLeft: "2%" }}>
          <Grid item xs={2}>
            <Link to="/backOfficeL" style={{ textDecoration: "none" }}>
              <Button
                startIcon={<ArrowBackIcon />}
                style={{
                  textTransform: "none",
                  backgroundColor: "white",
                  color: "#009BDB",
                }}
              >
                Volver
              </Button>
            </Link>
          </Grid>
          <Grid item xs={10} className="grid__responsive-title">
            <p
              style={{
                marginTop: "5px",
                fontSize: "18px",
                fontFamily: "Roboto",
                fontWeight: 700,
              }}
              className="title__administrar-feriados"
            >
              Administrar feriados - días no laborables
            </p>

            <p
              style={{
                marginTop: "30px",
                fontSize: "14px",
                fontFamily: "Roboto",
                fontWeight: 400,
              }}
              className="subtitle__administrar-feriados"
            >
              Lista feriados y días no laborables actuales.
            </p>
          </Grid>
        </Grid>
        <br />
        <>
          <TableContainer
            style={{ width: "63%", marginLeft: "19%" }}
            component={Paper}
            className="table__feriados"
          >
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Descripción</StyledTableCell>
                  <StyledTableCell align="center">Fecha</StyledTableCell>
                  <StyledTableCell align="center">Eliminar</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {feriados &&
                  feriados.map((item) => (
                    <StyledTableRow key={item.id}>
                      <StyledTableCell component="th" scope="row">
                        {item.descripcion}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item.fecha.split("-").reverse().join("/")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          startIcon={<ClearIcon />}
                          style={{
                            textTransform: "none",
                            backgroundColor: "white",
                            color: "red",
                          }}
                          onClick={() => {
                            eliminarFeriadosBoton(item.id);
                          }}
                        ></Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
              marginTop: "1%",
            }}
          >
            <Box component="span">
              <Pagination
                count={totalPages}
                page={page}
                onChange={handleChange}
                defaultPage={1}
                color="primary"
                showFirstButton
                showLastButton
                classes={{ ul: classes.paginator }}
              />
            </Box>

            <InputLabel
              style={{ marginTop: "0.75%", marginLeft: "2%", fontSize: "15px" }}
              className="label__pagina-hojas"
            >
              {" "}
              Filas por página{" "}
            </InputLabel>

            <FormControl
              style={{ marginLeft: "1%", marginTop: "0%" }}
              className={classes.formControlSelect}
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={size}
                onChange={handleChangeFilas}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={5000}>TODOS</MenuItem>
              </Select>
            </FormControl>
          </div>
        </>
        <br />
        <Button
          disabled={deshabilitarAgregarButton}
          startIcon={<AddIcon />}
          style={{
            textTransform: "none",
            backgroundColor: "white",
            color: agregarSector ? "grey" : "#009BDB",
            width: "20%",
            marginLeft: "13%",
          }}
          onClick={() => {
            setAgregarSector(true);
            setDeshabilitarAgregarButton(true);
          }}
          className="btn__agregar_feriado"
        >
          Agregar nuevo
        </Button>
        <br />
        {agregarSector == true && (
          <div
            style={{ marginLeft: "27%" }}
            className="grid__agregar-feriado-responsive"
          >
            <p
              style={{
                fontSize: "16px",
                fontFamily: "Roboto",
                fontWeight: 400,
                color: "#009BDB",
              }}
            >
              Nuevo feriado/día no laborable
            </p>

            <p
              style={{
                marginTop: "40px",
                fontSize: "12px",
                fontFamily: "Roboto",
                fontWeight: 400,
                color: "#009BDB",
              }}
            >
              Descripción
            </p>
            <TextField
              variant="standard"
              placeholder="Descripción"
              style={{ width: "62%" }}
              id="descripcion"
              name="descripcion"
              onChange={formik.handleChange}
              value={formik.values.descripcion}
              error={formik.errors.descripcion}
            />
            <br />
            <p
              style={{
                marginTop: "40px",
                fontSize: "12px",
                fontFamily: "Roboto",
                fontWeight: 400,
                color: "#009BDB",
              }}
            >
              Fecha
            </p>
            <FormControl style={{ width: "62%" }}>
              <TextField
                id="fecha"
                type="date"
                value={formik.values.fecha}
                onChange={formik.handleChange}
                error={formik.errors.fecha}
                excludeDates={populateFeriados(feriados)}
              />
            </FormControl>

            <Button
              style={{
                marginTop: "3%",
                textTransform: "none",
                backgroundColor: "#009BDB",
                color: "white",
                width: "10%",
                marginLeft: "52%",
              }}
              onClick={() => {
                agregarFeriadoBoton();
              }}
            >
              Agregar
            </Button>
          </div>
        )}
      </div>
      <br />
      <br />
    </Container>
  );
};
export default AdministrarFeriados;
