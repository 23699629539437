import React, { useState, useEffect } from "react";
import { Grid, Container, Paper, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  getSucursalesByTipoCaja,
  getTipoCaja,
  findCajasBySucursalTipoTramite,
  updateCaja,
  updateCantidadHablitadaDias,
} from "api";
import { useFormik } from "formik";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
} from "@material-ui/core";
import { filterSucursales } from "backOfficeUsuariosCau/components/utils/filterSucursales";
import { sucursalesActualizarPuestosFiltradaArray } from "const/constantes";
import Header from "usuariosOnline/components/Header/Header";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert2";
import "./ActualizarPuestos.css";
import SesionExpirada from "backOfficeUsuariosCau/components/SesionExpirada/SesionExpirada";
import ButtonEscape from "const/ButtonEscape";

const ActualizarPuestos = () => {
  const welcome = "Bienvenido !";
  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        display: "flex",
        flexWrap: "wrap",
        marginBottom: "2%",
        "& > *": {
          width: "50%",
          height: theme.spacing(9),
          marginLeft: "25%",
        },
        [theme.breakpoints.down("sm")]: {
          marginLeft: "-24%",
          width: "196%",
        },
      },
      paper: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
          with: "100%",
        },
      },
      texto1: {
        color: "#0055A6",
        justifyContent: "start",
        marginLeft: "4%",
      },
      texto2: {
        justifyContent: "end",
        marginRight: "6%",
      },
      texto3: {
        width: "43%",
        marginLeft: "29%",
        fontSize: "12px",
        marginTop: "4%",
        color: "#F86436",
        [theme.breakpoints.down("sm")]: {
          width: "73%",
          marginLeft: "15%",
          marginTop: "10%",
        },
      },
    })
  );

  const classes = useStyles();

  const [sucursales, setSucursales] = useState([]);
  const [tipoCajas, setTipoCajas] = useState([]);
  const [sucId, setSucId] = useState(1);
  const [tramId, setTramId] = useState(2);
  const [confirmar, setConfirmar] = useState(false);
  const [deshabilitarButton, setDeshabilitarButton] = useState(false);
  const [buscarButton, setBuscarButton] = useState(true);
  const [result, setResult] = useState(false);
  const [cantidad, setCantidad] = useState("");
  const [idCaja, setIdCaja] = useState();
  const [cantidadTotal, setCantidadTotal] = useState();
  const [cantidadHabilitada, setCantidadHabilitada] = useState();
  const [duracion, setDuracion] = useState();
  const [errorTextfield, setErrorTextfield] = useState();
  const [helperText, setHelperText] = useState("");
  const [sucursalIdHelperText, setSucursalIdHelperText] = useState("");
  const [tramiteIdHelperText, setTramiteIdHelperText] = useState("");
  const [tramiteIdError, setTramiteIdError] = useState(false);
  const [sucursalIdError, setSucursalIdError] = useState(false);
  const [sucursalNombre, setSucursalNombre] = useState("");
  const [success, setSuccess] = useState(false);
  //const [messageSuccess, setMessageSuccess] = useState();
  const [arrayDias, setArrayDias] = useState([]);
  const [arrayCantTotales, setArrayCantTotales] = useState([]);
  const [initialValuesState, setInitialValuesState] = useState({
    id: "",
    lunes: "",
    martes: "",
    miercoles: "",
    jueves: "",
    viernes: "",
  });

  async function getSucursalesFunc(caja) {
    var suc = [];
    const res = await getSucursalesByTipoCaja(caja);
    suc = res.data;

    setSucursales(
      filterSucursales(sucursalesActualizarPuestosFiltradaArray, suc)
    );
  }

  async function getTipoCajaFunc() {
    const res = await getTipoCaja(true);
    setTipoCajas(res.data);
  }

  async function findCajasBySucursalTipoTramiteFunc(trameId, sucurlId) {
    const res = await findCajasBySucursalTipoTramite(trameId, sucurlId);
    const arr = crearArregloCantidadDias(res.data);
    const arrayCant = crearArregloCantTotales(res.data.cantidadTotal);
    const arrayCantTotal = {
      cant: arrayCant,
      dias: arr,
    };
    setCantidad(res.data.cantidadHabilitada);
    setIdCaja(res.data.id);
    setCantidadTotal(res.data.cantidadTotal);
    setDuracion(res.data.duracion);
    setArrayDias(arr);
    setArrayCantTotales(arrayCantTotal);
    setInitialValuesState({
      id: res.data.id,
      lunes: res.data.cantidadHabilitadaLunes,
      martes: res.data.cantidadHabilitadaMartes,
      miercoles: res.data.cantidadHabilitadaMiercoles,
      jueves: res.data.cantidadHabilitadaJueves,
      viernes: res.data.cantidadHabilitadaViernes,
    });
  }

  async function actualizarCajaFunc(obj) {
    try {
      await updateCaja(obj);
      setCantidad(cantidadHabilitada);
      setSuccess(true);
    } catch (e) {
      console.log(e);
    }
  }

  function crearArregloCantTotales(limite) {
    let array = [];
    for (let i = 0; i < limite; i++) {
      array[i] = 0;
    }
    return array;
  }

  function crearArregloCantidadDias(obj) {
    let arrayDias = [];
    arrayDias[0] = { data: obj.cantidadHabilitadaLunes, dia: "Lunes" };
    arrayDias[1] = { data: obj.cantidadHabilitadaMartes, dia: "Martes" };
    arrayDias[2] = { data: obj.cantidadHabilitadaMiercoles, dia: "Miercoles" };
    arrayDias[3] = { data: obj.cantidadHabilitadaJueves, dia: "Jueves" };
    arrayDias[4] = { data: obj.cantidadHabilitadaViernes, dia: "Viernes" };
    return arrayDias;
  }

  useEffect(() => {
    try {
      getTipoCajaFunc();
      getSucursalesFunc(tramId);
      findCajasBySucursalTipoTramiteFunc(tramId, sucId);
    } catch (err) {
      console.log(err);
    }
  }, [tramId, sucId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {}, []);

  const formik = useFormik({
    initialValues: {
      sucursalId: "",
      tramiteId: "",
    },
    onSubmit: (values) => {
      const { sucursalId, tramiteId } = values;

      findCajasBySucursalTipoTramiteFunc(tramiteId, sucursalId);
      setResult(true);
    },
  });

  const { id, lunes, martes, miercoles, jueves, viernes } = initialValuesState;
  const formikCantidadPuestos = useFormik({
    initialValues: {
      id,
      lunes,
      martes,
      miercoles,
      jueves,
      viernes,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      actualizarButton();
    },
  });

  function actualizarButton() {
    if (
      JSON.stringify(formikCantidadPuestos.values) ===
      JSON.stringify(initialValuesState)
    ) {
      cambiarInputsForm();
    } else {
      swalWarning();
    }
  }

  function validarCampos() {
    if (formik.values.sucursalId === "" || formik.values.tramiteId === "") {
      setTramiteIdError(true);
      setSucursalIdError(true);
      setSucursalIdHelperText("Requerido");
      setTramiteIdHelperText("Requerido");
    } else {
      setTramiteIdError(false);
      setSucursalIdError(false);
      setBuscarButton(false);
      formik.handleSubmit();
    }
  }

  function swalWarning() {
    swal
      .fire({
        position: "center",
        title: "Confirmar Operación",
        text: "¿Está seguro/a que desea actualizar los puestos de atención?",
        icon: "warning",
        confirmButtonColor: "#009bdb",
        confirmButtonText: "Sí",
        showCancelButton: true,
        cancelButtonText: "No",
        cancelButtonColor: "#b7b7b7",
        animation: false,
      })
      .then((resultado) => {
        if (resultado.value) {
          const obj = {
            id: idCaja,
            cantidadTotal: cantidadTotal,
            cantidadHabilitada: Number(cantidadHabilitada),
            duracion: duracion,
          };
          const obj2 = {
            id: formikCantidadPuestos.values.id,
            lunes: formikCantidadPuestos.values.lunes,
            martes: formikCantidadPuestos.values.martes,
            miercoles: formikCantidadPuestos.values.miercoles,
            jueves: formikCantidadPuestos.values.jueves,
            viernes: formikCantidadPuestos.values.viernes,
          };
          actualizarCajaFunc(obj);
          updateCantidadHablitadaDias(obj2);
          setResult(false);
          setDeshabilitarButton(false);
          swal
            .fire({
              icon: "success",
              title: "Actualización Éxitosa",
              text: ` La actualización de puestos en ${sucursalNombre} se ha realizado éxitosamente.`,
              confirmButtonColor: "#009bdb",
              confirmButtonText: "Aceptar",
            })
            .then(() => {
              validarCampos();
            });
        } else {
          setDeshabilitarButton(false);
        }
      });
  }

  function cambiarInputsForm() {
    swal.fire({
      icon: "warning",
      title: "Modificar",
      text: ` Debe modificar al menos un día.`,
      confirmButtonText: "Aceptar",
    });
  }

  return (
    <Container fixed>
      <div>
        <Header welcome={welcome} button={<ButtonEscape />} />
      </div>
      <SesionExpirada timer={900000} />
      <div
        style={{
          marginTop: "2%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <br />
        <Grid
          container
          style={{
            marginLeft: "2%",
          }}
          className="title__header"
        >
          <Grid item xs={2}>
            <Link to="/backOfficeL" style={{ textDecoration: "none" }}>
              <Button
                startIcon={<ArrowBackIcon />}
                style={{
                  textTransform: "none",
                  backgroundColor: "white",
                  color: "#009BDB",
                }}
              >
                Volver
              </Button>
            </Link>
          </Grid>
          <Grid item xs={10}>
            <p
              style={{
                marginTop: "5px",
                fontSize: "19px",
                marginLeft: "12.5%",
              }}
            >
              Actualizar Puestos
            </p>
          </Grid>
        </Grid>
        <br />

        <Grid
          container
          style={{
            width: "100%",
          }}
        >
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            item
            xs={1}
          ></Grid>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            item
            xs={10}
          >
            <form
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <FormControl
                style={{
                  alignSelf: "center",
                  width: "50%",
                  marginTop: "-2%",
                }}
                className="label__actualizar-puestos"
              >
                <InputLabel>
                  <p
                    style={{
                      marginTop: "-2%",
                      fontSize: "19px",
                    }}
                  >
                    Tipo atención
                  </p>
                </InputLabel>
                <Select
                  error={tramiteIdError}
                  helperText={tramiteIdHelperText}
                  id="tramiteId"
                  name="tramiteId"
                  style={{
                    marginBottom: "15px",
                    width: "100%",
                  }}
                  onChange={formik.handleChange}
                  value={formik.values.tramiteId}
                >
                  {tipoCajas &&
                    tipoCajas.map((tipoCaja) => (
                      <MenuItem
                        style={{ fontSize: "11pt", fontFamily: "Roboto" }}
                        key={`tipoCaja_${tipoCaja.id}`}
                        value={tipoCaja.id}
                        onClick={() => {
                          setDeshabilitarButton(false);
                          setConfirmar(false);
                          setTramId(tipoCaja.id);

                          if (result === false && buscarButton === false) {
                            setResult(true);
                            setSuccess(false);
                          }
                        }}
                      >
                        {tipoCaja.nombre}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </form>
          </Grid>
        </Grid>
        <br />

        <Grid
          style={{
            width: "100%",
          }}
          container
        >
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            item
            xs={1}
          ></Grid>
          <Grid item xs={10}>
            <form
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <FormControl
                style={{
                  alignSelf: "center",
                  width: "50%",
                  marginTop: "-2%",
                }}
                className="label__actualizar-puestos"
              >
                <InputLabel>
                  <p
                    style={{
                      marginTop: "-2%",
                      fontSize: "19px",
                    }}
                  >
                    Elegí el Centro de Atención
                  </p>
                </InputLabel>
                <Select
                  error={sucursalIdError}
                  helperText={sucursalIdHelperText}
                  id="sucursalId"
                  name="sucursalId"
                  style={{
                    marginBottom: "15px",
                    width: "100%",
                  }}
                  onChange={formik.handleChange}
                  value={formik.values.sucursalId}
                >
                  {sucursales &&
                    sucursales.map((sucursal) => (
                      <MenuItem
                        style={{ fontSize: "11pt", fontFamily: "Roboto" }}
                        key={`sucursal_${sucursal.id}`}
                        value={sucursal.id}
                        onClick={() => {
                          setDeshabilitarButton(false);
                          setConfirmar(false);
                          setSucId(sucursal.id);
                          setHelperText("");
                          setErrorTextfield(false);
                          setSucursalNombre(
                            sucursal.nombre +
                              " - " +
                              sucursal.localidad.nombre +
                              " - " +
                              sucursal.direccion
                          );

                          if (result === false && buscarButton === false) {
                            setResult(true);
                            setSuccess(false);
                          }
                        }}
                      >
                        {sucursal.localidad.nombre === "GRANDES USUARIOS"
                          ? ` ${sucursal.nombre} - ${sucursal.localidad.nombre}`
                          : ` ${sucursal.nombre} - ${sucursal.localidad.nombre} - ${sucursal.direccion}`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <Grid container>
                <Grid item xs={9}></Grid>

                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                  item
                  xs={3}
                >
                  {buscarButton === true && (
                    <Button
                      style={{
                        marginRight: "150%",
                        alignSelf: "center",
                        backgroundColor: "#0055a6",
                        color: "white",
                        width: "50%",
                        marginTop: "5%",
                      }}
                      className="btn__buscar-actualizar-puestos"
                      onClick={() => {
                        validarCampos();
                      }}
                      variant="contained"
                    >
                      Buscar
                    </Button>
                  )}{" "}
                </Grid>
              </Grid>
              <br />
            </form>
          </Grid>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            item
            xs={1}
          ></Grid>
        </Grid>
        <br />
        <br />

        {result === true && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignSelf: "center",
              width: "100%",
            }}
          >
            <Grid
              container
              style={{
                width: "100%",
              }}
            >
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
                item
                xs={1}
              ></Grid>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
                item
                xs={10}
                className="grid__actualizar-puestos"
              >
                <Box className={classes.root}>
                  <Paper className="actpuestos__item">
                    <b className={classes.texto1}>
                      Cantidad máxima puestos por dia
                    </b>
                    <p className={classes.texto2}> {cantidadTotal}</p>
                  </Paper>
                </Box>
                <Box className={classes.root}>
                  <Paper className="actpuestos__item">
                    <b className={classes.texto1}>Duración</b>
                    <p className={classes.texto2}> {duracion}</p>
                  </Paper>
                </Box>
                <Box className="box__dias">
                  <Paper className="container__dias">
                    <b className={classes.texto1} id="container__dias-titulo">
                      Cantidad de puestos actualmente habilitados de lunes a
                      viernes
                    </b>
                    <div className="grid__dias">
                      {arrayDias &&
                        arrayDias.map((dato) => (
                          <div key={dato.dia}>
                            <div className="grid__dias-dia">{dato.dia}</div>
                            <div className="grid__dias-dato">{dato.data}</div>
                          </div>
                        ))}
                    </div>
                  </Paper>
                </Box>
                <Box className="box__dias">
                  <Paper className="container__dias">
                    <div className="select__titulo">
                      Cantidad de puestos a habilitar de lunes a viernes
                    </div>
                    <form
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                      onSubmit={formikCantidadPuestos.handleSubmit}
                    >
                      <div
                        style={{
                          color: "white",
                          textTransform: "none",
                          marginTop: "1%",
                          width: "100%",
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="select__cont">
                          <div className="select__dia">Lunes</div>
                          <Select
                            name="lunes"
                            onChange={formikCantidadPuestos.handleChange}
                            error={errorTextfield}
                            helperText={helperText}
                            disabled={deshabilitarButton}
                            id="textfield"
                            variant="outlined"
                            value={formikCantidadPuestos.values.lunes}
                          >
                            {arrayCantTotales.cant.map((item, i) => {
                              return (
                                <MenuItem
                                  style={{
                                    fontSize: "11pt",
                                    fontFamily: "Roboto",
                                  }}
                                  value={i + 1}
                                >
                                  {i + 1}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>

                        <div className="select__cont">
                          <div className="select__dia">Martes</div>
                          <Select
                            name="martes"
                            onChange={formikCantidadPuestos.handleChange}
                            error={errorTextfield}
                            helperText={helperText}
                            disabled={deshabilitarButton}
                            id="textfield"
                            variant="outlined"
                            value={formikCantidadPuestos.values.martes}
                          >
                            {arrayCantTotales.cant.map((item, i) => {
                              return (
                                <MenuItem
                                  style={{
                                    fontSize: "11pt",
                                    fontFamily: "Roboto",
                                    fontWeight: "bold",
                                  }}
                                  value={i + 1}
                                >
                                  {i + 1}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>

                        <div className="select__cont">
                          <div className="select__dia">Miercoles</div>
                          <Select
                            name="miercoles"
                            onChange={formikCantidadPuestos.handleChange}
                            error={errorTextfield}
                            helperText={helperText}
                            disabled={deshabilitarButton}
                            id="textfield"
                            variant="outlined"
                            value={formikCantidadPuestos.values.miercoles}
                          >
                            {arrayCantTotales.cant.map((item, i) => {
                              return (
                                <MenuItem
                                  style={{
                                    fontSize: "11pt",
                                    fontFamily: "Roboto",
                                  }}
                                  value={i + 1}
                                >
                                  {i + 1}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>

                        <div className="select__cont">
                          <div className="select__dia">Jueves</div>
                          <Select
                            name="jueves"
                            onChange={formikCantidadPuestos.handleChange}
                            error={errorTextfield}
                            helperText={helperText}
                            disabled={deshabilitarButton}
                            id="textfield"
                            variant="outlined"
                            value={formikCantidadPuestos.values.jueves}
                          >
                            {arrayCantTotales.cant.map((item, i) => {
                              return (
                                <MenuItem
                                  style={{
                                    fontSize: "11pt",
                                    fontFamily: "Roboto",
                                  }}
                                  value={i + 1}
                                >
                                  {i + 1}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>

                        <div className="select__cont">
                          <div className="select__dia">Viernes</div>
                          <Select
                            name="viernes"
                            onChange={formikCantidadPuestos.handleChange}
                            error={errorTextfield}
                            helperText={helperText}
                            disabled={deshabilitarButton}
                            id="textfield"
                            variant="outlined"
                            value={formikCantidadPuestos.values.viernes}
                          >
                            {arrayCantTotales.cant.map((item, i) => {
                              return (
                                <MenuItem
                                  style={{
                                    fontSize: "11pt",
                                    fontFamily: "Roboto",
                                  }}
                                  value={i + 1}
                                >
                                  {i + 1}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <b className="select__advertencia">
                        *La nueva cantidad de puestos a habilitar no puede
                        superar la cantidad de puestos totales y no puede ser un
                        valor negativo
                      </b>
                      <div
                        style={{
                          alignSelf: "end",
                          marginTop: "2%",
                        }}
                        className="btn__act-actualizar-puestos"
                      >
                        <button
                          style={{
                            backgroundColor: "#F86436",
                            color: "white",

                            textTransform: "none",
                          }}
                          variant="contained"
                          type="submit"
                          onClick={actualizarButton}
                          className="select__boton"
                        >
                          Actualizar
                        </button>
                      </div>
                    </form>
                  </Paper>
                </Box>
              </Grid>
            </Grid>
          </div>
        )}
        <br />
      </div>
    </Container>
  );
};
export default ActualizarPuestos;
