import React, { useState, useCallback, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Typography, Container, Paper } from "@material-ui/core";
import swal from "sweetalert2";
import ButtonWithProgress from "usuariosOnline/components/ButtonWithProgress/ButtonWithProgress";
import Input from "usuariosOnline/components/Input/Input";
import * as authActions from "../../authActions";
import { connect } from "react-redux";
import {
  administrarBreakString,
  verAgendaString,
  deshabilitarCAUString,
  actualizarPuestosString,
  emitirReportesString,
  actualizarFeriadosString,
  turnoInSituString,
  reporteProximoTurnoDisponibleString,
  bloquearHorariosString,
  administrarPuestosTotalesString,
  administrarTiemposDeAtencionString,
  verTurnosEnElDiaString,
  verReporteTurnosEnElDiaString,
} from "const/constantes";
import { getData } from "api";

const SingleLogInForm = (props) => {
  const validation = Yup.object({
    username: Yup.string().required(
      <Typography
        variant="p"
        style={{ fontSize: "12pt", textTransform: "none" }}
      >
        requerido
      </Typography>
    ),
    password: Yup.string()
      .min(
        6,
        <Typography
          variant="p"
          style={{ fontSize: "12pt", textTransform: "none" }}
        >
          "La contraseña debe tener al menos 6 caracteres"
        </Typography>
      )
      .required(
        <Typography
          variant="p"
          style={{ fontSize: "12pt", textTransform: "none" }}
        >
          requerido
        </Typography>
      ),
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    form: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
  }));

  const classes = useStyles();
  const link = props.link;
  const [apiError, setApiError] = useState();
  const [pendingApiCall, setPendingApiCall] = useState(false);
  const [ipCapturadaWeb, setIpCapturadaWeb] = useState("");

  function swalWarning() {
    swal.fire({
      title: "Credenciales inválidas",
      text: "Por favor ingresar user y password correctos",
      icon: "warning",
      confirmButtonColor: "#009bdb",
      confirmButtonText: "ok",
      showCancelButton: false,
      cancelButtonText: "No",
      cancelButtonColor: "#b7b7b7",
      animation: true,
    });
  }
  async function capturaIP() {
    const res = await getData();
    setIpCapturadaWeb(res);
  }

  localStorage.setItem("ip", ipCapturadaWeb);

  useEffect(() => {
    capturaIP();
  }, []);

  const onClickLogin = useCallback((values) => {
    setPendingApiCall(true);
    props.actions
      .postLogin(values)
      .then((response) => {
        if (response.data.username !== null) {
          setPendingApiCall(false);
          window.location.replace(link);
          sessionStorage.setItem("logeado", true);
          sessionStorage.setItem("username", response.data.username);
          sessionStorage.setItem("token", response.data.token);
          sessionStorage.setItem("emailAtencion", values.username);

          response.data.actions.find(function (post, index) {
            if (post.name == administrarBreakString) {
              sessionStorage.setItem(administrarBreakString, "true");
              let sucursalesParseadas = post.sucursales.map((suc) => {
                return { ...suc, id: Number(suc.id) };
              });
              localStorage.setItem(
                "sucursalesAdministrarBreak",
                JSON.stringify(sucursalesParseadas)
              );
              return true;
            } else {
              sessionStorage.setItem(administrarBreakString, "false");
            }
          });
          sessionStorage.setItem("token", response.data.token);

          response.data.actions.find(function (post, index) {
            if (post.name == bloquearHorariosString) {
              sessionStorage.setItem(bloquearHorariosString, "true");
              let sucursalesParseadas = post.sucursales.map((suc) => {
                return { ...suc, id: Number(suc.id) };
              });
              localStorage.setItem(
                "sucursalesBloquearHorarios",
                JSON.stringify(sucursalesParseadas)
              );
              return true;
            } else {
              sessionStorage.setItem(bloquearHorariosString, "false");
            }
          });

          response.data.actions.find(function (post, index) {
            if (post.name === verAgendaString) {
              sessionStorage.setItem(verAgendaString, "true");
              let sucursalesParseadas = post.sucursales.map((suc) => {
                return { ...suc, id: Number(suc.id) };
              });
              localStorage.setItem(
                "sucursalesVerAgenda",
                JSON.stringify(sucursalesParseadas)
              );
              return true;
            } else {
              sessionStorage.setItem(verAgendaString, "false");
            }
          });

          response.data.actions.find(function (post, index) {
            if (post.name === deshabilitarCAUString) {
              sessionStorage.setItem(deshabilitarCAUString, "true");
              let sucursalesParseadas = post.sucursales.map((suc) => {
                return { ...suc, id: Number(suc.id) };
              });
              localStorage.setItem(
                "sucursalesDeshabiltarCAU",
                JSON.stringify(sucursalesParseadas)
              );
              return true;
            } else {
              sessionStorage.setItem(deshabilitarCAUString, "false");
            }
          });

          response.data.actions.find(function (post, index) {
            if (post.name === actualizarPuestosString) {
              sessionStorage.setItem(actualizarPuestosString, "true");
              let sucursalesParseadas = post.sucursales.map((suc) => {
                return { ...suc, id: Number(suc.id) };
              });
              localStorage.setItem(
                "sucursalesActualizarPuestos",
                JSON.stringify(sucursalesParseadas)
              );
              return true;
            } else {
              sessionStorage.setItem(actualizarPuestosString, "false");
            }
          });

          response.data.actions.find(function (post, index) {
            if (post.name === emitirReportesString) {
              sessionStorage.setItem(emitirReportesString, "true");
              let sucursalesParseadas = post.sucursales.map((suc) => {
                return { ...suc, id: Number(suc.id) };
              });
              localStorage.setItem(
                "sucursalesReportes",
                JSON.stringify(sucursalesParseadas)
              );
              return true;
            } else {
              sessionStorage.setItem(emitirReportesString, "false");
            }
          });

          response.data.actions.find(function (post, index) {
            if (post.name === turnoInSituString) {
              sessionStorage.setItem(turnoInSituString, "true");
              let sucursalesParseadas = post.sucursales.map((suc) => {
                return { ...suc, id: Number(suc.id) };
              });
              localStorage.setItem(
                "sucursalesTurnoInSitu",
                JSON.stringify(sucursalesParseadas)
              );
              return true;
            } else {
              sessionStorage.setItem(turnoInSituString, "false");
            }
          });

          response.data.actions.find(function (post, index) {
            if (post.name === reporteProximoTurnoDisponibleString) {
              sessionStorage.setItem(
                reporteProximoTurnoDisponibleString,
                "true"
              );
              let sucursalesParseadas = post.sucursales.map((suc) => {
                return { ...suc, id: Number(suc.id) };
              });
              localStorage.setItem(
                "sucursalesReporteProximoTurnoDisponible",
                JSON.stringify(sucursalesParseadas)
              );
              return true;
            } else {
              sessionStorage.setItem(
                reporteProximoTurnoDisponibleString,
                "false"
              );
            }
          });

          response.data.actions.find(function (post, index) {
            if (post.name === actualizarFeriadosString) {
              sessionStorage.setItem(actualizarFeriadosString, "true");
              let sucursalesParseadas = post.sucursales.map((suc) => {
                return { ...suc, id: Number(suc.id) };
              });
              localStorage.setItem(
                "sucursalesActualizarFeriados",
                JSON.stringify(sucursalesParseadas)
              );
              return true;
            } else {
              sessionStorage.setItem(actualizarFeriadosString, "false");
            }
          });

          response.data.actions.find(function (post, index) {
            if (post.name === administrarPuestosTotalesString) {
              sessionStorage.setItem(administrarPuestosTotalesString, "true");
              let sucursalesParseadas = post.sucursales.map((suc) => {
                return { ...suc, id: Number(suc.id) };
              });
              localStorage.setItem(
                "administrarPuestosTotales",
                JSON.stringify(sucursalesParseadas)
              );
              return true;
            } else {
              sessionStorage.setItem(administrarPuestosTotalesString, "false");
            }
          });

          response.data.actions.find(function (post, index) {
            if (post.name === administrarTiemposDeAtencionString) {
              sessionStorage.setItem(
                administrarTiemposDeAtencionString,
                "true"
              );
              let sucursalesParseadas = post.sucursales.map((suc) => {
                return { ...suc, id: Number(suc.id) };
              });
              localStorage.setItem(
                "administrarTiemposDeAtencion",
                JSON.stringify(sucursalesParseadas)
              );
              return true;
            } else {
              sessionStorage.setItem(
                administrarTiemposDeAtencionString,
                "false"
              );
            }
          });

          response.data.actions.find(function (post, index) {
            if (post.name === verTurnosEnElDiaString) {
              sessionStorage.setItem(verTurnosEnElDiaString, "true");
              let sucursalesParseadas = post.sucursales.map((suc) => {
                return { ...suc, id: Number(suc.id) };
              });
              localStorage.setItem(
                "sucursalesVerTurnosEnElDia",
                JSON.stringify(sucursalesParseadas)
              );
              return true;
            } else {
              sessionStorage.setItem(verTurnosEnElDiaString, "false");
            }
          });

          response.data.actions.find(function (post, index) {
            if (post.name === verReporteTurnosEnElDiaString) {
              sessionStorage.setItem(verReporteTurnosEnElDiaString, "true");
              let sucursalesParseadas = post.sucursales.map((suc) => {
                return { ...suc, id: Number(suc.id) };
              });
              localStorage.setItem(
                "sucursalesVerReporteAtencionDeTurnoEnElDia",
                JSON.stringify(sucursalesParseadas)
              );
              return true;
            } else {
              sessionStorage.setItem(verReporteTurnosEnElDiaString, "false");
            }
          });
        } else {
          sessionStorage.setItem("logeado", false);
          setPendingApiCall(false);
          swalWarning();
          console.log("error en las acciones del back");
        }
      })
      .catch((error) => {
        if (error.response) {
          sessionStorage.setItem("logeado", false);
          setPendingApiCall(false);
          setApiError(error.response.data.message);
          swalWarning();
          console.log("catch de error en la respuesta");
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validation,
    onSubmit: onClickLogin,
  });
  useEffect(() => {
    const keys = Object.keys(localStorage);
    keys.forEach((key) => {
      localStorage.removeItem(key);
    });
  }, []);

  return (
    <div>
      <Container fixed>
        <Grid container spacing={6}>
          <Grid item xs>
            <Paper className={classes.paper}>
              <Container component="main" maxWidth="xs">
                <div className={classes.paper}>
                  <Typography
                    variant="h2"
                    style={{
                      fontSize: "17pt",
                      fontFamily: "Roboto",
                      textTransform: "none",
                      color: "#b7b7b7",
                    }}
                  >
                    Iniciar sesión
                  </Typography>
                  <form className={classes.form} onSubmit={formik.handleSubmit}>
                    <Input
                      id="user"
                      label="Usuario"
                      name="username"
                      value={formik.values.username}
                      onChange={formik.handleChange}
                      helperText={formik.errors.username}
                      error={formik.errors.username}
                    />
                    <Input
                      id="password"
                      label="Contraseña"
                      name="password"
                      type="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      helperText={formik.errors.password}
                      error={formik.errors.password}
                    />
                    <br />
                    <div className="text-center">
                      <ButtonWithProgress
                        disabled={pendingApiCall}
                        text="Ingresar"
                        pendingApiCall={pendingApiCall}
                        type="submit"
                      />
                    </div>
                  </form>
                </div>
                <Box mt={8}></Box>
              </Container>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

SingleLogInForm.defaultProps = {
  actions: {
    postLogin: () => new Promise((resolve, reject) => resolve({})),
  },
  dispatch: () => {},
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      postLogin: (body) => dispatch(authActions.loginHandler(body)),
    },
  };
};

export default connect(null, mapDispatchToProps)(SingleLogInForm);
