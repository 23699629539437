import React from "react";
import {
  Button,
  Grid,
  Paper,
  TableRow,
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableBody,
} from "@material-ui/core";

const TablaHorariosBloqueados = ({
  horariosBloqueados,
  fechaModificada,
  desbloquearButton,
}) => {
  return (
    <div style={{ width: "100%", marginLeft: "1%" }}>
      <p
        style={{
          marginBottom: "10px",
          fontFamily: "Roboto",
          fontSize: "14px",
          color: "black",
        }}
      >
        Horarios bloqueados
      </p>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: "#EDEBEC" }}>
              <TableCell id="tableCell">Fecha</TableCell>
              <TableCell id="tableCell">Hora inicio</TableCell>
              <TableCell id="tableCell">Hora fin</TableCell>
              <TableCell id="tableCell"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {horariosBloqueados &&
              horariosBloqueados.map(
                ({ fechaDesde, horaDesde, horaHasta, caja, id }) => (
                  <TableRow key={id}>
                    <TableCell id="tableCell" component="th" scope="row">
                      {fechaModificada || "-"}
                    </TableCell>
                    <TableCell id="tableCell">{horaDesde || "-"} </TableCell>
                    <TableCell id="tableCell">{horaHasta || "-"}</TableCell>
                    <TableCell>
                      <Button
                        id={"buttonCancelar"}
                        variant="contained"
                        onClick={() => {
                          desbloquearButton(
                            caja.sucursal.id,
                            caja.id,
                            id,
                            horaDesde,
                            horaHasta,
                            caja.sucursal.localidad.nombre
                          );
                        }}
                        style={{
                          color: "white",
                          backgroundColor: "#F86436",
                          marginLeft: "20%",
                        }}
                      >
                        Desbloquear
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TablaHorariosBloqueados;
