import React from "react";
import Container from "@material-ui/core/Container";
import SingleLogInForm from "backOfficeUsuariosCau/components/SingleLogInForm/SingleLogInForm";

import Header from "usuariosOnline/components/Header/Header";

export const FormLogin = (props) => {
  const title = null;
  const welcome = null;
  const button = null;
  const link = "/buscarTurno";

  return (
    <div>
      <Container fixed>
        <div>
          <Header title={title} welcome={welcome} button={button} />
        </div>
        <div>
          <SingleLogInForm link={link} />
        </div>
      </Container>
    </div>
  );
};

export default FormLogin;
