import React, { useState, useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { getTipoCaja } from "api";
import { MenuProps, useStyles } from "./utilies";
import { setTramitesValues } from "store/slices/tramitesSlice";
import { useDispatch } from "react-redux";
import "./MultipleSelectCaja.css";

function MultipleSelectCajas(props) {
  const [options, setOptions] = useState([]);
  const [cajas, setCajas] = useState([]);
  const [error, setError] = useState("");
  const [selected, setSelected] = useState([]);

  const dispatch = useDispatch();

  let cajaMultipleSelect = [];

  const cajaHabilitadaFunc = () => {
    if (props.cajaHabilitada === "algo") {
      return "";
    } else if (props.cajaHabilitada === true) {
      return true;
    } else if (props.cajaHabilitada === false) {
      return false;
    }
  };

  async function getSucursalesFunc() {
    const cajaHabilitada = cajaHabilitadaFunc();
    console.log({ cajaHabilitada });

    const res = await getTipoCaja(cajaHabilitada);
    const tipoCaja = res.data;
    localStorage.setItem("tipoCajaReRender", JSON.stringify(tipoCaja));
    setCajas(tipoCaja);
    tipoCaja.map((caja) => {
      let string = caja.nombre;
      cajaMultipleSelect.push(string);
    });
    setOptions(cajaMultipleSelect);
  }

  let tipoDeTramiteArray = [];

  function addCajasId() {
    selected.map((item) => {
      cajas.map((caja) => {
        if (item == caja.nombre) {
          const newArray = [...tipoDeTramiteArray];
          newArray.push(caja.id);
          tipoDeTramiteArray = newArray;
        }
      });
    });

    dispatch(setTramitesValues(tipoDeTramiteArray));
  }

  const handleChange = (event) => {
    const value = event.target.value;
    props.onChange(value);
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : options);
      return;
    }
    setSelected(value);
    addCajasId();
  };

  useEffect(() => {
    try {
      getSucursalesFunc();
      addCajasId();
    } catch (err) {
      console.log(err);
    }
  }, [props.tipoDeTramiteHabilitado]);

  useEffect(() => {
    try {
      addCajasId();
    } catch (err) {
      console.log(err);
    }
  }, [tipoDeTramiteArray]);

  const isAllSelected =
    options.length > 0 && selected.length === options.length;
  const classes = useStyles();

  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel
        style={{ fontSize: "20px" }}
        id="mutiple-select-label"
        className="grid__label"
      >
        {props.title}
      </InputLabel>
      <Select
        disabled={props.habilitado}
        labelId="mutiple-select-label"
        multiple
        value={selected}
        onChange={handleChange}
        renderValue={(selected) => selected.join(", ")}
        error={props.error}
        MenuProps={MenuProps}
      >
        <MenuItem
          value="all"
          classes={{
            root: isAllSelected ? classes.selectedAll : "",
          }}
        >
          <ListItemIcon>
            <Checkbox
              style={{ color: "blue" }}
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                selected.length > 0 && selected.length < options.length
              }
            />
          </ListItemIcon>

          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary="TODOS LOS TRÁMITES"
          />
        </MenuItem>

        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <ListItemIcon>
              <Checkbox
                style={{ color: "blue" }}
                checked={selected.indexOf(option) > -1}
              />
            </ListItemIcon>

            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MultipleSelectCajas;
