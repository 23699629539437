import React from "react";
import { src } from "const/mapsSRC";
import Iframe from "react-iframe";
//import { useLocation } from "react-router-dom";

const SucursalMaps = ({ iddd }) => {
  /* let { search } = useLocation();
  let query = new URLSearchParams(search);
  let isUsuarioApp = query.get("userApp"); */

  return (
    <div
    /* style={
        isUsuarioApp ? { pointerEvents: "none" } : { pointerEvents: "all" }
      } */
    >
      {src
        .filter((mapa) => mapa.id === iddd)
        .map((list) => (
          <div key={list.id}>
            <Iframe
              src={list.map}
              width="100%"
              height="100%"
              frameborder="0"
              style={{ border: "0" }}
              allowfullscreen=""
              ariaHidden="false"
              tabindex="0"
            />
          </div>
        ))}
    </div>
  );
};
export default SucursalMaps;
