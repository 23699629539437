/* eslint-disable no-unused-expressions */

import React, { useState, useEffect } from "react";
import { dataTurnoOnlineParaCancelar, cancelarTurnoOnline } from "./utils";
import { Button, Divider } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import "./CancelarTurnoOnline.css";

const CancelarTurnoOnline = () => {
  const [datosTurno, setDatosTurno] = useState({});
  const history = useHistory();

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const token = query.get("token");

  const swalTunoYaEliminado = () => {
    swal
      .fire({
        title: "Este turno ya ha sido cancelado",
        icon: "warning",
        confirmButtonColor: "#009bdb",
        confirmButtonText: "Volver",
      })
      .then((result) => {
        history.push("/");
      });
  };

  const swalTunoYaAtendido = () => {
    swal
      .fire({
        title: "Este turno ya ha sido atendido",
        icon: "warning",
        confirmButtonColor: "#009bdb",
        confirmButtonText: "Volver",
      })
      .then((result) => {
        history.push("/");
      });
  };

  async function getDatosTurnoParaCancelar(token) {
    try {
      const res = await dataTurnoOnlineParaCancelar(token);
      setDatosTurno(res.data);
    } catch (e) {
      if (e.response.status === 404) {
        console.log(e);
        swalTunoYaEliminado();
      }
      if (e.response.status === 400) {
        swalTunoYaAtendido();
      }
    }
  }

  async function deleteTurno(token) {
    try {
      await cancelarTurnoOnline(token);
      console.log("turno Cancelado");
    } catch (e) {
      if (e.response.status === 400) {
        swalTunoYaAtendido();
      }
    }
  }

  const nombre = datosTurno?.cliente?.nombre?.toUpperCase();
  const nombreMin = datosTurno?.cliente?.nombre;
  const apellido = datosTurno?.cliente?.apellido?.toUpperCase();
  const apellidoMin = datosTurno?.cliente?.apellido;
  const fecha = datosTurno?.fecha?.split("-").reverse().join("/");
  const hora = datosTurno?.hora?.slice(0, -3);
  const dni = datosTurno?.cliente?.dni;
  const tipoTramite = datosTurno?.caja?.tipo?.nombre;
  const localidad = datosTurno?.sucursal?.localidad?.nombre;
  const sucursal = datosTurno?.sucursal?.nombre;
  const direccion = datosTurno?.sucursal?.direccion;

  const swalCancelarTurno = () => {
    swal
      .fire({
        title: "CONFIRMAR OPERACION",
        text: `¿Está seguro que desea Cancelar su turno con fecha: ${fecha} a las ${hora} en la sucursal ${sucursal}? `,
        icon: "warning",
        confirmButtonColor: "#009bdb",
        confirmButtonText: "Sí",
        showCancelButton: true,
        cancelButtonText: "No",
        cancelButtonColor: "#b7b7b7",
      })
      .then((result) => {
        if (result.value) {
          deleteTurno(token);
          swal
            .fire({
              title: "Turno Cancelado",
              icon: "success",
              confirmButtonColor: "#009bdb",
              confirmButtonText: "Volver",
            })
            .then((result) => {
              history.push("/");
            });
        } else {
          history.push("/");
        }
      });
  };

  useEffect(() => {
    getDatosTurnoParaCancelar(token);
  }, []);

  return (
    <div>
      <div style={{ marginTop: "2%", marginBottom: "2%", paddingLeft: "10%" }}>
        <img
          src="https://www.aysa.com.ar/assets/Menu/img/logo.png"
          alt="aysa logo"
        />
      </div>
      <Divider style={{ height: "3px" }} />

      {datosTurno && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p className="title_cancelacion-cancelar-turno">
            Cancelación de Turno
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p className="title_hola-cancelar-Turno">
              Hola : {nombre} {apellido}
            </p>
            <h4 className="sub-title_hola-cancelar-Turno">
              Se solicitó la cancelación del turno agendado:
            </h4>
            <p className="texto-fecha-hora_cancelar-turno">
              <strong>FECHA: </strong> {fecha}
            </p>
            <p className="texto-fecha-hora_cancelar-turno">
              <strong>HORA: </strong> {hora} hs
            </p>
            <hr />
            <p className="texto-cuerpo_cancelar-turno-top">
              <strong>Nombre: </strong> {nombreMin} {apellidoMin}
            </p>
            <p className="texto-cuerpo_cancelar-turno">
              <strong>N° de Documento: </strong> {dni}
            </p>
            <p className="texto-cuerpo_cancelar-turno">
              <strong>Tipo de Trámite: </strong> {tipoTramite}
            </p>
            <p className="texto-cuerpo_cancelar-turno">
              <strong>Centro de Atención: </strong> {sucursal} - {localidad}:{" "}
              {direccion}
            </p>
          </div>

          <Button
            onClick={swalCancelarTurno}
            style={{
              backgroundColor: "red",
              width: "250px",
              border: "none",
              borderRadius: "5px",
              height: "45px",
              marginTop: "20px",
            }}
          >
            <p
              style={{
                fontSize: "11pt",
                fontFamily: "Roboto",
                textTransform: "none",
                color: "white",
                fontWeight: "500",
              }}
            >
              CONFIRMAR CANCELACION{" "}
            </p>
          </Button>
        </div>
      )}
    </div>
  );
};

export default CancelarTurnoOnline;
