import React, { useState, useEffect, useRef } from "react";
import { Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import Header from "usuariosOnline/components/Header/Header";
import {
  getSucursales,
  getSucursalesByTipoCaja,
  getFeriados,
  getTipoCaja,
  getBloqueoHorario,
  deleteBloqueo,
  turnosAfectados,
  addBloque,
  cancelarTurno,
  findCajasBySucursalTipoTramite,
  updateCaja,
} from "api";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
  Button,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { filterSucursales } from "backOfficeUsuariosCau/components/utils/filterSucursales";
import { sucursalesReportesFiltradaArray } from "const/constantes";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import swal from "sweetalert2";
import TablaHorariosBloqueados from "./TablaHorariosBloqueados";
import GuardarBloqueo from "./GuardarBloqueo";
import TurnosAfectadosTabla from "./TurnosAfectadosTabla";
import "./bloquearHorarios.css";
import SesionExpirada from "backOfficeUsuariosCau/components/SesionExpirada/SesionExpirada";
import ButtonEscape from "const/ButtonEscape";

const BloquearHorarios = () => {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
    },
    textField: {
      marginTop: theme.spacing(6),
    },
    table: {},
    customOutline: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "grey",
        borderRadius: "1px",
        padding: "1px",
      },
    },
  }));

  const welcome = "Bienvenido !";
  const classes = useStyles();

  const width = clientWidth > 415 ? "35%" : "80%";
  const [clientWidth, setClientWidth] = useState("");
  const [sucursales, setSucursales] = useState([]);
  const [tipoDecajas, setTipoDecajas] = useState([]);
  const [tipoCajaId, setTipoCajaId] = useState();
  const [horariosBloqueados, setHorariosBloqueados] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [feriados, setFeriados] = useState([]);
  const [fechaModificada, setFechaModificada] = useState("");
  const [turnosAfectadosTabla, setTurnosAfectadosTabla] = React.useState(false);
  const [buscarBloqueoButtonPrimero, setBuscarBloqueoButtonPrimero] =
    useState(true);
  const [guardarBloqueo, setGuardarBloqueo] = useState(false);
  const [tablaHorariosBloqueados, setTablaHorariosBloqueados] = useState(true);

  const [horaDesde, setHoraDesde] = useState("09:00");
  const [horaHasta, setHoraHasta] = useState("09:00");
  const [cauBloqueo, setCauBloqueo] = useState("");
  const [turnosAfectadosArray, setTurnosAfectadosArray] = useState([]);
  const [isTurnosAfectadosArrayEmpty, setIsTurnosAfectadosArrayEmpty] =
    useState(false);

  const [duracion, setDuracion] = useState("");
  const [habilitado, setHabilitado] = useState(false);
  const [idCajaBySucursal, setIdCajaBySucursal] = useState("");

  const validation = Yup.object({
    sucursalId: Yup.string().required("requerido"),
    fecha: Yup.string().required("requerido"),
    tipoCaja: Yup.string().required("requerido"),
  });

  const formik = useFormik({
    initialValues: {
      sucursalId: "",
      fecha: "",
      tipoCaja: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      const { sucursalId, tipoCaja, fecha } = values;
      getHorariosBloqueadosFunc(sucursalId, tipoCaja, fecha, fecha, 1);
      setShowResults(true);
    },
  });

  async function cancelarTurnoFunc(id) {
    console.log("cancelarTurnoFunc");
    await cancelarTurno(id);
  }

  async function getTipoCajaFunc() {
    const res = await getTipoCaja(true);
    setTipoDecajas(res.data);
  }

  async function getTipoCajaBySucTipoFunc(tipoCajaId, sucursalId) {
    const res = await findCajasBySucursalTipoTramite(tipoCajaId, sucursalId);
    setDuracion(res.data.duracion);
    setIdCajaBySucursal(res.data.id);
  }
  async function turnosAfectadosFunc(
    fechaFin,
    fechaInicio,
    horaFin,
    horaInicio,
    sucursalId,
    tipoCajaId
  ) {
    const res = await turnosAfectados(
      fechaFin,
      fechaInicio,
      horaFin,
      horaInicio,
      sucursalId,
      tipoCajaId
    );
    setTurnosAfectadosArray(res.data);

    if (res.data.length == 0) {
      setIsTurnosAfectadosArrayEmpty(true);
    } else if (res.data.length > 0) {
      setIsTurnosAfectadosArrayEmpty(false);
    }
  }

  async function getSucursalesFunc(caja) {
    var suc = [];
    if (formik.values.tramiteId != "") {
      const res = await getSucursalesByTipoCaja(caja);
      suc = res.data;
    }
    if (formik.values.tramiteId == "") {
      const res = await getSucursales(true);
      suc = res.data;
    }

    setSucursales(filterSucursales(sucursalesReportesFiltradaArray, suc));
  }

  async function getHorariosBloqueadosFunc(
    sucursalId,
    tipoCaja,
    fecha1,
    fecha2,
    tipoBloqueo
  ) {
    const res = await getBloqueoHorario(
      sucursalId,
      tipoCaja,
      fecha1,
      fecha2,
      1
    );
    const fechaNuevoFormato = fecha1.replace(
      /^(\d{4})-(\d{2})-(\d{2})$/g,
      "$3/$2/$1"
    );
    setFechaModificada(fechaNuevoFormato);
    setHorariosBloqueados(res.data);
  }

  useEffect(() => {
    try {
      setClientWidth(document.documentElement.clientWidth);
      getFeriadosFunc();
      getTipoCajaFunc();
    } catch (err) {
      console.log(err);
    }
  }, [tipoCajaId]);

  useEffect(() => {
    try {
      if (tipoCajaId) {
        getSucursalesFunc(tipoCajaId);
      }
    } catch (err) {
      console.log(err);
    }
  }, [tipoCajaId]);

  async function getFeriadosFunc() {
    const res = await getFeriados();
    setFeriados(res.data);
  }

  function populateFeriados(holiday) {
    const feriadoData = [];
    if (holiday) {
      for (let a = 0; a < holiday.length; a++) {
        const day = holiday[a].fecha.replace(/-/g, "/");
        feriadoData.push(new Date(day));
      }
    }
    return feriadoData;
  }

  async function deleteBloqueoFunc(sucursalId, tipoCaja, idBloqueo) {
    const res = await deleteBloqueo(sucursalId, tipoCaja, idBloqueo);
    if (res.status == 200) {
      alert(
        "Se desbloqueó éxitosamente!",
        "success",
        alertTitleSuccess("Éxito"),
        false,
        null,
        null,
        null,
        null
      );
      formik.handleSubmit();
    }
  }

  function cancelarTurnosAfectados() {
    {
      turnosAfectadosArray &&
        turnosAfectadosArray.map((turno) => cancelarTurnoFunc(turno.id));
    }
  }

  async function addBloqueoFunc(obj) {
    await addBloque(obj)
      .then((result) => {
        alert(
          "El bloqueo se guardó éxitosamente!",
          "success",
          alertTitleSuccess("Éxito"),
          false,
          null,
          null,
          null,
          null
        );
        if (isTurnosAfectadosArrayEmpty === false) {
          cancelarTurnosAfectados();
        }
        formik.handleSubmit();
        setTablaHorariosBloqueados(true);
        setShowResults(false);
        setTurnosAfectadosTabla(false);
      })

      .catch((err) => {
        if (err.response.status !== 401) {
          alert(
            "Ya existe un bloqueo para esa fecha y hora, O hubo un error, por favor intente nuevamente!",
            "warning",
            alertTitle("Atención"),
            null,
            null,
            null,
            null,
            null
          );
        }
      });
  }

  function desbloquearButton(a, b, c, e, f, g) {
    const desbloquearMessage = `¿Estás seguro que deseas desbloquear el horario de ${e} / ${f} para el CAU ${g}?`;
    alert(
      desbloquearMessage,
      "warning",
      alertTitle("Confirmar la Operación"),
      true,
      "desbloquear",
      a,
      b,
      c
    );
  }

  function bloquearButton(obj) {
    const bloquearMessage = `¿Estás seguro que deseas bloquear el horario de ${horaDesde} / ${horaHasta} el día ${formik.values.fecha
      .split("-")
      .reverse()
      .join("/")} para el CAU ${cauBloqueo}?`;
    alert(
      bloquearMessage,
      "warning",
      alertTitle("Confirmar la Operación"),
      true,
      "bloquear",
      null,
      null,
      null,
      obj
    );
  }

  function alert(message, icon, title, form, tipo, a, b, c, obj) {
    swal
      .fire({
        title: title,
        text: message,
        icon: icon,
        confirmButtonColor: "#009bdb",
        confirmButtonText: form ? "Sí" : "Ok",
        showCancelButton: form ? true : false,
        cancelButtonText: "No",
        cancelButtonColor: "#b7b7b7",
      })
      .then((result) => {
        if (tipo == "desbloquear" && form == true) {
          if (result.value) {
            deleteBloqueoFunc(a, b, c);
          } else {
            console.log(" error desbloquear");
          }
        } else if (tipo == "bloquear" && form == true) {
          if (result.value) {
            addBloqueoFunc(obj);
          } else {
            console.log(" error bloquear");
          }
        }
      });
  }

  function alertTitle(fName) {
    var val;
    val = fName;
    return val.fontcolor("#FF7F50"); // returning string from function
  }
  function alertTitleSuccess(fName) {
    var val;
    val = fName;
    return val.fontcolor("green"); // returning string from function
  }

  const handleNuevoBloqueo = () => {
    setShowResults(false);
    setBuscarBloqueoButtonPrimero(false);
    setGuardarBloqueo(true);
    setTablaHorariosBloqueados(false);
    setHabilitado(true);
    getTipoCajaBySucTipoFunc(formik.values.tipoCaja, formik.values.sucursalId);
    setHoraDesde("09:00");
    setHoraHasta("09:00");
  };

  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
      fixed
    >
      <div>
        <Header welcome={welcome} button={<ButtonEscape />} mainPage={false} />
      </div>
      <SesionExpirada timer={900000} />
      <div
        style={{
          marginTop: "3%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Grid container style={{ marginLeft: "2%" }}>
          <Grid item xs={3}>
            <Link to="/backOfficeL" style={{ textDecoration: "none" }}>
              <Button
                startIcon={<ArrowBackIcon />}
                style={{
                  textTransform: "none",
                  backgroundColor: "white",
                  color: "#009BDB",
                }}
              >
                Volver
              </Button>
            </Link>
          </Grid>
          <Grid item xs={9}>
            <Typography
              style={{
                marginLeft: "2%",
                color: "#4A4A4A",
                fontFamily: "Roboto",
                fontWeight: "bold",
                fontSize: "18px",
                lineHeight: "21.09px",
              }}
            >
              Bloqueos por Horarios
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            item
            xs={12}
          >
            <form
              onSubmit={formik.handleSubmit}
              style={{ alignSelf: "center", width: "45%", marginTop: "1%" }}
              className="form__tipoYcaja"
            >
              <FormControl className={classes.formControl}>
                <InputLabel style={{ fontSize: "16pt", fontFamily: "Roboto" }}>
                  Tipo Atención
                </InputLabel>
                <Select
                  id="tipoCaja"
                  name="tipoCaja"
                  value={formik.values.tipoCaja}
                  onChange={formik.handleChange}
                  helperText={formik.errors.tipoCaja || "*Campo requerido"}
                  error={formik.errors.tipoCaja}
                  disabled={habilitado}
                >
                  {tipoDecajas &&
                    tipoDecajas.map((tipo) => (
                      <MenuItem
                        onClick={() => setTipoCajaId(tipo.id)}
                        key={`tipo_${tipo.id}`}
                        value={tipo.id}
                      >
                        {tipo.nombre}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel style={{ fontSize: "16pt", fontFamily: "Roboto" }}>
                  Elegí el Centro de Atención
                </InputLabel>
                <Select
                  className={classes.textField}
                  id="sucursalId"
                  name="sucursalId"
                  value={formik.values.sucursalId}
                  onChange={formik.handleChange}
                  helperText={formik.errors.sucursalId || "*Campo requerido"}
                  error={formik.errors.sucursalId}
                  disabled={habilitado}
                >
                  {sucursales &&
                    sucursales.map((sucursal) => (
                      <MenuItem
                        key={`sucursal_${sucursal.id}`}
                        value={sucursal.id}
                        onClick={() => {
                          setCauBloqueo(sucursal.nombre);
                        }}
                      >
                        {sucursal.localidad === "GRANDES USUARIOS"
                          ? ` ${sucursal.nombre} - ${sucursal.localidad.nombre}`
                          : ` ${sucursal.nombre} - ${sucursal.localidad.nombre} - ${sucursal.direccion}`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl className={classes.formControl}>
                <InputLabel style={{ fontSize: "16pt", fontFamily: "Roboto" }}>
                  Fecha
                </InputLabel>
                <TextField
                  className={classes.textField}
                  id="fecha"
                  type="date"
                  value={formik.values.fecha}
                  selected={formik.values.fecha}
                  onChange={formik.handleChange}
                  helperText={formik.errors.fecha}
                  error={formik.errors.fecha}
                  excludeDates={populateFeriados(feriados)}
                  disabled={habilitado}
                />
              </FormControl>
            </form>
          </Grid>
        </Grid>
        <br />
        <Grid container>
          <Grid item xs={9}></Grid>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            item
            xs={3}
          >
            {" "}
            {buscarBloqueoButtonPrimero == true && (
              <Button
                style={{
                  textTransform: "none",
                  marginRight: "150%",
                  alignSelf: "center",
                  backgroundColor: "#0055a6",
                  color: "white",
                  width: "35%",
                }}
                className="boton__buscar"
                onClick={() => {
                  formik.handleSubmit();
                }}
                variant="contained"
              >
                Buscar
              </Button>
            )}
          </Grid>
        </Grid>
      </div>

      {tablaHorariosBloqueados == true && (
        <div className="bloquearHorarios__tabla">
          <div style={{ alignSelf: "center", width: "100%" }}>
            {showResults == true &&
              formik.values.tipoCaja != "" &&
              formik.values.sucursalId != "" &&
              formik.values.fecha != "" && (
                <TablaHorariosBloqueados
                  horariosBloqueados={horariosBloqueados}
                  fechaModificada={fechaModificada}
                  desbloquearButton={desbloquearButton}
                />
              )}
          </div>
          <br />
          <div style={{ alignSelf: "flex-end" }}>
            {showResults == true &&
              formik.values.tipoCaja != "" &&
              formik.values.sucursalId != "" &&
              formik.values.fecha != "" && (
                <Button
                  style={{
                    textTransform: "none",

                    backgroundColor: "#009BDB",
                    color: "white",
                  }}
                  onClick={() => {
                    handleNuevoBloqueo();
                  }}
                  variant="contained"
                >
                  Nuevo Bloqueo
                </Button>
              )}
          </div>
        </div>
      )}

      {guardarBloqueo == true && (
        <div
          style={{
            marginBottom: "80px",
            marginTop: "3%",
            width: "97%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignSelf: "center",
          }}
        >
          <div style={{ alignSelf: "center", width: "100%" }}>
            <GuardarBloqueo
              setHoraDesde={setHoraDesde}
              setHoraHasta={setHoraHasta}
              horaDesde={horaDesde}
              horaHasta={horaHasta}
              setGuardarBloqueo={setGuardarBloqueo}
              params={formik.values}
              setTurnosAfectadosTabla={setTurnosAfectadosTabla}
              turnosAfectadosFunc={turnosAfectadosFunc}
              duracion={duracion}
            />
          </div>
        </div>
      )}

      {turnosAfectadosTabla == true && (
        <div
          style={{
            marginBottom: "80px",
            marginTop: "3%",
            width: "50%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignSelf: "center",
          }}
        >
          <div style={{ alignSelf: "center", width: "100%" }}>
            <TurnosAfectadosTabla
              isTurnosAfectadosArrayEmpty={isTurnosAfectadosArrayEmpty}
              turnosAfectadosArray={turnosAfectadosArray}
              horaDesde={horaDesde}
              horaHasta={horaHasta}
              bloquearButton={bloquearButton}
              params={formik.values}
              tipoCajaId={tipoCajaId}
              idCajaBySucursal={idCajaBySucursal}
            />
          </div>
        </div>
      )}
    </Container>
  );
};
export default BloquearHorarios;
