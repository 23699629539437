import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import cajaReducer from "./slices/cajaSlice";
import turnoReducer from "./slices/turnoSlice";
import turnoConfirmadoReducer from "./slices/turnoConfirmadoSlice";
import disclaimerReducer from "./slices/disclaimerSlice";
import sucursalesReducer from "./slices/sucursalesSlice";
import tramitesReducer from "./slices/tramitesSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    caja: cajaReducer,
    turno: turnoReducer,
    turnoConfirmado: turnoConfirmadoReducer,
    disclaimer: disclaimerReducer,
    sucursales: sucursalesReducer,
    tramites: tramitesReducer,
  },
});
