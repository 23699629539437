import React from "react";
import { useSelector } from "react-redux";
import { generaXlsReporteIndicadoresEspecialesDetallado } from "api";
import swal from "sweetalert2";
import { useHistory } from "react-router-dom";

const PopupReporteDetallado = ({ fechaDesde, fechaHasta }) => {
  const history = useHistory();
  const sucursalIdArray = useSelector(
    (state) => state.sucursales.sucursalesArray
  );
  const tipoDeTramiteArray = useSelector(
    (state) => state.tramites.tramitesArray
  );

  const generaXlsReporteFunction = async (
    sucursalIdArray,
    tipoDeTramiteArray,
    fechaDesde,
    fechaHasta
  ) => {
    if (
      sucursalIdArray.length > 0 &&
      tipoDeTramiteArray.length > 0 &&
      fechaDesde !== "" &&
      fechaHasta !== ""
    ) {
      await generaXlsReporteIndicadoresEspecialesDetallado(
        sucursalIdArray,
        tipoDeTramiteArray,
        fechaDesde,
        fechaHasta
      )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "reporte-indicadores-especiales.xls");
          document.body.appendChild(link);
          link.click();
          return response.data;
        })
        .catch((error) => {
          if (error.response?.status !== 401) {
            window.alert("Hubo un error, por favor intentar en otro momento");
          }
        });
    }
  };
  function descargarxls() {
    swal
      .fire({
        position: "center",
        title: "Reporte Indicadores Especiales Detallado",
        text: "Se iniciara la descarga del Reporte en formato .xls",
        icon: "warning",
        confirmButtonColor: "#009bdb",
        confirmButtonText: "Sí",
        showCancelButton: true,
        cancelButtonText: "No",
        cancelButtonColor: "#b7b7b7",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return generaXlsReporteFunction(
            sucursalIdArray,
            tipoDeTramiteArray,
            fechaDesde,
            fechaHasta
          );
        },
        allowOutsideClick: () => !swal.isLoading(),
      })
      .then((result) => {
        if (!result.value) {
          history.push("/backOfficeL");
        } else {
          swal
            .fire({
              position: "center",
              title: "Reporte descargado con exito",
              icon: "success",
              confirmButtonColor: "#009bdb",
              confirmButtonText: "Continuar",
            })
            .then((result) => {
              if (result.value) {
                history.push("/backOfficeL");
              }
            });
        }
      });
  }
  descargarxls();

  return <div></div>;
};

export default PopupReporteDetallado;
