export const sucursalesDeshabiltarCAUFiltradaArray = JSON.parse(
  localStorage.getItem("sucursalesDeshabiltarCAU")
);

export const sucursalesVerAgendaFiltradaArray = JSON.parse(
  localStorage.getItem("sucursalesVerAgenda")
);

export const sucursalesActualizarPuestosFiltradaArray = JSON.parse(
  localStorage.getItem("sucursalesActualizarPuestos")
);

export const sucursalesReportesFiltradaArray = JSON.parse(
  localStorage.getItem("sucursalesReportes")
);

export const sucursalesTurnoInSitu = JSON.parse(
  localStorage.getItem("sucursalesTurnoInSitu") //
);
export const reportesProximoTurnoDisponibleSucursales = JSON.parse(
  localStorage.getItem("sucursalesReporteProximoTurnoDisponible") //
);

export const administrarTiemposDeAtencion = JSON.parse(
  localStorage.getItem("administrarTiemposDeAtencion") //
);

export const administrarPuestosTotales = JSON.parse(
  localStorage.getItem("administrarPuestosTotales") //
);

export const verTurnosEnElDiaSucursales = JSON.parse(
  localStorage.getItem("sucursalesVerTurnosEnElDia") //
);

export const sucursalesVerReporteAtencionDeTurnosEnElDia = JSON.parse(
  localStorage.getItem("sucursalesVerReporteAtencionDeTurnoEnElDia") //
);

export const verAgendaString = "verAgenda";

export const deshabilitarCAUString = "deshabilitarCAU";

export const actualizarPuestosString = "actualizarPuestos";

export const emitirReportesString = "emitirReportes";

export const actualizarFeriadosString = "administrarFeriados";

export const turnoInSituString = "turnoInSitu";

export const reporteProximoTurnoDisponibleString = "reporteProximoTurno";

export const bloquearHorariosString = "bloqueoHorarios";

export const administrarPuestosTotalesString = "puestosTotales";

export const administrarBreakString = "administrarBreak";

export const administrarTiemposDeAtencionString = "tiempoAtencion";

export const verTurnosEnElDiaString = "verAtencionDeTurnosEnElDia";

export const verReporteTurnosEnElDiaString = "verReporteAtencionDeTurnoEnElDia";
