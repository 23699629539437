import React from "react";
import ClearIcon from "@material-ui/icons/Clear";
import {
  TableRow,
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableBody,
  Paper,
  Button,
} from "@material-ui/core";
import swal from "sweetalert2";

const TablaExcepciones = ({ eliminarExcepcionFunc, excepcionesArray }) => {
  return (
    <div>
      <p>Excepciones al Horario de break de la Sucursal</p>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="simple table">
          <TableHead variant="head">
            <TableRow>
              <TableCell id="tableCell" align="center">
                Fecha desde
              </TableCell>
              <TableCell id="tableCell" align="center">
                Fecha hasta
              </TableCell>
              <TableCell id="tableCell" align="center">
                Hora desde
              </TableCell>
              <TableCell id="tableCell" align="center">
                Hora hasta
              </TableCell>

              <TableCell id="tableCell" align="center">
                Eliminar
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {excepcionesArray &&
              excepcionesArray.map((item) => (
                <TableRow>
                  <TableCell
                    id="tableCell"
                    component="th"
                    scope="row"
                    align="center"
                  >
                    {item.fechaDesde.split("-").reverse().join("/")}
                  </TableCell>
                  <TableCell id="tableCell" align="center">
                    {item.fechaHasta.split("-").reverse().join("/")}
                  </TableCell>
                  <TableCell id="tableCell" align="center">
                    {item.horaDesde}
                  </TableCell>
                  <TableCell id="tableCell" align="center">
                    {item.horaHasta}
                  </TableCell>

                  <TableCell id="tableCell" align="center">
                    {
                      <Button
                        onClick={() =>
                          swal
                            .fire({
                              title: "CONFIRMAR OPERACION",
                              text: `¿Está seguro que desea eliminar horario de break seleccionado ${
                                item.horaDesde
                              } / ${
                                item.horaHasta
                              } desde el día ${item.fechaDesde
                                .split("-")
                                .reverse()
                                .join("/")} hasta el día ${item.fechaHasta
                                .split("-")
                                .reverse()
                                .join("/")}`,
                              icon: "warning",
                              confirmButtonColor: "#009bdb",
                              confirmButtonText: "Sí",
                              showCancelButton: true,
                              cancelButtonText: "No",
                              cancelButtonColor: "#b7b7b7",
                            })
                            .then((result) => {
                              if (result.value) {
                                eliminarExcepcionFunc(item.id);
                              }
                            })
                        }
                      >
                        <ClearIcon style={{ color: "red" }} />
                      </Button>
                    }
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default TablaExcepciones;
