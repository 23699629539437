import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import swal from "sweetalert2";

import {
  Grid,
  Paper,
  TableRow,
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableBody,
  Box,
  LinearProgress,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { getTurnosByFecha_Caja_Sucursal, imprimirCSV, printTurno } from "api";
import { Pagination } from "@material-ui/lab";
import usePagination from "Hooks/usePagination";
import LoaderSpinner from "../Loader/LoaderSpinner";

const TablaResultadosReporte = ({
  fechaDesde,
  fechaHasta,
  imprimir,
  setImprimir,
  setHabilitado,
  setShowResults,
  enSucursal,
  showResults,
}) => {
  const [sucursal, setSucursal] = useState([]);
  const [tramite, setTramite] = useState([]);
  const [resultados, setResultados] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [flag, setFlag] = useState(false);
  function swalWarning() {
    swal
      .fire({
        position: "center",
        icon: "warning",
        title: "Reporte Exportable",
        text: "El rango de fecha seleccionado contiene más de 10.000 turnos y se generará un archivo en formato CSV para su descarga. ¿Desea continuar?",
        confirmButtonColor: "#009bdb",
        confirmButtonText: "Sí",
        showCancelButton: true,
        cancelButtonText: "No",
        cancelButtonColor: "#b7b7b7",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return handlePrintCSV(
            fechaDesde,
            fechaHasta,
            sucursalIdArray,
            tipoDeTramiteArray
          );
        },
        allowOutsideClick: () => !swal.isLoading(),
      })
      .then((result) => {
        if (!result.value) {
          setHabilitado(false);
          setShowResults(false);
        }
      });
  }
  function swalWarningError() {
    swal.fire({
      icon: "warning",
      title: "Advertencia",
      text: `Error al generar el reporte, intente nuevamente`,
      confirmButtonColor: "#009bdb",
    });
  }

  const sucursalIdArray = useSelector(
    (state) => state.sucursales.sucursalesArray
  );
  const tipoDeTramiteArray = useSelector(
    (state) => state.tramites.tramitesArray
  );

  const condicion = enSucursal; // Este sería el valor de la variable que estás evaluando

  const validateOnSucursal =
    condicion === "ESPONTANEO"
      ? "ESPONTANEO"
      : condicion === "ONLINE"
      ? "ONLINE"
      : condicion === "PROGRAMADO"
      ? "PROGRAMADO"
      : "";

  async function getTurnosByFecha_Caja_SucursalFunc() {
    if (
      sucursalIdArray.length > 0 &&
      tipoDeTramiteArray.length > 0 &&
      fechaDesde !== "" &&
      fechaHasta !== ""
    ) {
      try {
        const res = await getTurnosByFecha_Caja_Sucursal(
          fechaDesde,
          sucursalIdArray,
          tipoDeTramiteArray,
          fechaHasta,
          validateOnSucursal
        );
        setResultados(res.data);
        setIsLoading(false);
        setFlag(true);
        return res.data;
      } catch (err) {
        if (
          err.response.status === 400 &&
          err.response.data.message === "Supera el maximo valor permitido"
        ) {
          swalWarning();
        }

        if (err.response.status === 504) {
          swalWarningError();
        }

        if (err.response?.status !== 400 && err.response?.status !== 401) {
          window.alert(
            "No se puede obtener la información solicitada en estos momentos, por favor intentá nuevamente más tarde."
          );
          // window.location.reload();
        }
      }
    }
  }

  const turnosCancelados = resultados?.content?.reduce(
    (contador, resultado) => {
      if (resultado.cancelado === true) return contador + 1;
      else return contador;
    },
    0
  );

  const turnosProgramados = resultados?.content?.reduce(
    (contador, resultado) => {
      if (resultado.tipoTurno === "PROGRAMADO") return contador + 1;
      else return contador;
    },
    0
  );
  const turnosEspontaneos = resultados?.content?.reduce(
    (contador, resultado) => {
      if (resultado.tipoTurno === "ESPONTANEO") return contador + 1;
      else return contador;
    },
    0
  );
  const turnosOnLine = resultados?.content?.reduce((contador, resultado) => {
    if (resultado.tipoTurno === "ONLINE") return contador + 1;
    else return contador;
  }, 0);

  async function handlePrint() {
    setImprimir(true);
    await printTurno(
      sucursalIdArray,
      fechaDesde,
      fechaHasta,
      tipoDeTramiteArray,
      validateOnSucursal
    )
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });

        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        setImprimir(false);
        setHabilitado(false);
        setShowResults(false);
      })
      .catch((error) => {
        console.log({ error });
      });
  }
  async function handlePrintCSV(
    fechaDesde,
    fechaHasta,
    sucursalesId,
    tipoCajaId,
    enSucursal
  ) {
    await imprimirCSV(
      fechaDesde,
      fechaHasta,
      sucursalesId,
      tipoCajaId,
      validateOnSucursal
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "turnos.csv");
        document.body.appendChild(link);
        link.click();
        setHabilitado(false);
        setShowResults(false);
        return response.data;
      })
      .catch((error) => {
        if (error.response?.status !== 401) {
          window.alert("Hubo un error, por favor intentar en otro momento");
          setHabilitado(false);
          setShowResults(false);
          setIsLoading(false);
        }
      });
  }

  const per_Page = 50;
  const resultadosParaPaginar = resultados?.content;
  const count = Math.ceil(resultadosParaPaginar?.length / per_Page);
  const resultadoPaginado = usePagination(resultadosParaPaginar, per_Page);

  const handleChange = (e, p) => {
    setPage(p);
    resultadoPaginado.jump(p);
  };

  useEffect(() => {
    setSucursal(sucursalIdArray);
    setTramite(tipoDeTramiteArray);
  }, []);

  useEffect(() => {
    getTurnosByFecha_Caja_SucursalFunc();
  }, []);
  useEffect(() => {
    if (flag === true) {
      setShowResults(false);
    }
  }, [sucursalIdArray, tipoDeTramiteArray, fechaDesde, fechaHasta, enSucursal]);

  return (
    <div style={{ width: "100%", marginLeft: "1%", marginBottom: "3%" }}>
      {isLoading ? (
        <LoaderSpinner />
      ) : (
        <>
          <Grid
            container
            className="botonera__reportes-csv"
            style={{ zIndex: "100", marginBottom: "-3%" }}
          >
            {imprimir === false ? (
              <>
                <Grid item xs>
                  <Button
                    onClick={() =>
                      handlePrintCSV(
                        fechaDesde,
                        fechaHasta,
                        sucursalIdArray,
                        tipoDeTramiteArray
                      )
                    }
                    style={{
                      textTransform: "none",
                      marginLeft: "61%",
                      alignSelf: "center",
                      backgroundColor: "#0055a6",
                      color: "white",
                      width: "15%",
                      zIndex: "100",
                      marginBottom: "-6%",
                    }}
                    variant="contained"
                  >
                    CSV
                  </Button>
                </Grid>
                <Grid item xs>
                  <Button
                    className="btn__generar-reporte-multiple"
                    style={{ marginLeft: "28%" }}
                    onClick={() => {
                      handlePrint(
                        sucursalIdArray,
                        fechaDesde,
                        fechaHasta,
                        tipoDeTramiteArray
                      );
                    }}
                    variant="contained"
                  >
                    Pdf
                  </Button>
                </Grid>
              </>
            ) : (
              <CircularProgress style={{ marginLeft: "50%" }} />
            )}
          </Grid>

          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Grid item xs={9}>
              <p
                style={{
                  marginTop: "5px",
                  fontSize: "18px",
                  fontFamily: "Roboto",
                  fontWeight: 400,
                }}
                className="title__texto-listado"
              >
                {`Listado de turnos desde el día ${fechaDesde
                  .split("-")
                  .reverse()
                  .join("/")} hasta el día ${fechaHasta
                  .split("-")
                  .reverse()
                  .join("/")}`}
              </p>
              <div className="turno__contador">
                <p className="turno__subtitle">
                  Turnos Cancelados = {turnosCancelados}
                </p>
                <p className="turno__subtitle">
                  Turnos Programados = {turnosProgramados}
                </p>
                <p className="turno__subtitle">
                  Turnos Espontáneos = {turnosEspontaneos}
                </p>
                <p className="turno__subtitle">
                  Turnos Online = {turnosOnLine}
                </p>
              </div>
            </Grid>
          </Grid>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: "#EDEBEC" }}>
                  <TableCell id="tableCell" align="center">
                    ID
                  </TableCell>
                  <TableCell id="tableCell" align="center">
                    T
                  </TableCell>
                  <TableCell id="tableCell" align="center">
                    Sucursal
                  </TableCell>
                  <TableCell id="tableCell" align="center">
                    Fecha
                  </TableCell>
                  <TableCell id="tableCell" align="center">
                    Hora
                  </TableCell>
                  <TableCell id="tableCell" align="center">
                    Tipo de Turno
                  </TableCell>
                  <TableCell id="tableCell" align="center">
                    Contacto
                  </TableCell>
                  <TableCell id="tableCell" align="center">
                    DNI
                  </TableCell>
                  <TableCell id="tableCell" align="center">
                    Cuenta
                  </TableCell>
                  <TableCell id="tableCell" align="center">
                    Teléfono
                  </TableCell>
                  <TableCell id="tableCell" align="center">
                    Email
                  </TableCell>
                  <TableCell id="tableCell" align="center">
                    Estado del Turno
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resultados.content &&
                  resultadoPaginado.currentData().map((turno) => (
                    /* turno.cancelado === false &&  */ <TableRow
                      key={turno.id}
                    >
                      <TableCell
                        id="tableCell"
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {turno.id}
                      </TableCell>
                      <TableCell
                        id="tableCell"
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {turno.caja?.tipo?.nombre}
                      </TableCell>
                      <TableCell id="tableCell" align="center">
                        {turno.sucursal.localidad.nombre}
                      </TableCell>
                      <TableCell id="tableCell" align="center">
                        {turno.fecha.split("-").reverse().join("/")}
                      </TableCell>
                      <TableCell id="tableCell" align="center">
                        {turno.hora}
                      </TableCell>
                      <TableCell id="tableCell" align="center">
                        {turno.tipoTurno}
                      </TableCell>
                      <TableCell id="tableCell" align="center">
                        {`${turno.cliente.nombre.toUpperCase()} ${turno.cliente.apellido.toUpperCase()}`}
                      </TableCell>
                      <TableCell id="tableCell" align="center">
                        {turno.cliente.dni}
                      </TableCell>
                      <TableCell id="tableCell" align="center">
                        {turno.cliente.cuenta}
                      </TableCell>
                      <TableCell id="tableCell" align="center">
                        {turno.cliente.telefono}
                      </TableCell>
                      <TableCell id="tableCell" align="center">
                        {turno.cliente.email}
                      </TableCell>
                      <TableCell id="tableCell" align="center">
                        {turno?.estado ? turno?.estado : "Sin dato"}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
              marginTop: "1%",
            }}
          >
            {resultados?.content?.length > 50 && (
              <Box component="span">
                <Pagination
                  count={count}
                  page={page}
                  onChange={handleChange}
                  defaultPage={1}
                  color="primary"
                  showFirstButton
                  showLastButton
                />
              </Box>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default TablaResultadosReporte;
