import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import swal from "sweetalert2";
import { tomarTurnoAtencion, finalizarTurnoAtencion } from "api";
import { Link } from "react-router-dom";
import {
  Grid,
  Paper,
  TableRow,
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableBody,
  Box,
  Button,
} from "@material-ui/core";
import { getTurnosByFecha_Caja_Sucursal } from "api";
import LoaderSpinner from "../Loader/LoaderSpinner";

const TablaVerTurnosEnElDia = ({
  fecha,
  setTurnoSeleccionado,
  turnoSeleccionado,
  setTurnoActivo,
  setReload,
  reload,
  setShowResults,
  showResults,
  handleGenerarReporte,
}) => {
  const sucursalIdArray = useSelector(
    (state) => state.sucursales.sucursalesArray
  );
  const tipoDeTramiteArray = useSelector(
    (state) => state.tramites.tramitesArray
  );

  const enSucursal = "";
  const [resultados, setResultados] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [flag, setFlag] = useState(false);

  /*  const swalTurnoConAtencionYaIniciada = () => {
    swal.fire({
      position: "center",
      icon: "warning",
      title: "Atencion",
      text: "El trámite seleccionado ha sido iniciado en otro puesto de atención",
      showConfirmButton: true,
      confirmButtonText: "Aceptar",
    });
  }; */

  const functionAtencionYaIniciadaAsync = async () => {
    try {
      await tomarTurnoAtencion(turnoSeleccionado.id);
      setTurnoActivo(true);
      setTurnoSeleccionado("");
    } catch (err) {
      if (err.response.status === 412) {
        setTurnoActivo(false);
        setShowResults(true);
        setReload(!reload);
        // swalTurnoConAtencionYaIniciada();
        swal.fire({
          position: "center",
          icon: "warning",
          title: "Atencion",
          text: `${err.response.data.message}`,
          showConfirmButton: true,
          confirmButtonText: "Aceptar",
        });
      }
    }
  };
  async function getTurnosByFecha_Caja_SucursalFunc() {
    try {
      const res = await getTurnosByFecha_Caja_Sucursal(
        fecha,
        sucursalIdArray,
        tipoDeTramiteArray,
        fecha,
        enSucursal
      );
      setResultados(res.data);
      setIsLoading(false);
      setFlag(true);
      return res.data;
    } catch (err) {
      if (err.response.status !== 400 && err.response.status !== 401) {
        window.alert(
          "No se puede obtener la información solicitada en estos momentos, por favor intentá nuevamente más tarde."
        );
      }
    }
  }

  const swalTurnoSeleccionado = () => {
    swal
      .fire({
        html: `<div className="swall_datos" style="color: rgb(20, 20, 20);">
        <div>N° Turno: ${turnoSeleccionado.id}</div>
        <div>Nombre y Apellido: ${turnoSeleccionado.cliente.nombre.toUpperCase()} ${turnoSeleccionado.cliente.apellido.toUpperCase()}</div>
        <div>DNI: ${turnoSeleccionado.cliente.dni}</div>
        <div>Fecha: ${turnoSeleccionado.fecha}</div>
        <div>Hora: ${turnoSeleccionado.hora}</div>
        <div>Tipo de trámite: ${turnoSeleccionado.caja.tipo.nombre}</div>
        <div>Centro de Atención: ${turnoSeleccionado.caja.sucursal.nombre}</div>
      </div>
      `,
        confirmButtonText: "Iniciar Trámite",
        confirmButtonColor: "#F86436",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      })
      .then((result) => {
        if (result.isConfirmed) {
          functionAtencionYaIniciadaAsync();
        } else {
          setReload(!reload);
          setTurnoSeleccionado("");
        }
      });
  };

  const resultadosVerEnLaTabla = resultados?.content?.filter(
    (resultado) => resultado.horaFinAtencion === null
  );

  useEffect(() => {
    getTurnosByFecha_Caja_SucursalFunc();
  }, [reload]);

  const iniciarAtencion = function (index) {
    setTurnoSeleccionado(resultadosVerEnLaTabla[index]);
  };

  useEffect(() => {
    if (turnoSeleccionado != "") {
      swalTurnoSeleccionado();
    }
  }, [turnoSeleccionado]);

  useEffect(() => {
    const interval = setInterval(() => {
      getTurnosByFecha_Caja_SucursalFunc();
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, [sucursalIdArray, tipoDeTramiteArray]);

  useEffect(() => {
    if (flag === true) {
      setShowResults(false);
    }
  }, [sucursalIdArray, tipoDeTramiteArray]);

  useEffect(() => {
    if (flag === true) {
      getTurnosByFecha_Caja_SucursalFunc();
    }
  }, [showResults]);
  console.log({ resultadosVerEnLaTabla });

  return (
    <div style={{ width: "100%", marginLeft: "1%", marginBottom: "3%" }}>
      {isLoading ? (
        <LoaderSpinner />
      ) : (
        <>
          {" "}
          {resultadosVerEnLaTabla.length === 0 ? (
            <>
              <div style={{ marginLeft: "6%", color: "#F86436" }}>
                <h4>
                  Estimado/a usuario/a: Recuerde que para iniciar la atención de
                  un turno espontáneo deberá agregar el mismo en la sección:
                  <Link
                    to="/turnoEnCau"
                    style={{
                      textTransform: "none",
                      color: "#F86436",
                      marginLeft: "5px",
                    }}
                  >
                    Turno Espontáneo
                  </Link>
                </h4>
              </div>
              <Box
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                <Paper elevation={3} style={{ padding: "3%" }}>
                  <p style={{ color: "#F86436", fontWeight: "700" }}>
                    Todos los turnos han sido atendidos
                  </p>
                </Paper>
              </Box>
            </>
          ) : (
            <>
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              ></Grid>
              <div style={{ marginLeft: "6%", color: "#F86436" }}>
                <h4>
                  Estimado/a usuario/a: Recuerde que para iniciar la atención de
                  un turno espontáneo deberá agregar el mismo en la sección:
                  <Link
                    to="/turnoEnCau"
                    style={{
                      //textDecoration: "none",
                      textTransform: "none",
                      color: "#F86436",
                      marginLeft: "5px",
                    }}
                  >
                    Turno Espontáneo
                  </Link>
                </h4>
              </div>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow style={{ backgroundColor: "#EDEBEC" }}>
                      <TableCell id="tableCell" align="center">
                        DNI
                      </TableCell>
                      <TableCell id="tableCell" align="center">
                        Nombre y Apellido
                      </TableCell>
                      <TableCell id="tableCell" align="center">
                        CAU
                      </TableCell>
                      <TableCell id="tableCell" align="center">
                        Tipo de Trámite
                      </TableCell>
                      <TableCell id="tableCell" align="center">
                        Hora
                      </TableCell>
                      <TableCell id="tableCell" align="center">
                        Estado
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {resultadosVerEnLaTabla?.map(
                      (turno, index) =>
                        turno.cancelado === false && (
                          <TableRow key={turno.id}>
                            <TableCell
                              id="tableCell"
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {turno.cliente.dni}
                            </TableCell>
                            <TableCell id="tableCell" align="center">
                              {`${turno.cliente.nombre.toUpperCase()} ${turno.cliente.apellido.toUpperCase()}`}
                            </TableCell>
                            <TableCell id="tableCell" align="center">
                              {turno.sucursal.nombre}
                            </TableCell>
                            <TableCell id="tableCell" align="center">
                              {turno.caja?.tipo?.nombre}
                            </TableCell>
                            <TableCell id="tableCell" align="center">
                              {turno.hora}
                            </TableCell>
                            <TableCell id="tableCell" align="center">
                              {turno.horaInicioAtencion === null ? (
                                <Button
                                  onClick={() => iniciarAtencion(index)}
                                  style={{
                                    backgroundColor: "rgb(248, 100, 54, 0.5)",
                                    border: "1px solid #F86436",
                                  }}
                                >
                                  Iniciar atención
                                </Button>
                              ) : (
                                <p
                                  style={{ color: "green", fontWeight: "700" }}
                                >
                                  En Progreso
                                </p>
                              )}
                            </TableCell>
                          </TableRow>
                        )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default TablaVerTurnosEnElDia;
