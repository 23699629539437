import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#0055a6",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "#0055a6",
    "&:hover": {
      backgroundColor: "#0055a6",
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const reportesAFiltrar = [
  {
    name: "REPORTES DE TURNOS",
    value: 1,
    rol: sessionStorage.getItem("emitirReportes"),
  },
  {
    name: "REPORTES DE INDICADORES ESPECIALES",
    value: 2,
    rol: sessionStorage.getItem("reporteProximoTurno"),
  },
  {
    name: "REPORTES DE ATENCIÓN DE TURNOS DURANTE EL DÍA",
    value: 3,
    rol: sessionStorage.getItem("verReporteAtencionDeTurnoEnElDia"),
  },
  {
    name: "REPORTES DETALLADO DE INDICADORES ESPECIALES",
    value: 4,
    rol: sessionStorage.getItem("reporteProximoTurno"),
  },
];

const reportesFiltrados = reportesAFiltrar.filter((e) => e.rol === "true");

const reporteTurnoSegunSolicitud = [
  { name: "ONLINE", value: "ONLINE" },
  { name: "PROGRAMADO", value: "PROGRAMADO" },
  { name: "ESPONTÁNEO", value: "ESPONTANEO" },
  { name: "TODOS", value: "algo" },
];

const cajaTramiteEstado = [
  { name: "HABILITADO", value: true },
  { name: "NO HABILITADO", value: false },
  { name: "TODOS", value: "algo" },
];

export {
  MenuProps,
  useStyles,
  reportesFiltrados,
  reporteTurnoSegunSolicitud,
  cajaTramiteEstado,
};
