import React from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

const UsuarioBloqueadoRealizandoTramite = () => {
  const history = useHistory();
  Swal.fire({
    text: "Tenés una atención de turno en progreso que debes finalizar para realizar otras operaciones en el backoffice",
    confirmButtonColor: "#F86436",
    confirmButtonText: "Volver al trámite",
  }).then((result) => {
    if (result.isConfirmed) {
      history.push("/VerTurnosEnElDia");
    } else {
      history.push("/backOfficeL");
    }
  });

  return <></>;
};

export default UsuarioBloqueadoRealizandoTramite;
