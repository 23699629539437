import React from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { finalizarTurnoAtencion } from "api";

const PopupTramiteEnCurso = ({ numeroTurno, fechaTurno, horaTurno }) => {
  const history = useHistory();
  Swal.fire({
    title: `Trámite en Curso `,
    html: `<div> N° Turno: <b> ${numeroTurno}</b></div>  
           <div> a las <b> ${horaTurno}</b></div>  
           <div> del día  <b> ${fechaTurno}</b></div> `,
    icon: "warning",
    confirmButtonText: "Finalizar Trámite",
  }).then((result) => {
    localStorage.removeItem("timer");
    if (result.isConfirmed) {
      finalizarTurnoAtencion();
      Swal.fire({
        icon: "success",
        title: "Trámite finalizado",
        confirmButtonText: "Aceptar",
      }).then((result) => {
        if (result.isConfirmed) {
          history.push("/backOfficeL");
        }
      });
    } else {
      history.push("/backOfficeL");
    }
  });

  return <></>;
};

export default PopupTramiteEnCurso;
