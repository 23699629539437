import React, { useState, useEffect } from "react";
import { Container, InputLabel, Select, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import Header from "usuariosOnline/components/Header/Header";
import {
  getFeriados,
  generaXlsReporteIndicadoresEspecialesDetallado,
} from "api";
import { useFormik } from "formik";
import { TextField, FormControl, Typography } from "@material-ui/core";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import swal from "sweetalert2";
import AddIcon from "@material-ui/icons/Add";
import "./Reportes.css";
import { differenceInDays, subDays, eachDayOfInterval, format } from "date-fns";
import CircularLoader from "./CircularLoader";
import SesionExpirada from "backOfficeUsuariosCau/components/SesionExpirada/SesionExpirada";
import MultipleSelectCaja from "backOfficeUsuariosCau/components/MultiSelect/MultipleSelectCaja.js";
import TablaResultadosReporte from "./TablaResultadosReporte";
import MultipleSelectReporteSucursales from "./MultipleSelectReporteSucursales";
import ProximoTurnoDisponibleTablaResultados from "../ProximoTurnoDisponibleTablaResultados/ProximoTurnoDisponibleTablaResultados";
import ReporteAtencionEnElDiaTabla from "./ReporteAtencionEnElDiaTabla";
import {
  reportesFiltrados,
  reporteTurnoSegunSolicitud,
  cajaTramiteEstado,
} from "./utilies";
import ButtonEscape from "const/ButtonEscape";
import { useSelector } from "react-redux";
import PopupReporteDetallado from "../PopupReporteDetallado/PopupReporteDetallado";

const Reportes = () => {
  const sucursalesRender = JSON.parse(
    localStorage.getItem("sucursalesFiltradasReRender")
  );
  const sucursalesReRenderizadas = sucursalesRender?.length + 1;

  const cajasRender = JSON.parse(localStorage.getItem("tipoCajaReRender"));
  const cajasReRenderizadas = cajasRender?.length + 1;

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
    },
    textField: {
      marginTop: theme.spacing(6),
    },
    table: {},
    customOutline: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "grey",
        borderRadius: "1px",
        padding: "1px",
      },
    },
  }));

  const welcome = "Bienvenido !";
  const classes = useStyles();

  const [clientWidth, setClientWidth] = useState("");
  const [tipoCajaId, setTipoCajaId] = useState();
  const [showResults, setShowResults] = useState(false);
  const [feriados, setFeriados] = useState([]);
  const [habilitado, setHabilitado] = useState(false);
  const [errorSucursales, setErrorSucursales] = useState("");
  const [imprimir, setImprimir] = useState(false);
  const [isLoadingCircular, setIsLoadingCircular] = useState(false);
  const [changeTramite, setChangeTramite] = useState([]);
  const [errorTipoTramite, setErrorTipoTramite] = useState("");
  const [changeSucursal, setChangeSucursal] = useState([]);
  const [tipoDeReporte, setTipoDeReporte] = useState("");
  const [obtencionDelTurno, setObtencionDelTurno] = useState("");
  const [tipoDeTramiteHabilitado, setTipoDeTramiteHabilitado] = useState("");
  const [mostrarPopup, setMostrarPopup] = useState(false);

  const formik = useFormik({
    initialValues: {
      sucursalId: [],
      fechaDesde: "",
      fechaHasta: "",
      tipoCaja: [],
      obtencionDelTurno: obtencionDelTurno,
      tipoDeTramiteHabilitado: tipoDeTramiteHabilitado,
    },

    onSubmit: (values) => {
      const {
        sucursalId,
        tipoCaja,
        fechaDesde,
        fechaHasta,
        obtencionDelTurno,
      } = values;
      console.log({ values });
    },
  });

  const handleChangeSelectReporte = (event) => {
    setTipoDeReporte(event.target.value);
  };

  const handleChangeSelectTramiteEstado = (event) => {
    setTipoDeTramiteHabilitado(event.target.value);
  };

  const rangoDeFechaParaGenerarReporte = () => {
    const toDate = (dateStr) => {
      const [year, month, day] = dateStr.split("-");
      return new Date(year, month - 1, day);
    };
    let validacionTipoDeReporteSinFechaHasta =
      formik.values.fechaHasta !== ""
        ? formik.values.fechaHasta
        : formik.values.fechaDesde;
    const daysBetween = differenceInDays(
      toDate(validacionTipoDeReporteSinFechaHasta),
      toDate(formik.values.fechaDesde)
    );
    return daysBetween;
  };

  const numeroDeDiasParaGenerarReporte = rangoDeFechaParaGenerarReporte();

  // Fecha dada
  const dateSelectCalendar = new Date(formik.values.fechaDesde); // MM/DD/YYYY

  // Fecha actual
  const dateToday = new Date();

  // Calcula la diferencia en días
  const diffDays = differenceInDays(dateToday, dateSelectCalendar);

  useEffect(() => {
    try {
      setClientWidth(document.documentElement.clientWidth);
      getFeriadosFunc();
    } catch (err) {
      console.log(err);
    }
  }, [tipoCajaId]);

  async function getFeriadosFunc() {
    const res = await getFeriados();
    setFeriados(res.data);
  }

  function populateFeriados(holiday) {
    const feriadoData = [];
    if (holiday) {
      for (let a = 0; a < holiday.length; a++) {
        const day = holiday[a].fecha.replace(/-/g, "/");
        feriadoData.push(new Date(day));
      }
    }
    return feriadoData;
  }

  function swalWarningFechaHasta() {
    swal.fire({
      position: "center",
      title: "Rango de Fecha Inválido",
      text: `El rango de Fecha Hasta ${formik.values.fechaHasta
        .split("-")
        .reverse()
        .join(
          "/"
        )} debe ser posterior a la Fecha Desde ${formik.values.fechaDesde
        .split("-")
        .reverse()
        .join("/")}`,
      icon: "error",
      confirmButtonColor: "#009bdb",
      confirmButtonText: "Continuar",
    });
  }
  const swalWarningFechaAnterior = () =>
    swal.fire({
      position: "center",
      title: "Rango de Fecha Inválido",
      text: `El rango de fechas seleccionado no puede exceder los 60 días previos a la fecha de consulta.`,
      icon: "error",
      confirmButtonColor: "#009bdb",
      confirmButtonText: "Continuar",
    });

  const handleChangeTramites = (value) => {
    setChangeTramite(value);
  };
  const handleChangeSucursales = (value) => {
    setChangeSucursal(value);
  };

  const handleGenerarReporte = async () => {
    if (
      changeSucursal.length === 0 ||
      changeSucursal.length === sucursalesReRenderizadas
    ) {
      setErrorSucursales(" ");
    }
    if (formik.values.fechaDesde === "") {
      formik.setFieldError("fechaDesde", " ");
    }
    if (formik.values.fechaHasta === "") {
      formik.setFieldError("fechaHasta", " ");
    }

    if (
      changeTramite.length === 0 ||
      changeTramite.length === cajasReRenderizadas
    ) {
      setErrorTipoTramite(" ");
    }
    if (
      changeSucursal.length > 0 &&
      changeTramite.length > 0 &&
      changeTramite.length !== cajasReRenderizadas &&
      changeSucursal.length !== sucursalesReRenderizadas &&
      tipoDeReporte === 2
    ) {
      try {
        setIsLoadingCircular(true);
        setShowResults(true);
        setIsLoadingCircular(false);
      } catch (err) {
        if (err.response.status !== 400 && err.response.status !== 401) {
          setIsLoadingCircular(false);
          window.alert(
            "No se puede obtener la información solicitada en estos momentos, por favor intentá nuevamente más tarde."
          );
        }
      }
    }
    if (
      changeSucursal.length > 0 &&
      changeTramite.length > 0 &&
      changeTramite.length !== cajasReRenderizadas &&
      changeSucursal.length !== sucursalesReRenderizadas &&
      tipoDeReporte === 4 &&
      formik.values.fechaDesde !== "" &&
      formik.values.fechaHasta !== ""
    ) {
      if (numeroDeDiasParaGenerarReporte < 0) {
        swalWarningFechaHasta();
      } else {
        if (diffDays <= 60) {
          setMostrarPopup(true);
        } else {
          swalWarningFechaAnterior();
        }
      }
    }

    if (
      formik.values.fechaDesde !== "" &&
      changeSucursal.length > 0 &&
      changeSucursal.length !== sucursalesReRenderizadas &&
      changeTramite.length > 0 &&
      changeTramite.length !== cajasReRenderizadas &&
      formik.values.fechaHasta !== ""
    ) {
      if (numeroDeDiasParaGenerarReporte >= 0) {
        try {
          setIsLoadingCircular(true);
          setShowResults(true);
          // setHabilitado(true);
          setIsLoadingCircular(false);
        } catch (err) {
          if (
            err.response.status === 400 &&
            err.response.data.message === "Supera el maximo valor permitido"
          ) {
            setIsLoadingCircular(false);
            setShowResults(false);
          }

          if (err.response.status !== 400 && err.response.status !== 401) {
            setIsLoadingCircular(false);
            window.alert(
              "No se puede obtener la información solicitada en estos momentos, por favor intentá nuevamente más tarde."
            );
          }
        }
      } else {
        swalWarningFechaHasta();
      }
    }
  };

  return (
    <div>
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
        fixed
      >
        <div>
          <Header
            welcome={welcome}
            button={<ButtonEscape />}
            mainPage={false}
          />
        </div>

        <div
          style={{
            marginTop: "3%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <SesionExpirada timer={900000} />
          <Grid
            container
            style={{ marginLeft: "2%" }}
            className="grid__container-header-reporte"
          >
            <Grid item xs={3}>
              <Link to="/backOfficeL" style={{ textDecoration: "none" }}>
                <Button
                  startIcon={<ArrowBackIcon />}
                  style={{
                    textTransform: "none",
                    backgroundColor: "white",
                    color: "#009BDB",
                  }}
                >
                  Volver
                </Button>
              </Link>
            </Grid>
            <Grid item xs={9}>
              <Typography
                style={{
                  marginLeft: "2%",
                  color: "#4A4A4A",
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                  fontSize: "18px",
                  lineHeight: "21.09px",
                }}
                className="title__reportes-header"
              >
                Reportes
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
              item
              xs={12}
            >
              <form
                onSubmit={formik.handleSubmit}
                style={{ alignSelf: "center", width: "45%", marginTop: "1%" }}
                className="form__Reportes-multiple"
              >
                <FormControl className={classes.formControl}>
                  <InputLabel>
                    <p
                      style={{
                        marginTop: "2%",
                        fontSize: "20px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Reporte
                    </p>
                  </InputLabel>
                  <Select
                    value={tipoDeReporte}
                    onChange={handleChangeSelectReporte}
                    disabled={habilitado}
                  >
                    {reportesFiltrados.map((reporte) => (
                      <MenuItem key={reporte.value} value={reporte.value}>
                        {reporte.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {tipoDeReporte === 1 && (
                  <FormControl className={classes.formControl}>
                    <InputLabel>
                      <p
                        style={{
                          marginTop: "2%",
                          fontSize: "20px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Tipo de Turno
                      </p>
                    </InputLabel>
                    <Select
                      name="obtencionDelTurno"
                      onChange={(e) => formik.handleChange(e)}
                      disabled={habilitado}
                      value={formik.values.obtencionDelTurno}
                    >
                      {reporteTurnoSegunSolicitud.map((reporte) => (
                        <MenuItem key={reporte.value} value={reporte.value}>
                          {reporte.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                <FormControl className={classes.formControl}>
                  <InputLabel>
                    <p
                      style={{
                        marginTop: "2%",
                        fontSize: "20px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Estado del Trámite
                    </p>
                  </InputLabel>
                  <Select
                    value={tipoDeTramiteHabilitado}
                    onChange={handleChangeSelectTramiteEstado}
                    disabled={habilitado}
                  >
                    {cajaTramiteEstado.map((caja) => (
                      <MenuItem key={caja.value} value={caja.value}>
                        {caja.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl className={classes.formControl}>
                  <MultipleSelectCaja
                    habilitado={habilitado}
                    title={"Elegí el Tipo de Trámite "}
                    error={errorTipoTramite}
                    onChange={(value) => {
                      handleChangeTramites(value);
                      setErrorTipoTramite("");
                    }}
                    //cajaHabilitada={false}
                    cajaHabilitada={tipoDeTramiteHabilitado}
                    setTipoDeTramiteHabilitado={setTipoDeTramiteHabilitado}
                    tipoDeTramiteHabilitado={tipoDeTramiteHabilitado}
                  />
                </FormControl>

                <div className={classes.formControl}>
                  <MultipleSelectReporteSucursales
                    habilitado={habilitado}
                    title={"Elegí el Centro de Atención"}
                    error={errorSucursales}
                    onChange={(value) => {
                      handleChangeSucursales(value);
                      setErrorSucursales("");
                    }}
                    true={true}
                  />
                </div>
              </form>
            </Grid>
          </Grid>

          <div style={{ width: "100%" }}>
            <Grid container>
              <Grid item xs={3}></Grid>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
                item
                xs={9}
                className="btn__puedes-agregar-mas-sucursales-container"
              >
                {" "}
                <Button
                  startIcon={<AddIcon />}
                  disabled={true}
                  style={{
                    textTransform: "none",
                    backgroundColor: "white",
                    color: "#009BDB",
                    marginLeft: "1%",
                  }}
                  className="btn__puedes-agregar-mas-sucursales"
                >
                  Podés seleccionar más de un centro
                </Button>
              </Grid>
            </Grid>
          </div>

          <br />

          {tipoDeReporte === 2 ? (
            <div style={{ display: "none" }}></div>
          ) : (
            <div style={{ width: "100%" }}>
              <FormControl
                style={{ width: "50%", marginLeft: "28%" }}
                className="form__Reportes-fechas"
              >
                <Grid container>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={4}>
                        <Typography
                          style={{
                            fontSize: "16px",
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            color: "#0055A6",
                          }}
                        >
                          Fecha desde
                        </Typography>
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                        item
                        xs={8}
                      >
                        <FormControl style={{ marginTop: "-2%", width: "70%" }}>
                          <TextField
                            id="fechaDesde"
                            type="date"
                            value={formik.values.fechaDesde}
                            selected={formik.values.fechaDesde}
                            onChange={formik.handleChange}
                            helperText={formik.errors.fechaDesde}
                            error={formik.errors.fechaDesde}
                            excludeDates={populateFeriados(feriados)}
                            disabled={habilitado}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={4}>
                        <Typography
                          style={{
                            fontSize: "16px",
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            color: "#0055A6",
                          }}
                        >
                          Fecha hasta
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        {" "}
                        <FormControl style={{ marginTop: "-2%", width: "70%" }}>
                          <TextField
                            id="fechaHasta"
                            type="date"
                            value={formik.values.fechaHasta}
                            selected={formik.values.fechaHasta}
                            onChange={formik.handleChange}
                            helperText={formik.errors.fechaHasta}
                            error={formik.errors.fechaHasta}
                            excludeDates={populateFeriados(feriados)}
                            disabled={habilitado}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </FormControl>
            </div>
          )}

          <br />
          <div
            style={{ width: "100%", marginBottom: "5%" }}
            className="btn__generar-reporte-container"
          >
            {isLoadingCircular && <CircularLoader />}
            <Button
              style={{
                textTransform: "none",
                marginLeft: "76%",
                alignSelf: "center",
                backgroundColor: "#0055a6",
                color: "white",
                width: "15%",
              }}
              className="btn__generar-reporte"
              onClick={handleGenerarReporte}
              variant="contained"
              disabled={habilitado}
            >
              Generar Reporte
            </Button>
          </div>
          <b />

          {showResults === true && tipoDeReporte === 1 && (
            <TablaResultadosReporte
              fechaDesde={formik.values.fechaDesde}
              fechaHasta={formik.values.fechaHasta}
              imprimir={imprimir}
              setImprimir={setImprimir}
              habilitado={habilitado}
              setHabilitado={setHabilitado}
              setShowResults={setShowResults}
              enSucursal={formik.values.obtencionDelTurno}
              showResults={showResults}
            />
          )}
          {showResults === true && tipoDeReporte === 2 && (
            <ProximoTurnoDisponibleTablaResultados
              imprimir={imprimir}
              setImprimir={setImprimir}
              setShowResults={setShowResults}
              showResults={showResults}
            />
          )}
          {showResults === true && tipoDeReporte === 3 && (
            <ReporteAtencionEnElDiaTabla
              fechaDesde={formik.values.fechaDesde}
              fechaHasta={formik.values.fechaHasta}
              imprimir={imprimir}
              setImprimir={setImprimir}
              setHabilitado={setHabilitado}
              setShowResults={setShowResults}
              showResults={showResults}
            />
          )}
          {tipoDeReporte === 4 && mostrarPopup && (
            <PopupReporteDetallado
              fechaDesde={formik.values.fechaDesde}
              fechaHasta={formik.values.fechaHasta}
            />
          )}
        </div>
      </Container>
    </div>
  );
};
export default Reportes;
