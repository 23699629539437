import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import TurnoConfirmado from "usuariosOnline/components/TurnoConfirmado/TurnoConfirmado";
import FormLogin from "backOfficeUsuariosCau/components/FormLogin/FormLogin";
import BuscarTurno from "backOfficeUsuariosCau/components/BuscarTurno/BuscarTurno";
import BackOffice from "backOfficeUsuariosCau/components/BackOffice/BackOffice";
import SingleLogInForm from "backOfficeUsuariosCau/components/SingleLogInForm/SingleLogInForm";
import BackOfficeLoguado from "backOfficeUsuariosCau/components/BackOfficeLogueado/BackOfficeLogueado";
import TablaResultados from "backOfficeUsuariosCau/components/TablaResultados/TablaResultados";
import CancelarCAU from "backOfficeUsuariosCau/components/CancelarCau/CancelarCAU";
import ActualizarPuestos from "backOfficeUsuariosCau/components/ActualizarPuestos/ActualizarPuestos";
import CancelarTurno from "backOfficeUsuariosCau/components/CancelarTurno/CancelarTurno";
import { Home } from "usuariosOnline/components/Home/Home";
import TurnoInsitu from "backOfficeUsuariosCau/components/TurnoInsitu/TurnoInsitu";
import ProximoTurnoDisponible from "backOfficeUsuariosCau/components/ProximoTurnoDisponible/ProximoTurnoDisponible";
import AdministrarFeriados from "backOfficeUsuariosCau/components/AdministrarFeriados/AdministrarFeriados";
import BloquearHorarios from "backOfficeUsuariosCau/components/BloquearHorarios/BloquearHorarios";
import Reportes from "backOfficeUsuariosCau/components/Reportes/Reportes";
import AdministrarBreak from "backOfficeUsuariosCau/components/AdministrarBreak/AdministrarBreak";
import AdministrarPuestosTotales from "backOfficeUsuariosCau/components/AdministrarPuestosTotales/AdministrarPuestosTotales";
import AdministrarTiemposDeAtencion from "backOfficeUsuariosCau/components/AdministrarTiemposDeAtencion/AdministrarTiemposDeAtencion";
import CancelarTurnoOnline from "usuariosOnline/components/CancelarTurnoOnline/CancelarTurnoOnline";
import VerTurnosEnElDia from "backOfficeUsuariosCau/components/VerTurnosEnElDia/VerTurnosEnElDia";
import ProgramarTurnoEnCau from "backOfficeUsuariosCau/components/ProgramarTurnoEnCau/ProgramarTurnoEnCau";
const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/turno_confirmado/" exact component={TurnoConfirmado} />
        <Route path="/backOffice" exact component={BackOffice} />
        <Route path="/administrarBreak" exact component={AdministrarBreak} />
        <Route path="/buscarTurno" exact component={BuscarTurno} />
        <Route path="/login" exact component={FormLogin} />
        <Route path="/singleLoginForm" exact component={SingleLogInForm} />
        <Route path="/BackOfficeL" exact component={BackOfficeLoguado} />
        <Route path="/tabla" exact component={TablaResultados} />
        <Route path="/cancelarCAU" exact component={CancelarCAU} />
        <Route path="/actualizarPuestos" exact component={ActualizarPuestos} />
        <Route path="/verTurnosEnElDia" exact component={VerTurnosEnElDia} />
        <Route
          path="/actualizarFeriados"
          exact
          component={AdministrarFeriados}
        />
        <Route path="/cancelarTurno" exact component={CancelarTurno} />
        <Route path="/bloquearHorarios" exact component={BloquearHorarios} />
        <Route path="/reportes" exact component={Reportes} />
        <Route
          path="/administrarPuestosTotales"
          exact
          component={AdministrarPuestosTotales}
        />
        <Route path="/turnoEnCau" exact component={TurnoInsitu} />
        <Route
          path="/proximoTurnoDisponible"
          exact
          component={ProximoTurnoDisponible}
        />
        <Route
          path="/administrarTiemposDeAtencion"
          exact
          component={AdministrarTiemposDeAtencion}
        />
        <Route
          path="/cancelar/TurnoOnline"
          exact
          component={CancelarTurnoOnline}
        />
        <Route path="/programarTurno" exact component={ProgramarTurnoEnCau} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
