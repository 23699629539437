import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Button, Divider } from "@material-ui/core";
import { useReactToPrint } from "react-to-print";
import ErrorIcon from "@material-ui/icons/Error";
import PrintIcon from "@material-ui/icons/Print";
import { QRCode } from "react-qr-svg";
import { Link } from "react-router-dom";
import * as QueryString from "query-string";
import { getTurnoConfirmado } from "api";
import { useLocation } from "react-router-dom";

import "./TurnoConfirmado.css";

const urlAySaDoc =
  "https://www.aysa.com.ar/media-library/usuarios/informacion_util/datos_utiles_para_el_usuario/Que_documentacion_necesita_2020.pdf";

const useStyles = makeStyles((theme) => ({
  printButton: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    color: "white",
    width: "250px",
    background: "#009bdb",
    "&:hover": {
      background: "#009bdb",
    },
  },
}));

const TurnoConfirmado = ({ displayNone }) => {
  let { search } = useLocation();
  let query = new URLSearchParams(search);
  let isUsuarioApp = query.get("userApp");

  const [turnoConfirmado, setTurnoConfirmado] = useState([]);
  const classes = useStyles();
  const baseUrl = window.location.host;
  const userDni = useSelector((state) => state.user.dni);
  const turnoId = useSelector((state) => state.turnoConfirmado.idTurno);
  const [verBotonesDespuesDeImprimir, setVerBotonesDespuesDeImprimir] =
    useState(true);

  async function getTurnoConfirmadoFunc(dniUser, idTurno) {
    try {
      const res = await getTurnoConfirmado(dniUser, idTurno);
      setTurnoConfirmado(res.data);
    } catch (e) {
      console.log(e);
    }
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    pageStyle: () => "@page { size: A4 portrait;} @page {margin-left: 80;}",
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        setVerBotonesDespuesDeImprimir(false);
        resolve();
      });
    },
    onAfterPrint: () => setVerBotonesDespuesDeImprimir(true),
  });

  const handleAppNativa = () => {
    let data = { name: "Guardar turno" };
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  };

  useEffect(() => {
    const params = QueryString.parse(window.location.search);
    const { userDni: userDniParams, turnoId: turnoIdParams } = params;

    if (userDniParams && turnoIdParams) {
      getTurnoConfirmadoFunc(userDniParams, turnoIdParams);
    } else {
      getTurnoConfirmadoFunc(userDni, turnoId);
    }
  }, [userDni, turnoId]);

  const { cliente, caja, hora, sucursal, fecha, token } = turnoConfirmado;

  const fechaFinal = fecha?.split("-").reverse().join("/");

  return (
    <>
      {!displayNone && (
        <>
          {" "}
          <div
            style={{ marginTop: "2%", marginBottom: "2%", paddingLeft: "10%" }}
          >
            <img
              src="https://www.aysa.com.ar/assets/Menu/img/logo.png"
              alt="aysa logo"
            />
          </div>
          <Divider style={{ height: "3px" }} />
        </>
      )}
      <div className="container-card_turno-confirmado" ref={componentRef}>
        <div className="div-container-body">
          <div>
            {turnoConfirmado ? (
              <>
                <div className="div-container-body-turno-confirmado">
                  <h2
                    style={{
                      alignSelf: "center",
                      textAling: "justify",
                      color: "#0175a3",
                    }}
                  >
                    Turno Confirmado
                  </h2>
                  {cliente && (
                    <p style={{ alignSelf: "center", marginBottom: "34px" }}>
                      {`Se ha enviado la confirmación del turno a su correo electrónico ${cliente.email}`}
                      .
                    </p>
                  )}
                  {!isUsuarioApp ? (
                    <Link
                      to={`/turno_confirmado?turnoId=${turnoId}&userDni=${userDni}`}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <QRCode
                        bgColor="#FFFFFF"
                        fgColor="#000000"
                        level="Q"
                        style={{ maxWidth: 256, alignSelf: "center" }}
                        value={`${baseUrl}/turno_confirmado?turnoId=${turnoId}&userDni=${userDni}`}
                      />
                    </Link>
                  ) : (
                    <QRCode
                      bgColor="#FFFFFF"
                      fgColor="#000000"
                      level="Q"
                      style={{ maxWidth: 256, alignSelf: "center" }}
                      value={`${baseUrl}/turno_confirmado?turnoId=${turnoId}&userDni=${userDni}`}
                    />
                  )}
                  {cliente && (
                    <>
                      <p className="texto-alineado-centro">
                        <b>Nombre: </b>
                        {`${cliente.nombre} ${cliente.apellido}`}
                      </p>
                      <p className="texto-alineado-centro">
                        <b>N° de Documento: </b> {`${cliente.dni}`}
                      </p>
                      <div className="div-spaciador_turno-confirmado">
                        <p className="texto-fecha-hora_turno-confirmado">
                          <b>Fecha: </b> {fechaFinal}
                        </p>
                        <p className="texto-fecha-hora_turno-confirmado">
                          <b>Hora: </b>{" "}
                          {`${
                            hora[0] + hora[1] + hora[2] + hora[3] + hora[4]
                          } hs`}
                        </p>
                      </div>
                      <p className="texto-alineado-centro">
                        <b>Tipo de tramite:</b> {caja.tipo.nombre}
                      </p>
                      <p className="texto-alineado-centro">
                        <b>Centro de Atención:</b> {sucursal.nombre},{" "}
                        {sucursal.direccion} - {sucursal.localidad.nombre}
                      </p>
                      <p className="texto-alineado-centro">
                        <b>Importante: </b>No olvides traer tu DNI y recordá que
                        este comprobante te servirá para ser atendido en nuestro
                        Centro de Atención.<br></br>
                      </p>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {verBotonesDespuesDeImprimir && (
                          <>
                            {" "}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <Button
                                variant="contained"
                                className={classes.printButton}
                                onClick={() => {
                                  !isUsuarioApp
                                    ? handlePrint()
                                    : handleAppNativa();
                                }}
                                startIcon={<PrintIcon />}
                              >
                                Imprimir turno
                              </Button>
                              <Link
                                to={`/cancelar/TurnoOnline?token=${token}`}
                                style={{ textDecoration: "none" }}
                              >
                                <Button
                                  style={{
                                    backgroundColor: "red",
                                    width: "250px",
                                    border: "none",
                                    borderRadius: "5px",
                                    height: "37px",
                                    marginBottom: "20px",
                                    color: "white",
                                  }}
                                >
                                  Cancelar Turno
                                </Button>
                              </Link>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ErrorIcon fontSize="large" />
                  <h2>No pudimos obtener tu turno</h2>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TurnoConfirmado;
