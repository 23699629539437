import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tramitesArray: [],
};

export const tramitesSlice = createSlice({
  name: "tramites",
  initialState: initialState,
  reducers: {
    setTramitesValues: (state, action) => {
      state.tramitesArray = action.payload;
    },
    resetTramitesValues: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const { setTramitesValues, resetTramitesValues } = tramitesSlice.actions;

export default tramitesSlice.reducer;
