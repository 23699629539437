import React, { useState, useEffect } from "react";
import { Grid, Container, Paper, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import Header from "usuariosOnline/components/Header/Header";
import { getSucursales, getSucursaleById, enableSucursal } from "api";
import { useFormik } from "formik";
import { filterSucursales } from "backOfficeUsuariosCau/components/utils/filterSucursales";
import { sucursalesDeshabiltarCAUFiltradaArray } from "const/constantes";
import SesionExpirada from "backOfficeUsuariosCau/components/SesionExpirada/SesionExpirada";

import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert2";
import "./CancelarCau.css";
import ButtonEscape from "const/ButtonEscape";

const CancelarCAU = () => {
  const welcome = "Bienvenido !";

  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        display: "flex",
        flexWrap: "wrap",
        marginBottom: "2%",
        marginTop: "5%",
        "& > *": {
          width: "50%",
          height: theme.spacing(22),
          marginLeft: "25%",
        },
        [theme.breakpoints.down("sm")]: {
          marginLeft: "-24%",
          width: "196%",
        },
      },
      paper: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
          with: "100%",
        },
      },
    })
  );

  const classes = useStyles();

  const [sucursales, setSucursales] = useState([]);
  const [result, setResult] = useState(false);
  const [estado, setEstado] = useState("");
  const [sucId, setSucId] = useState(1);
  const [sucStatu, setSucStatu] = useState();
  const [confirmar, setConfirmar] = useState(false);
  const [deshabilitarButton, setDeshabilitarButton] = useState(false);
  const [buscarButton, setBuscarButton] = useState(true);
  const [sucursalNombre, setSucursalNombre] = useState("");
  const [habilitar, setHabilitar] = useState();

  async function sucursalFiltradaFunc() {
    const res = await getSucursales(true);
    var suc = [];
    suc = res.data;
    setSucursales(filterSucursales(sucursalesDeshabiltarCAUFiltradaArray, suc));
  }

  async function getSucursaleByIdFunc(id) {
    const res = await getSucursaleById(id);
    if (res.data.habilitada === true) {
      setEstado("Habilitada");
    } else {
      setEstado("Deshabilitada");
    }
    setSucStatu(res.data.habilitada);
  }

  async function enableSucursalFunc(id) {
    if (sucStatu === true) {
      const resultadoFuncion = await enableSucursal(id, false).then(
        (response) => {
          const res = response.data;
          return res;
        }
      );
      return resultadoFuncion;
    }
    if (sucStatu === false) {
      await enableSucursal(id, true).then((response) => {
        setResult(false);
        setHabilitar(true);
      });
    }
  }

  useEffect(() => {
    try {
      sucursalFiltradaFunc();
      getSucursaleByIdFunc(sucId);
    } catch (err) {
      console.log(err);
    }
  }, [sucId, habilitar]);

  const formik = useFormik({
    initialValues: {
      sucursalId: "",
    },
    onSubmit: (values) => {
      const { sucursalId } = values;
      setResult(true);
    },
  });

  function habilitarMess(value) {
    if (estado === "Deshabilitada" && value === true) {
      return "Habilitar";
    }
    if (estado === "Habilitada" && value === true) {
      return "Deshabilitar";
    }
    if (estado === "Deshabilitada") {
      return "habilitar";
    }
    if (estado === "Habilitada") {
      return "deshabilitar";
    }
  }

  function confirmarFunc() {
    if (estado === "Deshabilitada") {
      setEstado("Habilitada");
    } else {
      setEstado("Deshabilitada");
    }
  }

  const textoSwal = estado === "Deshabilitada" ? "Habilitar" : "Deshabilitar";
  const textoSwalConfirmar =
    estado === "Deshabilitada" ? "habilitado" : "deshabilitado";

  const confirmarAccion = () => {
    swal
      .fire({
        position: "center",
        title: "Confirmar Operación",
        text: `¿Está seguro/a que desea ${textoSwal} el Centro de Atención ${sucursalNombre}?`,
        icon: "warning",
        confirmButtonColor: "#009bdb",
        confirmButtonText: "Sí",
        showCancelButton: true,
        cancelButtonText: "No",
        cancelButtonColor: "#b7b7b7",
      })
      .then(async (resultado) => {
        if (resultado.value) {
          if (estado === "Habilitada") {
            const turnoResultado = await enableSucursalFunc(
              formik.values.sucursalId
            );
            setConfirmar(false);
            confirmarFunc();
            setDeshabilitarButton(false);
            setResult(true);
            swal.fire({
              icon: "success",
              title: "Actualización Éxitosa",
              text: ` Se ha ${textoSwalConfirmar} el CAU  ${sucursalNombre}, se notificó la cancelación de ${turnoResultado}  turnos a los usuarios correspondientes.`,
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#009bdb",
            });
          } else {
            enableSucursalFunc(formik.values.sucursalId);
            setConfirmar(false);
            confirmarFunc();
            setDeshabilitarButton(false);
            setResult(true);
            swal.fire({
              icon: "success",
              title: "Actualización Éxitosa",
              text: ` Se ha ${textoSwalConfirmar} el CAU  ${sucursalNombre}.`,
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#009bdb",
            });
          }
        } else {
          setDeshabilitarButton(false);
          setConfirmar(false);
        }
      });
  };

  const swalWarning = () => {
    return confirmarAccion();
  };

  return (
    <Container fixed>
      <div>
        <Header welcome={welcome} button={<ButtonEscape />} />
      </div>

      <div
        style={{
          marginTop: "2%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        {" "}
        <SesionExpirada timer={900000} />
        <br />
        <Grid
          style={{
            marginLeft: "2%",
          }}
          container
          className="title__header"
        >
          <Grid item xs={2}>
            <Link to="/backOfficeL" style={{ textDecoration: "none" }}>
              <Button
                startIcon={<ArrowBackIcon />}
                style={{
                  textTransform: "none",
                  backgroundColor: "white",
                  color: "#009BDB",
                }}
              >
                Volver
              </Button>
            </Link>
          </Grid>
          <Grid item xs={10}>
            <p
              style={{
                marginTop: "5px",
                fontSize: "19px",
                marginLeft: "12.5%",
              }}
            >
              Habilitar - Deshabilitar CAU
            </p>
          </Grid>
        </Grid>
        <br />
        <Grid
          container
          style={{
            width: "100%",
          }}
        >
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            item
            xs={1}
          ></Grid>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            item
            xs={10}
          >
            <form
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
              onSubmit={formik.handleSubmit}
            >
              <FormControl
                style={{
                  alignSelf: "center",
                  width: "50%",
                  marginTop: "-2%",
                }}
                className="label__actualizar-puestos"
              >
                <InputLabel>
                  <p
                    style={{
                      marginTop: "-2%",
                      fontSize: "19px",
                    }}
                  >
                    Elegí el Centro de Atención
                  </p>
                </InputLabel>
                <Select
                  id="sucursalId"
                  name="sucursalId"
                  style={{
                    marginBottom: "15px",
                    //width: "100%",
                  }}
                  onChange={formik.handleChange}
                  value={formik.values.sucursalId}
                  error={formik.errors.sucursalId}
                >
                  {sucursales &&
                    sucursales.map((sucursal) => (
                      <MenuItem
                        style={{ fontSize: "11pt", fontFamily: "Roboto" }}
                        key={`sucursal_${sucursal.id}`}
                        value={sucursal.id}
                        onClick={() => {
                          setDeshabilitarButton(false);
                          setConfirmar(false);
                          setSucId(sucursal.id);
                          setSucursalNombre(
                            sucursal.nombre +
                              " - " +
                              sucursal.localidad.nombre +
                              " - " +
                              sucursal.direccion
                          );
                          if (result === false && buscarButton === false) {
                            setResult(true);
                          }
                        }}
                      >
                        {sucursal.localidad.nombre === "GRANDES USUARIOS"
                          ? ` ${sucursal.nombre} - ${sucursal.localidad.nombre}`
                          : ` ${sucursal.nombre} - ${sucursal.localidad.nombre} - ${sucursal.direccion}`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </form>
          </Grid>

          <Grid container>
            <Grid item xs={9}></Grid>

            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
              item
              xs={3}
            >
              {buscarButton === true && (
                <Button
                  style={{
                    marginRight: "150%",
                    alignSelf: "center",
                    backgroundColor: "#0055a6",
                    color: "white",
                    width: "50%",
                    marginTop: "5%",
                    marginLeft: "-33%",
                  }}
                  onClick={() => {
                    if (formik.values.sucursalId !== "") {
                      setBuscarButton(false);
                      formik.handleSubmit();
                    } else {
                      formik.setFieldError("sucursalId", " ");
                    }
                  }}
                  variant="contained"
                  className="btn__buscar-actualizar-cau"
                >
                  Buscar
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        {result === true && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignSelf: "center",
              width: "100%",
            }}
          >
            <Grid
              container
              style={{
                width: "100%",
              }}
            >
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
                item
                xs={1}
              ></Grid>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
                item
                xs={10}
                className="grid__actualizar-puestos"
              >
                <Box className={classes.root}>
                  <Paper className={classes.paper}>
                    <p className="nombre-cau-box">{sucursalNombre}</p>

                    <span>
                      {" "}
                      <b>Estado:</b>{" "}
                      <b
                        style={{
                          color:
                            estado === "Habilitada" ? "#67B86A" : " #E20613",
                        }}
                      >
                        {estado}
                      </b>
                    </span>
                    <Button
                      disabled={deshabilitarButton}
                      style={{
                        backgroundColor:
                          estado === "Deshabilitada" ? "#67B86A" : " #E20613",
                        color: "white",
                        marginTop: "4%",
                        width: "60%",
                      }}
                      onClick={() => {
                        swalWarning();
                      }}
                      variant="contained"
                    >
                      {habilitarMess(true)}
                    </Button>
                  </Paper>
                </Box>
              </Grid>{" "}
            </Grid>

            <br />

            <br />
          </div>
        )}
        <br />
      </div>
    </Container>
  );
};
export default CancelarCAU;
