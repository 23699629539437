import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  Container,
  TextField,
  Typography,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import {
  getSucursalesByTipoCaja,
  getSucursales,
  findCajasBySucursalTipoTramite,
} from "api";
import { makeStyles } from "@material-ui/core/styles";
import {
  formatDate,
  formatHour,
} from "usuariosOnline/components/utils/formateoFechaYHora";
import { sucursalesTurnoInSitu } from "const/constantes";
import { filterSucursales } from "backOfficeUsuariosCau/components/utils/filterSucursales";
import { setTurnoValues } from "store/slices/turnoSlice";

const FormPaso3TurnoInSitu = () => {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      width: "70%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    subtitulo: {
      marginTop: theme.spacing(1),
      color: "blue",
      fontWeight: "bold",
    },
    textField: {
      marginTop: theme.spacing(6),
    },
    botonHora: {
      textTransform: "none",
      marginTop: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  const [sucursales, setSucursales] = useState([]);
  const [habilitado, setHabilitado] = useState(false);
  const [mostrarHora, setMostrarHora] = useState(false);
  const fechaActual = useRef(new Date());
  const tipoCaja = useSelector((state) => state.caja.tipo);
  const observaciones = useSelector((state) => state.caja.observaciones);
  const dniUser = useSelector((state) => state.user.dni);
  const nombreUser = useSelector((state) => state.user.nombre);
  const apellidoUser = useSelector((state) => state.user.apellido);
  const telefonoUser = useSelector((state) => state.user.telefono);
  const emailUser = useSelector((state) => state.user.email);
  const cuentaUser = useSelector((state) => state.user.cuentaContrato);
  const titularUser = useSelector((state) => state.user.titularCuenta);

  const dispatch = useDispatch();

  const validation = Yup.object({
    sucursalId: Yup.string().required("requerido"),
    hora: Yup.string().required("requerido"),
  });

  const horaFormateada = formatHour(fechaActual.current).slice(0, 5);

  const formik = useFormik({
    initialValues: {
      sucursalId: "",
      fecha: formatDate(fechaActual.current),
      hora: horaFormateada,
      en_sucursal: true,
      direccion: "",
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      const { hora, sucursalId, direccion } = values;
      const caja = await findCajasBySucursalTipoTramiteFunc(
        tipoCaja,
        sucursalId
      );
      const auxHora = hora.concat(":00");
      const auxFecha = formatDate(fechaActual.current);

      const obj = {
        hora: auxHora,
        sucursalId: sucursalId,
        fecha: auxFecha,
        tipoTurno: "ESPONTANEO",
        direccion: direccion,
        cajaId: caja,
      };

      const objTurno = {
        dni: dniUser,
        nombre: nombreUser,
        apellido: apellidoUser,
        email: emailUser,
        telefono: telefonoUser,
        cuentaContrato: cuentaUser,
        titularCuenta: titularUser,
        observaciones: observaciones,

        ...obj,
      };
      dispatch(setTurnoValues(obj));
      localStorage.setItem("objetCrearTurno", JSON.stringify(objTurno));
    },
  });

  useEffect(() => {
    async function getSucursalesFunc(caja) {
      let suc = [];
      if (tipoCaja !== "") {
        const res = await getSucursalesByTipoCaja(caja);
        suc = res.data;
      }
      if (tipoCaja === "") {
        const res = await getSucursales(true);
        suc = res.data;
      }
      setSucursales(filterSucursales(sucursalesTurnoInSitu, suc));
    }
    getSucursalesFunc(tipoCaja);
  }, [tipoCaja]);

  async function findCajasBySucursalTipoTramiteFunc(sucursalId, tipoCaja) {
    const res = await findCajasBySucursalTipoTramite(sucursalId, tipoCaja);
    return res.data.id;
  }

  const fechaformularioMostrar = formik.values.fecha
    .split("-")
    .reverse()
    .join("-");

  const handleMostrarImputHora = () => {
    setMostrarHora(true);
  };

  return (
    <Container
      fixed
      style={{ width: "70%", textAlign: "center", height: "40%" }}
    >
      <form
        onSubmit={formik.handleSubmit}
        style={{
          alignSelf: "center",
          marginTop: "2%",
          display: "inline-block",
          width: "100%",
        }}
      >
        <FormControl className={classes.formControl}>
          <InputLabel style={{ fontSize: "16pt", fontFamily: "Roboto" }}>
            Centro de Atención
          </InputLabel>
          <Select
            className={classes.textField}
            id="sucursalId"
            name="sucursalId"
            value={formik.values.sucursalId}
            onChange={formik.handleChange}
            error={formik.errors.sucursalId}
          >
            {sucursales &&
              sucursales.map((sucursal) => (
                <MenuItem key={`sucursal_${sucursal.id}`} value={sucursal.id}>
                  {` ${sucursal.nombre} - ${sucursal.localidad.nombre} - ${sucursal.direccion}`}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <Typography variant="subtitle1" className={classes.subtitulo}>
          {`USTED ESTA INGRESANDO UN TURNO EL DÍA : ${fechaformularioMostrar}`}
        </Typography>
        <div style={{ position: "relative" }}>
          <Button
            variant="contained"
            onClick={handleMostrarImputHora}
            className={classes.botonHora}
          >
            Hora
          </Button>
        </div>

        <FormControl className={classes.formControl}>
          {mostrarHora === true && (
            <TextField
              style={{
                marginTop: "5%",
                marginLeft: "30%",
                marginRight: "30%",
              }}
              id="hora"
              type="time"
              value={formik.values.hora}
              onChange={formik.handleChange}
              helperText={formik.errors.hora}
              error={formik.errors.hora}
            />
          )}
        </FormControl>

        <div style={{ marginTop: "3%", alignSelf: "center" }}>
          <Button
            type="onSubmit"
            variant="contained"
            style={{ backgroundColor: "#0055a6", color: "white" }}
            disabled={habilitado}
          >
            Confirmar Datos
          </Button>
        </div>
      </form>
    </Container>
  );
};

export default FormPaso3TurnoInSitu;
