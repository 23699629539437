import React from "react";
import { CircularProgress, Box } from "@material-ui/core";
import "./CircularLoader.css";
export default function CircularLoader() {
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress className="circular-loader" />
    </Box>
  );
}
