import React, { useEffect, useState } from "react";
import { proximoTurnoDisponibleApi } from "api";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Button,
  TableContainer,
  Box,
  LinearProgress,
  CircularProgress,
} from "@material-ui/core";
import swal from "sweetalert2";
import { Link } from "react-router-dom";
import { imprimirProximoTurnoDisponiblePdf } from "api";
import "./ProximoTurnoDisponibleTablaResultados.css";
import { useSelector } from "react-redux";
import { Pagination } from "@material-ui/lab";
import usePagination from "Hooks/usePagination";
import LoaderSpinner from "../Loader/LoaderSpinner";

const ProximoTurnoDisponibleTablaResultados = ({
  imprimir,
  setImprimir,
  setShowResults,
  showResults,
}) => {
  const sucursalIdArray = useSelector(
    (state) => state.sucursales.sucursalesArray
  );
  const tipoDeTramiteArray = useSelector(
    (state) => state.tramites.tramitesArray
  );

  const [tablaData, setTablaData] = useState([]);
  const [sucursal, setSucursal] = useState([]);
  const [tramite, setTramite] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [flag, setFlag] = useState(false);

  function swalWarningError() {
    swal.fire({
      icon: "warning",
      title: "Advertencia",
      text: ` Error al generar el reporte, intente nuevamente`,
      confirmButtonColor: "#009bdb",
    });
  }

  const proximoTurnoDisponibleFunc = async () => {
    try {
      if (sucursalIdArray.length > 0 && tipoDeTramiteArray.length > 0) {
        const res = await proximoTurnoDisponibleApi(
          sucursalIdArray,
          tipoDeTramiteArray
        );
        setTablaData(res.data);
        setIsLoading(false);
        setFlag(true);
      }
    } catch (error) {
      if (error.response.status === 504) {
        swalWarningError();
      }
    }
  };
  async function print(sucursalIdArray, tipoDeTramiteArray) {
    setImprimir(true);

    await imprimirProximoTurnoDisponiblePdf(sucursalIdArray, tipoDeTramiteArray)
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        setImprimir(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const per_Page = 10;
  //const resultadosParaPaginar = resultados?.content;
  const count = Math.ceil(tablaData?.length / per_Page);
  const resultadoPaginado = usePagination(tablaData, per_Page);

  const handleChange = (e, p) => {
    setPage(p);
    resultadoPaginado.jump(p);
  };

  const onClickPrintPdf = () => {
    print(sucursalIdArray, tipoDeTramiteArray);
  };

  useEffect(() => {
    setSucursal(sucursalIdArray);
    setTramite(tipoDeTramiteArray);
  }, [sucursalIdArray, tipoDeTramiteArray]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // setIsLoading(true);
    proximoTurnoDisponibleFunc();
  }, []);
  useEffect(() => {
    if (flag === true) {
      setShowResults(false);
    }
  }, [sucursalIdArray, tipoDeTramiteArray]);

  return (
    <>
      {isLoading ? (
        <LoaderSpinner />
      ) : (
        <div style={{ width: "100%", marginLeft: "1%", marginBottom: "3%" }}>
          <TableContainer sx={{ maxHeight: "400px" }} component={Paper}>
            <Table stickyHeader aria-label="simple table">
              <TableHead variant="head">
                <TableRow>
                  <TableCell align="center">Sucursal</TableCell>
                  <TableCell align="center"> Tipo de Trámite</TableCell>
                  <TableCell align="center">
                    Total de Turnos Agendados
                  </TableCell>
                  <TableCell align="center">Próximo Turno Disponible</TableCell>
                  <TableCell align="center">
                    ¿Cuántos días faltan para el próximo turno?
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tablaData &&
                  resultadoPaginado.currentData().map((tabla) =>
                    tabla.cajas.map((item) => (
                      <TableRow
                        key={item.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{`${tabla.nombre} - ${tabla.localidad}`}</TableCell>
                        <TableCell align="center">{item.nombre}</TableCell>
                        <TableCell align="center">
                          {item.turno.turnosTomados}
                        </TableCell>
                        <TableCell align="center">
                          {`${item.turno.fecha
                            .split("-")
                            .reverse()
                            .join("/")} -- ${item.turno.hora.slice(0, -3)} Hs`}
                        </TableCell>
                        <TableCell align="center">
                          {item.turno.diasHastaTurno}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
              marginTop: "1%",
            }}
          >
            {tablaData?.length > 50 && (
              <Box component="span">
                <Pagination
                  count={count}
                  page={page}
                  onChange={handleChange}
                  defaultPage={1}
                  color="primary"
                  showFirstButton
                  showLastButton
                />
              </Box>
            )}
          </div>

          <Grid
            container
            xs
            display="flex"
            justify="center"
            style={{ marginTop: "1%", marginBottom: "3%" }}
            className="botonera"
          >
            {imprimir === false ? (
              <>
                <Grid item xs={6} container display="flex" justify="center">
                  <Link
                    to="/backOfficeL"
                    style={{ textDecoration: "none", textTransform: "none" }}
                  >
                    <Button
                      variant="outlined"
                      style={{
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                        border: "1px solid",
                      }}
                      className="btn__cancelar"
                    >
                      cancelar
                    </Button>
                  </Link>
                </Grid>

                <Grid item xs={6} container display="flex" justify="center">
                  <Button
                    style={{
                      fontWeight: "bold",
                      fontFamily: "Roboto",
                      backgroundColor: "#009BDB",
                      color: "white",
                    }}
                    variant="contained"
                    onClick={onClickPrintPdf}
                    className="btn__imprimir"
                  >
                    Imprimir
                  </Button>
                </Grid>
              </>
            ) : (
              <CircularProgress style={{ marginLeft: "50%" }} />
            )}
          </Grid>
        </div>
      )}
    </>
  );
};

export default ProximoTurnoDisponibleTablaResultados;
