import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextareaAutosize,
  Box,
  Paper,
} from "@material-ui/core";
import { getTipoCaja } from "api";
import { setCajaValues } from "store/slices/cajaSlice";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import "./selectTipoCaja.css";

const SelectTipoCaja = ({ width }) => {
  const [tipo, setTipo] = useState("");
  const [tipoDecajas, setTipoDecajas] = useState([]);
  const [observaciones, setObservaciones] = useState("");
  const dispatch = useDispatch();

  const useStyles = makeStyles((theme) => ({
    formControl: {
      marginTop: "3%",
      alignSelf: "center",
      width: "30%",
      [theme.breakpoints.down("sm")]: {
        width: "70%",
      },
    },
  }));
  const classes = useStyles();

  const handleChange = (event) => {
    setTipo(event.target.value);
    const obs = {
      tipo: event.target.value,
      observaciones: "",
    };
    dispatch(setCajaValues(obs));
  };

  const handleChangeConObservaciones = (event) => {
    setObservaciones(event.target.value);
    const obs = {
      tipo: tipo,
      observaciones: observaciones,
    };
    dispatch(setCajaValues(obs));
  };

  async function getTipoCajaFunc() {
    const res = await getTipoCaja(true);
    setTipoDecajas(res.data);
  }

  useEffect(() => {
    try {
      getTipoCajaFunc();
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <FormControl className={classes.formControl}>
        <InputLabel>Tipo de Trámite</InputLabel>
        <Select value={tipo} onChange={handleChange} style={{ minWidth: 150 }}>
          {tipoDecajas &&
            tipoDecajas.map((type) => (
              <MenuItem
                key={`tipo_${type.id}`}
                value={type.id}
                style={{ whiteSpace: "normal" }}
              >
                {type.nombre}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl
        style={{ marginTop: "3%", alignSelf: "center", width: "50%" }}
      >
        {tipo === 5 && (
          <>
            <TextareaAutosize
              style={{ minWidth: 150, height: 45 }}
              placeholder="Agregar comentarios"
              type="text"
              id="observaciones"
              value={observaciones}
              onChange={handleChangeConObservaciones}
              maxRows={5}
              aria-label="maximum height"
              maxLength={240}
            />
            <Paper elevation={3} />
          </>
        )}
      </FormControl>
      {tipo === 3 && (
        <div
          style={{ marginTop: "-2%", alignSelf: "center", width: "53%" }}
          className="div-container_paper-box"
        >
          <Box>
            <Paper
              elevation={3}
              style={{
                border: "2px solid #F86436",
                background: "rgb(248, 100, 54, 0.2)",
              }}
            >
              <div
                style={{ margin: "1%", display: "flex" }}
                className="div_paper-box"
              >
                <InfoIcon
                  style={{ color: "#F86436", height: "4rem", width: "3rem" }}
                />
                <p
                  style={{
                    fontWeight: "500",
                    textAlign: "start",
                    marginLeft: "2%",
                  }}
                  className="text_paper-box"
                >
                  A partir del 1 de noviembre el Archivo de planos no atenderá
                  más en forma presencial.
                  <br />
                  Se podrá hacer únicamente desde Oficina Virtual.
                </p>
              </div>
            </Paper>
          </Box>
          <div>
            <p style={{ fontSize: "11px" }}>
              *Recordá que sólo podrás hacer la solicitud de aquellos inmuebles
              pertenecientes a la Ciudad Autónoma de Buenos Aires
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
export default SelectTipoCaja;
