import React from "react";
import { Button, Grid, Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Header from "usuariosOnline/components/Header/Header";
import SingleLogInForm from "backOfficeUsuariosCau/components/SingleLogInForm/SingleLogInForm";
import AddIcon from "@material-ui/icons/Add";

export const actualUrl = window.location.href;

const BackOffice = (props) => {
  const Style = {
    turno: {
      color: "#b7b7b7",
    },
    h1: {
      color: "white",
      backgroundColor: "dodgerblue",
      padding: "10px",
    },
  };
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
    },
    form: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
  }));

  const classes = useStyles();
  const title = null;
  const welcome = null;
  const button = null;
  const link = "/backOfficeL";

  return (
    <div>
      <Container fixed>
        <div>
          <Header
            title={title}
            welcome={welcome}
            button={button}
            mainPage={true}
          />
        </div>

        <Grid container spacing={6}>
          <Grid item xs style={{ marginTop: "5%", marginLeft: "3%" }}>
            <div className={classes.root}>
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
                spacing={1}
              >
                <Grid item xs style={{ alignSelf: "center" }}>
                  <Typography
                    variant="h1"
                    style={{
                      fontSize: "17pt",
                      fontFamily: "Roboto",
                      textTransform: "none",
                    }}
                  >
                    Turnos
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} style={{ marginTop: "1%" }}>
                <Grid
                  item
                  xs
                  style={{
                    justifyContent: "centre",
                    flexDirection: "column",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Link
                    to="/"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      width: "60%",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      startIcon={<AddIcon style={{ color: "white" }} />}
                      style={{
                        backgroundColor: "#5788DE",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="p"
                        style={{
                          fontSize: "11pt",
                          fontFamily: "Roboto",
                          textTransform: "none",
                          color: "white",
                        }}
                      >
                        Nuevo Turno
                      </Typography>
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs style={{ marginTop: "5%" }}>
            <SingleLogInForm link={link} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default BackOffice;
