import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Zoom from "@material-ui/core/Zoom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Divider from "@material-ui/core/Divider";
import { FormControl, TextField, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useDispatch } from "react-redux";
import { setUserValues } from "store/slices/userSlice";
import $ from "jquery";
import service from "images/service.png";
import { Typography } from "@material-ui/core";
import { getData } from "api";

const Style = {
  a: {
    fontFamily: "Roboto",
    textTransform: "none",
    color: "red",
    fontSize: "10pt",
    fontWeight: "bold",
  },
  c: {
    fontFamily: "Roboto",
    textTransform: "none",
    color: "#0055a6",
    fontSize: "10pt",
    fontWeight: "bold",
  },
  button: {
    color: "white",
    fontFamily: "Roboto",
    background: "#0055a6",
    "&:hover": {
      background: "#0055a6",
    },
  },
};

Yup.addMethod(Yup.string, "codareaValidator", function () {
  return this.test({
    name: "codArea",
    message: "Ingrese código de área sin el 0",
    test: (value) => {
      return value.charAt(0) !== 0;
    },
  });
});
const validation = Yup.object().shape({
  dni: Yup.string("Ingrese dni")
    .min(7, "Debe contener 7 caracteres o más")
    .max(9, "Debe contener 9 caracteres o menos")

    .required("requerido"),
  nombre: Yup.string()
    .max(20, "Debe contener 20 caracteres o menos")

    .required("requerido"),
  apellido: Yup.string()
    .max(20, "Debe contener 20 caracteres o menos")

    .required("requerido"),
  email: Yup.string().email("Coloque un email válido").required("requerido"),
  confirmarEmail: Yup.string()
    .oneOf([Yup.ref("email"), null], "Debe coincidir con el email ingresado")
    .email("Coloque un email válido")

    .required("requerido"),
  codArea: Yup.string()
    .min(2, "Debe contener 2 digitos minimo")
    .max(4, "Debe contener 4 digitos maximo")
    .required("requerido"),

  telefono: Yup.string()
    .when("codArea", {
      is: (value) => value && value.length === 1,
      then: Yup.string().required(
        "Código de área debe contener 2 caracteres o más"
      ),
    })
    .when("codArea", {
      is: (value) => value && value.length === 2,
      then: Yup.string()
        .required("Debe tener 8 dígitos numéricos")
        .min(8, "Debe tener 8 dígitos numéricos")
        .max(8, "Debe tener 8 dígitos numéricos"),
    })
    .when("codArea", {
      is: (value) => value && value.length === 3,
      then: Yup.string()
        .required("Debe tener 7 dígitos numéricos")
        .min(7, "Debe tener 7 dígitos numéricos")
        .max(7, "Debe tener 7 dígitos numéricos"),
    })
    .when("codArea", {
      is: (value) => value && value.length === 4,
      then: Yup.string()
        .required("Debe tener 6 dígitos numéricos")
        .min(6, "Debe tener 6 dígitos numéricos")
        .max(6, "Debe tener 6 dígitos numéricos"),
    })
    .when("codArea", {
      is: (value) => value && value.length > 4,
      then: Yup.string().required("Código de área supera el máximo permitido"),
    })

    .required("requerido"),
  celular: Yup.boolean().required("requerido"),
  codareafalse: Yup.boolean().required(),
  cuentaContrato: Yup.string()
    .max(12, "Debe contener 12 caracteres o menos")

    .required("requerido"),

  esTitular: Yup.boolean().required("requerido"),

  titularCuenta: Yup.string()
    .max(40, "Debe contener 40 caracteres o menos")
    .when("esTitular", {
      is: (value) => value === false,
      then: Yup.string().required("requerido"),
    }),
});

const FormContacto = ({ width }) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const nombreUsuarioApp = (query.get("name") || "").split("_").join(" ");
  const apellidoUsuarioApp = (query.get("lastName") || "").split("_").join(" ");
  const emailUsuarioApp = query.get("email");
  const dniUsuarioApp = query.get("dni");
  const confirmarEmailUsuarioApp = query.get("confirmMail");
  const codAreaUsuarioApp = query.get("codePhone");
  const telefonoUsuarioApp = query.get("phone");
  const esCelularUsuarioApp = query.get("cellPhone");
  const cuentaContratoUsuarioApp = query.get("accountNumber");
  const esTitularUsuarioApp = query.get("accountHolder");
  const titularCuentaUsuarioNameApp = (query.get("accountHolderName") || "")
    .split("_")
    .join(" ");
  const usuarioApp = query.get("userApp");
  const ipUsuarioApp = query.get("ip_address");
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [habilitado, setHabilitado] = useState(false);
  const formik = useFormik({
    initialValues: {
      dni: dniUsuarioApp || "",
      nombre: nombreUsuarioApp,
      apellido: apellidoUsuarioApp,
      email: emailUsuarioApp || "",
      confirmarEmail: confirmarEmailUsuarioApp || "",
      codArea: codAreaUsuarioApp || "",
      telefono: telefonoUsuarioApp || "",
      cuentaContrato: cuentaContratoUsuarioApp || "",
      titularCuenta: titularCuentaUsuarioNameApp || "",
      esTitular: esTitularUsuarioApp || "",
      codareafalse: "",
      celular: esCelularUsuarioApp || "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      dispatch(setUserValues(values));
      deshabilitar();
      errorcodareafunc();
    },
  });

  const disablePaste = (id) =>
    $(id).bind("paste", function () {
      return false;
    });

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const deshabilitar = () => {
    setHabilitado(!habilitado);
  };

  useEffect(() => {
    try {
      setWidthh(width > 1023 ? "55%" : "60%");
      widthCelular(width);
      setMarginLeft(width > 1023 ? "1%" : "3%");
      setMarginLeft2(width > 1023 ? "2%" : "6%");
      setGridxl1(width > 1023 ? 4 : 4);
      setGridxl2(width > 1023 ? 8 : 8);
      disablePaste("#confirmarEmail");
    } catch (err) {
      console.loo(err);
    }
  }, [width]);

  useEffect(() => {
    errorcodareafunc();
  });

  function widthCelular(w) {
    if (w > 1023) {
      setWidthc("58%");
    }
    if (w > 600 && w < 1023) {
      setWidthc("35%");
    }
    if (w > 350 && w < 600) {
      setWidthc("65%");
    }
  }
  const [errorRadio] = React.useState(false);
  const [widthh, setWidthh] = useState("");
  const [widthc, setWidthc] = useState("");
  const [marginLeft, setMarginLeft] = useState("");
  const [marginLeft2, setMarginLeft2] = useState("");
  const [gridxl1, setGridxl1] = useState();
  const [gridxl2, setGridxl2] = useState();
  const [errorCodarea, setErrorCodarea] = React.useState(false);
  const [ipCapturadaWeb, setIpCapturadaWeb] = useState("");

  function ppp(id) {
    if (id === "celular") {
      return widthc;
    }
    if (id === "titular") {
      return widthh;
    }
  }
  const ipCapturadaApp = query.get("ip_address");

  async function capturaIP() {
    if (!usuarioApp) {
      const res = await getData();
      setIpCapturadaWeb(res);
    }
  }
  const ipCapturada = ipCapturadaApp || ipCapturadaWeb;

  localStorage.setItem("ip", ipCapturada);

  useEffect(() => {
    capturaIP();
  }, []);

  function errorcodareafunc() {
    if (formik.values.codArea.charAt(0) === 0 && formik.values.codArea !== "") {
      setErrorCodarea(true);
    }
    if (formik.values.codArea.charAt(0) !== 0) {
      setErrorCodarea(false);
      formik.values.codareafalse = "false";
    }
  }

  function errorText(id) {
    return (
      <div>
        <Divider style={{ width: "60%", backgroundColor: "red" }} />
        <p
          style={{
            marginTop: "0px",
            fontSize: "10pt",
            color: "red",
          }}
        >
          requerido
        </p>
      </div>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormControl style={{ width: "90%", paddingLeft: "10%" }}>
        <Grid container>
          <Grid item xs>
            <TextField
              placeholder="Número"
              label="N° de Documento"
              id="dni"
              name="dni"
              type="number"
              disabled={habilitado}
              onChange={formik.handleChange}
              value={formik.values.dni}
              helperText={
                formik.errors.dni || (
                  <Typography component={"span"} style={Style.c}>
                    *Campo requerido
                  </Typography>
                )
              }
              error={formik.errors.dni}
            />
          </Grid>
          <Grid item xs>
            <TextField
              placeholder="Nombre/s"
              label="Nombre/s"
              id="nombre"
              name="nombre"
              disabled={habilitado}
              onChange={formik.handleChange}
              value={formik.values.nombre}
              helperText={
                formik.errors.nombre || (
                  <Typography component={"span"} style={Style.c}>
                    *Campo requerido
                  </Typography>
                )
              }
              error={formik.errors.nombre}
            />
          </Grid>
          <Grid item xs>
            <TextField
              placeholder="Apellido/s"
              label="Apellido/s"
              id="apellido"
              name="apellido"
              disabled={habilitado}
              onChange={formik.handleChange}
              value={formik.values.apellido}
              helperText={
                formik.errors.apellido || (
                  <Typography component={"span"} style={Style.c}>
                    *Campo requerido
                  </Typography>
                )
              }
              error={formik.errors.apellido}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={gridxl1}>
            <TextField
              placeholder="E-mail"
              label="E-mail"
              id="email"
              name="email"
              disabled={habilitado}
              onChange={formik.handleChange}
              value={formik.values.email}
              helperText={
                formik.errors.email || (
                  <Typography component={"span"} style={Style.c}>
                    *Campo requerido
                  </Typography>
                )
              }
              error={formik.errors.email}
            />
          </Grid>
          <Grid item xs={gridxl2} style={{ minWidth: "350px" }}>
            <TextField
              placeholder="Confirmar e-mail"
              label="Confirmar e-mail"
              id="confirmarEmail"
              name="confirmarEmail"
              disabled={habilitado}
              onChange={formik.handleChange}
              value={formik.values.confirmarEmail}
              helperText={
                formik.errors.confirmarEmail || (
                  <Typography component={"span"} style={Style.c}>
                    *Campo requerido
                  </Typography>
                )
              }
              error={formik.errors.confirmarEmail}
            />
          </Grid>
          <Grid item xs></Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <TextField
              type="codArea"
              placeholder="Ej: 11"
              label="Código de área"
              id="codArea"
              name="codArea"
              disabled={habilitado}
              onChange={formik.handleChange}
              value={formik.values.codArea}
              helperText={
                formik.errors.codArea || (
                  <Typography
                    component={"span"}
                    style={errorCodarea === true ? Style.a : Style.c}
                  >
                    *Ingrese código de área sin el 0
                  </Typography>
                )
              }
              error={formik.errors.codArea}
            />
          </Grid>
          <Grid item xs>
            <TextField
              type="number"
              placeholder="Ej: 12345678"
              label="Teléfono"
              id="telefono"
              name="telefono"
              disabled={habilitado}
              onChange={formik.handleChange}
              value={formik.values.telefono}
              helperText={
                formik.errors.telefono || (
                  <Typography component={"span"} style={Style.c}>
                    *Ingrese el número sin el 15
                  </Typography>
                )
              }
              error={formik.errors.telefono}
            />
          </Grid>
          <Grid item xs>
            <div
              style={{
                marginTop: "5%",
                marginLeft: "2%",
                minWidth: "300px",
              }}
            >
              <div style={{ marginBottom: "-1%" }}>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "12pt",
                    fontFamily: "Roboto",
                    display: "inline-block",
                  }}
                >
                  Celular
                </p>
                <input
                  style={{ marginLeft: "5%" }}
                  type="radio"
                  name="celular"
                  disabled={habilitado}
                  value="true"
                  checked={formik.values.celular === "true"}
                  onChange={formik.handleChange}
                />
                <p
                  style={{
                    fontSize: "13pt",
                    display: "inline-block",
                    fontFamily: "Roboto",
                  }}
                >
                  Sí
                </p>
                <input
                  style={{ marginLeft: "3%" }}
                  type="radio"
                  name="celular"
                  disabled={habilitado}
                  value="false"
                  checked={formik.values.celular === "false"}
                  onChange={formik.handleChange}
                />
                <p
                  style={{
                    fontSize: "13pt",
                    display: "inline-block",
                    fontFamily: "Roboto",
                  }}
                >
                  No
                </p>
              </div>
              <div>
                {formik.values.celular === "" &&
                  formik.errors.celular &&
                  errorText("celular")}
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <TextField
              placeholder="0123456789"
              label="Cuenta de Servicios"
              id="cuentaContrato"
              name="cuentaContrato"
              disabled={habilitado}
              onChange={formik.handleChange}
              value={formik.values.cuentaContrato}
              helperText={formik.errors.cuentaContrato}
              error={formik.errors.cuentaContrato}
            />
            <div>
              <Button
                onClick={handleToggle}
                style={{ height: "3px", marginTop: "0%", marginBottom: "2%" }}
              >
                <p
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                    textTransform: "none",
                    color: "#009bdb",
                    fontSize: "10pt",
                  }}
                >
                  ¿Dónde lo encuentro?
                </p>
              </Button>
              <div>
                <Zoom in={toggle}>
                  <div>
                    {toggle && (
                      <img
                        id="service"
                        style={{ width: "75%", height: "50%" }}
                        src={service}
                        alt="mapa"
                      ></img>
                    )}
                  </div>
                </Zoom>
              </div>
            </div>
          </Grid>
          <Grid item xs={8}>
            <Grid container>
              <Grid item xs={6}>
                {" "}
                <div>
                  <p
                    style={{
                      minWidth: "300px",
                      fontWeight: "bold",
                      fontSize: "11pt",
                      fontFamily: "Roboto",
                      marginLeft: marginLeft2,
                      marginBottom: "-2%",
                    }}
                  >
                    Titular de la cuenta
                  </p>

                  <div style={{ marginLeft: marginLeft }}>
                    <input
                      type="radio"
                      name="esTitular"
                      disabled={habilitado}
                      value={true}
                      onChange={formik.handleChange}
                      checked={formik.values.esTitular === "true"}
                    />
                    <p
                      style={{
                        fontSize: "12pt",
                        display: "inline-block",
                        fontFamily: "Roboto",
                      }}
                    >
                      Sí
                    </p>
                    <input
                      style={{ marginLeft: "3%" }}
                      type="radio"
                      name="esTitular"
                      disabled={habilitado}
                      value={false}
                      onChange={formik.handleChange}
                      checked={formik.values.esTitular === "false"}
                    />
                    <p
                      style={{
                        fontSize: "12pt",
                        display: "inline-block",
                        fontFamily: "Roboto",
                      }}
                    >
                      No
                    </p>
                  </div>
                  <div
                    style={{
                      marginLeft: marginLeft2,
                      minWidth: "370px",
                      marginTop: "-2%",
                    }}
                  >
                    {errorRadio === false &&
                      formik.errors.esTitular &&
                      errorText("titular")}
                  </div>
                </div>
              </Grid>
              <Grid item xs>
                {formik.values.esTitular === "false" && (
                  <TextField
                    placeholder="Titular de la Cuenta"
                    label="Titular de la Cuenta"
                    name="titularCuenta"
                    disabled={habilitado}
                    onChange={formik.handleChange}
                    value={formik.values.titularCuenta}
                    helperText={
                      formik.errors.titularCuenta || (
                        <Typography component={"span"} style={Style.c}>
                          *Ingrese el nombre del titular
                        </Typography>
                      )
                    }
                    error={formik.errors.titularCuenta}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <div
          style={{
            marginTop: "1%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              marginTop: "2%",
              alignSelf: "center",
              marginRight: "12%",
            }}
          >
            <Button
              disabled={habilitado}
              type="submit"
              variant="contained"
              style={Style.button}
              onClick={() => errorcodareafunc()}
            >
              Confirmar Datos
            </Button>
          </div>
        </div>
      </FormControl>
    </form>
  );
};

export default FormContacto;
