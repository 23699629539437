import React from "react";
import { arrayMinutosTotales } from "./utils";
import { MenuItem, Select, Typography, Grid, Button } from "@material-ui/core";

const GuardarBloqueo = ({
  setHoraDesde,
  setHoraHasta,
  horaDesde,
  horaHasta,
  setGuardarBloqueo,
  params,
  setTurnosAfectadosTabla,
  turnosAfectadosFunc,
  duracion,
}) => {
  const { fecha, sucursalId, tipoCaja } = params;

  const arrayDuracionParceadoPorTiempo = (value) => {
    const nuevoArrayDuracion = arrayMinutosTotales.filter((minuto, index) => {
      if (index % value === 0) return minuto;
    });
    return nuevoArrayDuracion;
  };

  const arrayDuracionDesde = arrayDuracionParceadoPorTiempo(duracion)?.filter(
    (item) => item !== "16:00"
  );
  const arrayDuracionHasta = arrayDuracionParceadoPorTiempo(duracion)?.filter(
    (item) => item >= horaDesde
  );
  return (
    <div className="horas">
      <div className="contenedor__horas">
        <Grid container className="contenedor__horas-hijo">
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    fontWeight: 700,
                  }}
                >
                  Hora desde
                </Typography>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
                item
                xs={6}
              >
                <Select
                  name="horaDesde"
                  placeholder="Hora Desde"
                  value={horaDesde}
                  style={{ width: "65%", border: "1px solid grey" }}
                  id="horaDesde"
                  onChange={(event) => {
                    setHoraDesde(event.target.value);
                  }}
                >
                  {arrayDuracionDesde &&
                    arrayDuracionDesde.map((value, index) => (
                      <MenuItem value={value} key={index} placeholder="09:00">
                        {value}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    fontWeight: 700,
                  }}
                >
                  Hora hasta
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Select
                  name="horaHasta"
                  placeholder="09:00"
                  value={horaHasta}
                  style={{ width: "65%", border: "1px solid grey" }}
                  id="horaHasta"
                  onChange={(event) => {
                    setHoraHasta(event.target.value);
                  }}
                >
                  {arrayDuracionHasta &&
                    arrayDuracionHasta.map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <br />
      <br />

      <Button
        style={{
          textTransform: "none",
          marginLeft: "38%",
          width: "8%",
          backgroundColor: "#0055a6",
          color: "white",
        }}
        onClick={() => {
          turnosAfectadosFunc(
            fecha,
            fecha,
            horaHasta,
            horaDesde,
            sucursalId,
            tipoCaja
          );

          setGuardarBloqueo(false);
          setTurnosAfectadosTabla(true);
        }}
        variant="contained"
        disabled={horaHasta === "09:00" ? true : false}
      >
        Buscar
      </Button>
    </div>
  );
};

export default GuardarBloqueo;
