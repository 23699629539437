import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const SesionExpirada = ({ timer }) => {
  const history = useHistory();
  const [loggeado, setLoggeado] = useState(true);
  const checkDeInactividad = () => {
    const tiempoExpirado = localStorage.getItem("tiempoExpirado");
    const tiempoModal = tiempoExpirado - 60000;
    if (tiempoModal < Date.now()) {
      modalSesionPorVencer();
    }
  };

  const actualizarTiempoExpirado = () => {
    const tiempoExpirado = Date.now() + timer;
    localStorage.setItem("tiempoExpirado", tiempoExpirado);
  };

  const modalSesionPorVencer = () => {
    let timerInterval;
    Swal.fire({
      title: `Su sesión está por expirar`,
      html: `Hace varios minutos que se encuentra inactiva. Por cuestiones de seguridad su sesión se cerrará en <b></b> segundos.`,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "Cerrar Sesión",
      confirmButtonText: "Seguir Conectado",
      allowOutsideClick: false,
      timer: 1000 * 60,
      timerProgressBar: true,
      onOpen: () => {
        const b = Swal.getHtmlContainer().querySelector("b");
        timerInterval = setInterval(() => {
          b.textContent = Math.floor(Swal.getTimerLeft() / 1000);
        }, 100);
      },
      onClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.isConfirmed) {
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        history.push("/backOffice");
        setLoggeado(false);
        sessionStorage.clear();
        localStorage.clear();
      } else if (result.dismiss === Swal.DismissReason.timer) {
        Swal.fire({
          title: "Cerramos tu sesión.",
          html: "Por cuestiones de seguridad dimos por finalizada su sesión",
          confirmButtonText: "Ingresá de Nuevo",
        }).then((result) => {
          history.push("/backOffice");
          setLoggeado(false);
          sessionStorage.clear();
          localStorage.clear();
        });
      }
    });
  };
  useEffect(() => {
    actualizarTiempoExpirado();
    window.addEventListener("resize", actualizarTiempoExpirado);
    window.addEventListener("click", actualizarTiempoExpirado);
    window.addEventListener("keypress", actualizarTiempoExpirado);
    window.addEventListener("scroll", actualizarTiempoExpirado);
    window.addEventListener("mousemove", actualizarTiempoExpirado);

    return () => {
      window.addEventListener("resize", actualizarTiempoExpirado);
      window.addEventListener("click", actualizarTiempoExpirado);
      window.addEventListener("keypress", actualizarTiempoExpirado);
      window.addEventListener("scroll", actualizarTiempoExpirado);
      window.addEventListener("mousemove", actualizarTiempoExpirado);
    };
  }, []);
  useEffect(() => {
    let timerReload = timer === 900000 ? 180000 : 300000;
    const interval = setInterval(() => {
      checkDeInactividad();
    }, timerReload);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <></>;
};

export default SesionExpirada;
