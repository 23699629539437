import React, { useState, useEffect } from "react";
import Header from "usuariosOnline/components/Header/Header";
import { Grid, Container, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ProximoTurnoDisponibleTablaResultados from "backOfficeUsuariosCau/components/ProximoTurnoDisponibleTablaResultados/ProximoTurnoDisponibleTablaResultados";
import MultipleSelectProximoTurnoSucursales from "backOfficeUsuariosCau/components/MultiSelect/MultiselectProximoTurnoSucursales";
import MultipleSelectCaja from "../MultiSelect/MultipleSelectCaja.js";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "./ProximoTurnoDisponible.css";
import SesionExpirada from "backOfficeUsuariosCau/components/SesionExpirada/SesionExpirada";
import ButtonEscape from "const/ButtonEscape";

const ProximoTurnoDisponible = () => {
  const welcome = "Bienvenido !";

  const [verTablaResultados, setVerTablaResultados] = useState(false);
  const [verBotonBuscar, setVerBotonBuscar] = useState(true);
  const [habilitado, setHabilitado] = useState(false);
  const [errorSucursales, setErrorSucursales] = useState("");
  const [errorTipoTramite, setErrorTipoTramite] = useState("");
  const [changeSucursal, setChangeSucursal] = useState([]);
  const [changeTramite, setChangeTramite] = useState([]);

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      width: "60%",
      marginLeft: "16%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  }));
  const classes = useStyles();

  const onClick = () => {
    if (changeSucursal === [] || changeTramite === []) return;
    else if (changeSucursal.length > 0 && changeTramite.length > 0) {
      setVerBotonBuscar(false);
      setVerTablaResultados(true);
    }
  };

  const handleChangeSucursales = (value) => {
    setErrorSucursales("");
    setChangeSucursal(value);
  };

  const handleChangeTramites = (value) => {
    setErrorTipoTramite("");
    setChangeTramite(value);
  };

  return (
    <Container fixed style={{ minHeight: "700px" }}>
      <div>
        <Header welcome={welcome} button={<ButtonEscape />} />
      </div>
      <SesionExpirada timer={900000} />
      <Grid
        container
        alignItems="center"
        spacing={1}
        style={{ marginTop: "5%" }}
        display="flex"
      >
        <Grid
          item
          md={2}
          xs={4}
          container
          display="flex"
          justify="center"
          style={{ marginTop: "-4%", marginLeft: "-3%" }}
          className="grid__volver"
        >
          <Link to="/backOfficeL" style={{ textDecoration: "none" }}>
            <Button
              startIcon={<ArrowBackIcon />}
              style={{
                textTransform: "none",
                backgroundColor: "white",
                color: "#009BDB",
              }}
            >
              Volver
            </Button>
          </Link>
        </Grid>
        <Grid item xs={10} className="grid__title-proximo-turno-disponible">
          <p
            style={{
              marginTop: "-2%",
              fontSize: "19px",
              marginLeft: "12.5%",
              fontWeight: "600",
            }}
          >
            Reporte Indicadores Especiales
          </p>
        </Grid>

        <br />
        <Grid
          item
          xs={10}
          container
          display="flex"
          justify="center"
          direction="column"
          alignItems="center"
        >
          <form
            style={{ width: "100%", marginLeft: "13%" }}
            className="grid__form"
          >
            <div className={classes.formControl}>
              <MultipleSelectCaja
                habilitado={habilitado}
                title={"Elegí el Tipo de Trámite (obligatorio)"}
                error={errorTipoTramite}
                onChange={(value) => handleChangeTramites(value)}
              />
            </div>

            <div className={classes.formControl}>
              <MultipleSelectProximoTurnoSucursales
                habilitado={habilitado}
                title={"Elegí el Centro de Atención (obligatorio)"}
                error={errorSucursales}
                onChange={(value) => handleChangeSucursales(value)}
              />
            </div>
          </form>
        </Grid>
        <Grid
          item
          md={2}
          xs={4}
          container
          display="flex"
          justify="center"
          style={{ marginLeft: "-21.5%", marginBottom: "-20%" }}
          className="grid__btn-buscar-proximo-turno-disponible"
        >
          {verBotonBuscar && (
            <Button
              onClick={onClick}
              type="submit"
              variant="contained"
              style={{ backgroundColor: "#0055a6", color: "white" }}
            >
              Buscar
            </Button>
          )}
        </Grid>
      </Grid>

      <div
        style={{
          marginBottom: "80px",
        }}
      >
        <Container
          fixed
          style={{
            marginTop: "80px",
          }}
        >
          {verTablaResultados === true && (
            <ProximoTurnoDisponibleTablaResultados />
          )}
        </Container>
      </div>
    </Container>
  );
};

export default ProximoTurnoDisponible;
