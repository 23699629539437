import React, { useEffect, useState, useRef, useMemo } from "react";
import { useFormik } from "formik";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  Zoom,
  Grid,
  Typography,
  CircularProgress,
  Box,
  Paper,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { setMinutes, addDays, formatISO, format } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import { registerLocale } from "react-datepicker";
import DatePickerComponent from "usuariosOnline/components/DataPicker/DatePickerComponent";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import {
  getDiasFull,
  getSucursalesByTipoCaja,
  getFeriados,
  getTurnosDisponibles,
  createTurno,
  getRecaptcha,
  getSucursaleById,
} from "api";
import { setTurnoValues } from "store/slices/turnoSlice";
import { setTurnoConfirmado } from "store/slices/turnoConfirmadoSlice";
import SucursalMaps from "usuariosOnline/components/SucursalMaps/SucursalMaps";
import ReCAPTCHA from "react-google-recaptcha";
import "./formTurno.css";
import {
  formatDate,
  formatHour,
} from "usuariosOnline/components/utils/formateoFechaYHora";
import { isWeekday } from "usuariosOnline/components/utils/esDiaHabil";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router";
import "./formTurno.css";
import InfoIcon from "@material-ui/icons/Info";

registerLocale("es", es);

const FormTurnos = ({ width }) => {
  const [sucursales, setSucursales] = useState([]);
  const [feriados, setFeriados] = useState([]);
  const [habilitado, setHabilitado] = useState(false);
  const [turnos, setTurnos] = useState(null);
  const [cambiomess, setCambiomess] = useState(false);
  const [calendario, setCalendario] = useState(false);
  const [elegirFecha, setElegirFecha] = useState(false);
  const [hora, setHora] = useState(false);
  const [diasFull, setDiasFull] = useState(null);
  const [setError] = useState("");
  const fechaActual = useRef(new Date());
  const [fechaMinimaCalendario, setFechaMinimaCalendario] = useState(null);
  const [mostrarHora, setMostrarHora] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [disable, setDisable] = useState(false);

  //values from store
  const tipoCaja = useSelector((state) => state.caja.tipo);
  const observaciones = useSelector((state) => state.caja.observaciones);
  const dniUser = useSelector((state) => state.user.dni);
  const nombreUser = useSelector((state) => state.user.nombre);
  const apellidoUser = useSelector((state) => state.user.apellido);
  const telefonoUser = useSelector((state) => state.user.telefono);
  const emailUser = useSelector((state) => state.user.email);
  const cuentaUser = useSelector((state) => state.user.cuentaContrato);
  const titularUser = useSelector((state) => state.user.titularCuenta);

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      sucursalId: "",
      direccion: "",
      fecha: fechaActual.current,
      hora: "",
      en_sucursal: false,
    },
    onSubmit: (values) => {
      const { hora, sucursalId, direccion, fecha } = values;
      const auxHora = hora.split("_")[0];
      const auxCaja = hora.split("_")[1];
      const fechaAux = formatISO(new Date(`${fecha}`), {
        representation: "date",
      });

      const obj = {
        hora: auxHora,
        cajaId: auxCaja,
        sucursalId: sucursalId,
        direccion: direccion,
        fecha: fechaAux,
        tipoTurno: "ONLINE",
      };
      const objTurno = {
        dni: dniUser,
        nombre: nombreUser,
        apellido: apellidoUser,
        email: emailUser,
        telefono: telefonoUser,
        cuentaContrato: cuentaUser,
        titularCuenta: titularUser,
        observaciones: observaciones,
        ...obj,
      };

      dispatch(setTurnoValues(obj));
      createTurnoFunc(objTurno);
      deshabilitar();
    },
  });

  async function confirmarButton() {
    setDisable(true);
    const res = await getSucursaleById(formik.values.sucursalId);

    if (res.data.habilitada === false) {
      setCauDeshabilitada(true);
    } else {
      formik.handleSubmit();
    }
  }

  async function createTurnoFunc(obj) {
    {
      try {
        const res = await createTurno(obj);
        const { id } = res.data;
        dispatch(setTurnoConfirmado(id));
      } catch (e) {
        return (
          <Alert severity="error">This is an error alert — check it out!</Alert>
        );
      }
    }
  }

  async function getTurnosDisponiblesFunc(fecha, sucursal, Caja) {
    const auxFecha = formatISO(new Date(`${fecha}`), {
      representation: "date",
    });
    const res = await getTurnosDisponibles(auxFecha, sucursal, Caja);
    setTurnos(res.data);
  }
  function handleDateChange(date, sucursalId) {
    formik.setFieldValue("fecha", date);
    formik.setFieldValue("hora", "");

    getTurnosDisponiblesFunc(date, sucursalId, tipoCaja);
    setHora(true);
  }

  async function getSucursalesFunc() {
    const res = await getSucursalesByTipoCaja(tipoCaja);
    setSucursales(res.data);
  }

  async function getFeriadosFunc() {
    const res = await getFeriados();
    setFeriados(res.data);
  }

  async function addDiasFull(fechaFin, fechaInicio, sucursalId, tipoCajaId) {
    const res = await getDiasFull(
      fechaFin,
      fechaInicio,
      sucursalId,
      tipoCajaId
    );
    setDiasFull(res.data);
    setIsLoading(false);
  }

  function addDiaFull() {
    setIsLoading(true);
    const fechaInicio = formatISO(fechaActual.current, {
      representation: "date",
    });
    const fechaFin = formatISO(addDays(fechaActual.current, 30), {
      representation: "date",
    });

    addDiasFull(fechaFin, fechaInicio, formik.values.sucursalId, tipoCaja);
    setCalendario(true);
    setCambiomess(true);
    setElegirFecha(false);
    setMostrarHora(true);
  }

  let feriadoData = [];

  function populateFeriados() {
    if (diasFull === null) return;
    for (let a = 0; a < diasFull.length; a++) {
      const día = formatDate(new Date(diasFull[a]));
      let d = new Date(día);
      d.setUTCDate(d.getUTCDate() + 1);

      feriadoData.push(
        new Date(d.toISOString().substr(0, 10).replace(/-/g, "/"))
      );
    }
    for (let a = 0; a < feriados.length; a++) {
      const day = feriados[a].fecha.replace(/-/g, "/");
      feriadoData.push(new Date(day));
    }

    return feriadoData;
  }

  const deshabilitar = () => {
    if (formik.values.hora !== "") {
      setHabilitado(!habilitado);
      document.getElementById("datePicker").style.display = "none";
    }
  };

  const useStyles = makeStyles((theme) => ({
    formControl: {
      marginTop: "3%",
      alignSelf: "center",
      width: "30%",
      [theme.breakpoints.down("sm")]: {
        width: "70%",
      },
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    try {
      getSucursalesFunc();
    } catch (err) {
      setError(err);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    try {
      setWidthText(width > 1023 ? "40%" : "60%");
      setMarginTop(width > 1023 ? "2%" : "8%");
      getFeriadosFunc();
    } catch (err) {
      setError(err);
    }
  }, [width]); // eslint-disable-line react-hooks/exhaustive-deps

  const sucursalData = (value) => {
    handleDateChange(fechaActual.current, value);
  };

  const sucursalSeleccionada = sucursales.find(
    (sucursal) => sucursal.id === formik.values.sucursalId
  );

  const { nombre, localidad, direccion } = sucursalSeleccionada || {};

  function resetValues(value) {
    if (value === false) {
      setCauDeshabilitada(true);
    } else {
      setCauDeshabilitada(false);
    }

    setCalendario(false);
    setCambiomess(false);
    setElegirFecha(true);
    setHora(false);
    setSubmit(false);
    setMostrarHora(false);
    setFechaMinimaCalendario(null);
    setDiasFull(null);
    formik.values.fecha = "";
    formik.values.hora = "";
  }

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const [showMap, setShowMap] = useState(false);
  const [showVerMapText, setShowVerMapText] = useState(true);

  const onClick = () => {
    setShowMap(!showMap);
    setShowVerMapText(!showVerMapText);
  };
  const onClickOk = () => {
    setShowMap(!showMap);
    setShowVerMapText(!showVerMapText);
  };
  const VerText = () => {
    return <Typography variant="p">Ver sucursal en el mapa</Typography>;
  };

  const [marginTop, setMarginTop] = useState("");
  const [widthText, setWidthText] = useState("");
  const [submit, setSubmit] = useState(false);
  const [cauDeshabilitada, setCauDeshabilitada] = useState();

  async function getRecaptchaFunc(respo) {
    const res = await getRecaptcha(respo);
    if (res.status === 200) {
      setCambiomess(true);
      setSubmit(true);
    }
    if (res.status !== 200) {
      setError("RECAPTCHA ERROR");
    }
  }

  function onChange(value) {
    getRecaptchaFunc(value);
  }
  function onExpired() {
    setSubmit(false);
  }

  const feriadosFechaFormateada = feriados.map((feriado) =>
    formatDate(addDays(new Date(feriado.fecha), 1))
  );
  const isFeriado = (fecha) =>
    feriadosFechaFormateada.includes(formatDate(fecha));

  const isDiaHabil = (date) => {
    if (isWeekday(date) && !isFeriado(date)) {
      return true;
    } else {
      return false;
    }
  };

  const getProximaFechaValida = (date, fechasOcupadas) => {
    let proximaFechaValida = date;
    while (
      !isDiaHabil(proximaFechaValida) ||
      fechasOcupadas.includes(formatDate(proximaFechaValida))
    ) {
      proximaFechaValida = addDays(proximaFechaValida, 1);
    }

    return proximaFechaValida;
  };

  const dateNowString = formatDate(fechaActual.current).toString();
  const hourCurrentString = formatHour(fechaActual.current).toString();

  const turnosDisponibles = useMemo(
    () =>
      turnos &&
      turnos.filter((turn) => {
        if (turn.fecha === dateNowString) {
          return turn.hora > hourCurrentString;
        } else {
          return true;
        }
      }),
    [hourCurrentString, dateNowString, turnos]
  );

  const calcularMinimaFechaCalendario = (turnosDisponibles, fechasOcupadas) => {
    if (Array.isArray(turnosDisponibles)) {
      if (!fechaMinimaCalendario) {
        const noHayTurnosHoy = turnosDisponibles
          .filter((turno) => turno.fecha === dateNowString)
          .every((turno) => turno.hora < hourCurrentString);

        let proximaFechaValida;

        if (noHayTurnosHoy) {
          proximaFechaValida = getProximaFechaValida(
            addDays(fechaActual.current, 1),
            fechasOcupadas
          );
        } else {
          proximaFechaValida = getProximaFechaValida(
            fechaActual.current,
            fechasOcupadas
          );
        }

        setFechaMinimaCalendario(proximaFechaValida);

        return proximaFechaValida;
      } else {
        return getProximaFechaValida(fechaActual.current, fechasOcupadas);
      }
    } else {
      return fechaActual.current;
    }
  };

  // const fechalimitePlanos = new Date(2023, 9, 31, 16);
  // const fechalimiteCauSanJusto = new Date(2023, 11, 15, 17);

  useEffect(() => {
    if (
      formatDate(fechaActual.current) === formatDate(formik.values.fecha) &&
      formik.values.sucursalId &&
      diasFull !== null
    ) {
      const minimaFecha = calcularMinimaFechaCalendario(
        turnosDisponibles,
        diasFull
      );

      if (formatDate(minimaFecha) !== formatDate(fechaActual.current)) {
        handleDateChange(minimaFecha, formik.values.sucursalId);
      }
    }
  }, [
    formik.values.sucursalId,
    formik.values.fecha,
    turnosDisponibles,
    diasFull,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormControl
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <FormControl className={classes.formControl}>
          <InputLabel>
            <b>Elegí el Centro de Atención</b>
          </InputLabel>
          <Select
            id="sucursalId"
            name="sucursalId"
            style={{
              marginBottom: "15px",
              minWidth: "150",
              marginTop: marginTop,
            }}
            disabled={habilitado}
            onChange={(e) => {
              formik.handleChange(e);
              sucursalData(e.target.value);
            }}
            value={formik.values.sucursalId}
          >
            {sucursales &&
              sucursales.map((sucursal) => (
                <MenuItem
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Roboto",
                    display: "flex",
                    whiteSpace: "normal",
                  }}
                  key={`sucursal_${sucursal.id}`}
                  value={sucursal.id}
                  onClick={() => resetValues(sucursal.habilitada)}
                >
                  {sucursal.habilitada === false
                    ? sucursal.localidad.nombre === "GRANDES USUARIOS"
                      ? ` ${sucursal.nombre} - ${sucursal.localidad.nombre} - No disponible`
                      : ` ${sucursal.nombre} - ${sucursal.localidad.nombre} - ${sucursal.direccion} - No disponible`
                    : sucursal.localidad.nombre === "GRANDES USUARIOS"
                    ? ` ${sucursal.nombre} - ${sucursal.localidad.nombre}`
                    : ` ${sucursal.nombre} - ${sucursal.localidad.nombre} - ${sucursal.direccion}`}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        {cauDeshabilitada === true && (
          <div
            style={{
              backgroundColor: "#F9CDD0",
              border: "red 1px ridge",
              width: "30%",
              alignSelf: "center",
              marginBottom: "10px",
            }}
          >
            <Grid container>
              <Grid item xs={2}>
                <ErrorRoundedIcon
                  fontSize="large"
                  color="secondary"
                  style={{ marginTop: "25%", marginLeft: "25%" }}
                />
              </Grid>
              <Grid item xs>
                <p>
                  Esta sucursal no está disponible en este momento. Por favor,
                  volvé a intentar de nuevo más tarde.
                </p>
              </Grid>
            </Grid>
          </div>
        )}

        {cauDeshabilitada === false && (
          <div
            style={{
              alignSelf: "center",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <FormControl
              style={{
                alignSelf: "center",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {formik.values.sucursalId !== "" && (
                <Button
                  disabled={habilitado}
                  onClick={onClick}
                  style={{
                    fontSize: "12pt",
                    color: "blue",
                    textTransform: "none",
                    fontWeight: "bold",
                    fontFamily: "Roboto",
                  }}
                >
                  {showVerMapText && <VerText />}
                </Button>
              )}

              {showMap && <SucursalMaps iddd={formik.values.sucursalId} />}
              {showMap && (
                <div
                  style={{
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Button
                    onClick={onClickOk}
                    style={{
                      fontSize: "13pt",
                      color: "blue",
                      textTransform: "none",
                      fontWeight: "bold",
                      fontFamily: "Roboto",
                    }}
                  >
                    <Typography variant="p"> Ocultar mapa </Typography>
                  </Button>
                </div>
              )}
            </FormControl>

            {elegirFecha === true && (
              <Button
                style={{
                  alignSelf: "center",
                  textTransform: "none",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
                variant="contained"
                onClick={() => addDiaFull()}
              >
                Elegí la fecha
              </Button>
            )}
            {isLoading && (
              <CircularProgress
                style={{ marginLeft: "48%", marginTop: "5%" }}
              />
            )}
            {calendario === true && fechaMinimaCalendario && (
              <>
                <FormControl
                  style={{
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div id="datePicker">
                    <DatePickerComponent
                      style={{
                        marginTop: "5%",
                        marginRight: "10%",
                        width: "50%",
                        alignSelf: "center",
                      }}
                      id="fecha"
                      locale="es"
                      selected={formik.values.fecha}
                      name="fecha"
                      placeholderText="Elegí la fecha"
                      onChange={(date) =>
                        handleDateChange(date, formik.values.sucursalId)
                      }
                      dateFormat="MMMM d, yyyy"
                      filterDate={isWeekday}
                      minDate={new Date(fechaMinimaCalendario)}
                      maxDate={
                        /*    tipoCaja === 3
                          ? fechalimitePlanos
                          : */ setMinutes(addDays(fechaActual.current, 30), 30)
                      }
                      showDisabledMonthNavigation
                      inline={formik.values.sucursalId !== ""}
                      excludeDates={populateFeriados()}
                    />
                  </div>
                </FormControl>
                {turnosDisponibles?.length > 0 &&
                hora === true &&
                mostrarHora === true ? (
                  <>
                    {" "}
                    <FormControl
                      style={{
                        maxWidth: 200,
                        marginBottom: "15px",
                        marginTop: "3%",
                        width: widthText,
                        alignSelf: "center",
                      }}
                    >
                      <InputLabel>Elegí la hora</InputLabel>
                      <Select
                        id="hora"
                        name="hora"
                        disabled={habilitado}
                        onChange={(e) => {
                          formik.handleChange(e);
                          // horaData(e.target.value);
                        }}
                        value={formik.values.hora}
                      >
                        {turnosDisponibles.map((turno) => (
                          <MenuItem
                            key={`tur_${turno.hora}`}
                            value={`${turno.hora}_${turno.idCaja}`}
                          >
                            {turno.hora[0] +
                              turno.hora[1] +
                              turno.hora[2] +
                              turno.hora[3] +
                              turno.hora[4]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {/*   {tipoCaja === 3 && (
                      <div className="container-descarga-pdf">
                        <Button
                          style={{
                            backgroundColor: "#009bdb",
                            color: "white",
                            alignSelf: "center",
                            width: "30%",
                            padding: "6px 0",
                            margin: "auto",
                            marginBottom: "1%",
                            textTransform: "none",
                          }}
                          className="btn-descarga-pdf"
                          variant="contained"
                        >
                          <a
                            style={{
                              textDecoration: "none",
                              color: "white",
                              width: "100%",
                              height: "100%",
                            }}
                            href=" https://www.aysa.com.ar/media-library/usuarios/tramites_online/Requisitos-planos-sanitarios_2022.pdf"
                            target="_blank"
                          >
                            <span> Requisitos para solicitar planos</span>
                            <br />
                            <span style={{ fontWeight: "100" }}>Descargar</span>
                          </a>
                        </Button>
                      </div>
                    )} */}
                  </>
                ) : formik.values.sucursalId === 15 ? (
                  <>
                    {" "}
                    <div
                      style={{
                        marginTop: "3%",
                        position: "relative",
                        transform: "none",
                        fontFamily: "Roboto",
                        padding: "9pt",
                        textAlign: "center",
                        backgroundColor: "#F9CDD0",
                        border: "red 1px ridge",
                        width: widthText,
                        alignSelf: "center",
                      }}
                    >
                      <Grid container style={{ justifyContent: "center" }}>
                        <Grid item>
                          <p>
                            El Centro de Atención tiene todos los turnos
                            completos.
                          </p>
                        </Grid>
                      </Grid>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        marginTop: "3%",
                        position: "relative",
                        transform: "none",
                        fontFamily: "Roboto",
                        padding: "9pt",
                        textAlign: "center",
                        backgroundColor: "#F9CDD0",
                        border: "red 1px ridge",
                        width: widthText,
                        alignSelf: "center",
                      }}
                    >
                      <Grid container style={{ justifyContent: "center" }}>
                        <Grid item>
                          <p>
                            El Centro de Atención tiene todos los turnos
                            completos, por favor volvé a intentar mañana..
                          </p>
                        </Grid>
                      </Grid>
                    </div>{" "}
                  </>
                )}
              </>
            )}
            {formik.values.sucursalId === 15 && (
              <>
                <div
                  style={{
                    marginTop: "2%",
                    alignSelf: "center",
                    width: "48%",
                  }}
                  className="div-container_paper-box-form-turnos"
                >
                  <Box>
                    <Paper
                      elevation={3}
                      style={{
                        border: "2px solid #009BDB",
                        background: "#99D7F1",
                        marginBottom: "2%",
                      }}
                    >
                      <div
                        style={{
                          margin: "1%",
                          display: "flex",
                          marginBottom: "-2%",
                        }}
                        className="div_paper-box"
                      >
                        <InfoIcon
                          style={{
                            color: "#009BDB",
                            height: "4rem",
                            width: "3rem",
                          }}
                        />

                        <p className="text_paper-box-form-turnos">
                          Este centro de Atención dejará de funcionar el
                          15/12/2023.
                          <br />
                          <p
                            style={{
                              color: "#4A4A4A",
                              fontSize: "12px",
                              fontWeight: "700",
                              marginTop: "1%",
                            }}
                          >
                            Podés sacar tu turno previamente y seguir haciendo
                            tus trámites en forma presencial en:
                          </p>
                          <p
                            style={{
                              color: "#4A4A4A",
                              fontSize: "12px",
                              marginTop: "1%",
                              fontWeight: "500",
                            }}
                          >
                            LA MATANZA - RAMOS MEJÍA - Cangallo 561.
                          </p>
                          <p
                            style={{
                              color: "#4A4A4A",
                              fontSize: "12px",
                              marginTop: "1%",
                              fontWeight: "500",
                            }}
                          >
                            LA MATANZA - CIUDAD EVITA - Circ II Sec. 6 Chile y
                            Güemes.
                          </p>
                        </p>
                      </div>
                    </Paper>
                  </Box>
                </div>
              </>
            )}

            <Zoom in={!!formik.values.hora}>
              <div
                style={{
                  position: "relative",
                  alignSelf: "center",
                  transform: "none",
                  fontFamily: "Roboto",
                  padding: "9pt",
                  width: widthText,
                  border: "white 3px ridge",
                  textAlign: "center",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <b>Datos del Turno</b>
                </div>
                Ud. {nombreUser} {apellidoUser}, con DNI: {dniUser} esta a punto
                de sacar un turno para el Centro de Atención de {nombre} (
                {direccion}) - {localidad.nombre}. En la fecha{" "}
                {format(formik.values.fecha, "d/M/yyyy")} a las{" "}
                {formik.values.hora.slice(0, -5)} hs.
              </div>
            </Zoom>

            {formik.values.sucursalId !== "" &&
              formik.values.fecha !== "" &&
              formik.values.hora !== "" && (
                <div style={{ alignSelf: "center", marginTop: "1%" }}>
                  <ReCAPTCHA
                    sitekey="6LedcM0ZAAAAABYB6BrXUl_lX4NGmQGGOfZYMYgg"
                    onChange={onChange}
                    onExpired={onExpired}
                  />
                </div>
              )}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ marginTop: "3%", alignSelf: "center" }}>
                {submit === true && (
                  <Button
                    disabled={disable}
                    onClick={() => confirmarButton()}
                    variant="contained"
                    style={{ backgroundColor: "#0055a6", color: "white" }}
                  >
                    Confirmar Turno
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
      </FormControl>
    </form>
  );
};

export default FormTurnos;
