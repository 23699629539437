import React from "react";
import {
  Typography,
  Grid,
  Button,
  Paper,
  TableRow,
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableBody,
} from "@material-ui/core";
import ReportIcon from "@material-ui/icons/Report";

const TurnosAfectadosTabla = ({
  isTurnosAfectadosArrayEmpty,
  horaDesde,
  horaHasta,
  turnosAfectadosArray,
  bloquearButton,
  params,
  tipoCajaId,
  idCajaBySucursal,
}) => {
  const { fecha } = params;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "65%",
          marginLeft: "16%",
          padding: "4%",
          border: "1px solid grey",
          boxShadow: "3px 3px 5px grey",
          textTransform: "none",
          justifyContent: "center",
        }}
      >
        <Grid container>
          <Grid item xs={6}>
            <Typography
              style={{
                fontSize: "16px",
                fontFamily: "Roboto",
                color: "#0055A6",
              }}
            >
              Horario de Bloqueo
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {horaDesde}/{horaHasta}
          </Grid>
        </Grid>
      </div>
      <br />

      {isTurnosAfectadosArrayEmpty == false && (
        <div style={{ width: "100%", marginLeft: "1%" }}>
          <p
            style={{
              marginBottom: "10px",
              fontFamily: "Roboto",
              fontSize: "14px",
              color: "black",
            }}
          >
            Turnos afectados
          </p>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: "#EDEBEC" }}>
                  <TableCell id="tableCell">Fecha</TableCell>
                  <TableCell id="tableCell">Nombre</TableCell>
                  <TableCell id="tableCell">Apellido</TableCell>
                  {/*  <TableCell id="tableCell">Acción</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {turnosAfectadosArray &&
                  turnosAfectadosArray.map((turno) => (
                    <TableRow key={turno.id}>
                      <TableCell id="tableCell" component="th" scope="row">
                        {turno.fecha || "-"}
                      </TableCell>
                      <TableCell id="tableCell">
                        {turno.cliente.nombre || "-"}{" "}
                      </TableCell>
                      <TableCell id="tableCell">
                        {turno.cliente.apellido || "-"}
                      </TableCell>
                      {/*  <TableCell>
                        {turno.cancelado == true ? "Cancelado" : null}
                      </TableCell> */}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <p
            style={{
              marginBottom: "10px",
              fontFamily: "Roboto",
              fontSize: "12px",
              color: "#009BDB",
              marginLeft: "37%",
            }}
          >
            *Al presionar el botón Bloquear se cancelaran todos los turnos
          </p>

          <Button
            style={{
              textTransform: "none",
              marginLeft: "64%",
              alignSelf: "center",
              backgroundColor: "#009BDB",
              color: "white",
              width: "35%",
              marginTop: "2%",
            }}
            onClick={() => {
              const obj = {
                descripcion: "Bloqueo Aysa",
                fechaDesde: fecha,
                fechaHasta: fecha,
                horaDesde: horaDesde,
                horaHasta: horaHasta,
                caja: idCajaBySucursal,
                tipoBloqueo: 1,
              };
              bloquearButton(obj);
            }}
            variant="contained"
          >
            Bloquear
          </Button>
        </div>
      )}

      {isTurnosAfectadosArrayEmpty == true && (
        <div
          style={{
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <br />
          <ReportIcon style={{ alignSelf: "center", color: "red" }} />

          <p style={{ color: "red", fontSize: "14px", fontFamily: "Roboto" }}>
            No hay turnos agendados en el horario indicado
          </p>
          <Button
            style={{
              textTransform: "none",
              marginLeft: "130%",
              alignSelf: "center",
              backgroundColor: "#009BDB",
              color: "white",
              width: "35%",
              marginTop: "2%",
            }}
            onClick={() => {
              const obj = {
                descripcion: "Bloqueo Aysa",
                fechaDesde: fecha,
                fechaHasta: fecha,
                horaDesde: horaDesde,
                horaHasta: horaHasta,
                caja: idCajaBySucursal,
                tipoBloqueo: 1,
              };
              bloquearButton(obj);
            }}
            variant="contained"
          >
            Bloquear
          </Button>
        </div>
      )}
    </div>
  );
};

export default TurnosAfectadosTabla;
