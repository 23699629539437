import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  Paper,
  TableRow,
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableBody,
  Box,
  Button,
  CircularProgress,
} from "@material-ui/core";
import {
  getTurnosByFecha_Caja_Sucursal,
  generaCsvAtencionTurnosEnElDia,
  generaPdfAtencionTurnosEnElDia,
} from "api";
import "./ReporteAtencionEnElDiaTabla.css";
import { Pagination } from "@material-ui/lab";
import usePagination from "Hooks/usePagination";
import LoaderSpinner from "../Loader/LoaderSpinner";
import swal from "sweetalert2";

const ReporteAtencionEnElDiaTabla = ({
  fechaDesde,
  fechaHasta,
  imprimir,
  setImprimir,
  setHabilitado,
  setShowResults,
  showResults,
}) => {
  const sucursalIdArray = useSelector(
    (state) => state.sucursales.sucursalesArray
  );
  const tipoDeTramiteArray = useSelector(
    (state) => state.tramites.tramitesArray
  );
  const [resultados, setResultados] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const enSucursal = "";
  const [flag, setFlag] = useState(false);

  function swalWarning() {
    swal
      .fire({
        position: "center",
        icon: "warning",
        title: "Reporte Exportable",
        text: "El rango de fecha seleccionado contiene más de 10.000 turnos y se generará un archivo en formato CSV para su descarga. ¿Desea continuar?",
        confirmButtonColor: "#009bdb",
        confirmButtonText: "Sí",
        showCancelButton: true,
        cancelButtonText: "No",
        cancelButtonColor: "#b7b7b7",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return handlePrintCSV(
            fechaDesde,
            fechaHasta,
            sucursalIdArray,
            tipoDeTramiteArray
          );
        },
        allowOutsideClick: () => !swal.isLoading(),
      })
      .then((result) => {
        if (!result.value) {
          setHabilitado(false);
          setShowResults(false);
        }
      });
  }

  async function getTurnosByFecha_Caja_SucursalFunc() {
    if (
      sucursalIdArray.length > 0 &&
      tipoDeTramiteArray.length > 0 &&
      fechaDesde !== "" &&
      fechaHasta !== ""
    ) {
      try {
        const res = await getTurnosByFecha_Caja_Sucursal(
          fechaDesde,
          sucursalIdArray,
          tipoDeTramiteArray,
          fechaHasta,
          enSucursal
        );
        setResultados(res.data);
        setIsLoading(false);
        setFlag(true);
        return res.data;
      } catch (err) {
        if (
          err.response.status === 400 &&
          err.response.data.message === "Supera el maximo valor permitido"
        ) {
          swalWarning();
        }

        if (err.response?.status !== 400 && err.response?.status !== 401) {
          window.alert(
            "No se puede obtener la información solicitada en estos momentos, por favor intentá nuevamente más tarde."
          );
        }
      }
    }
  }

  async function handlePrint(
    fechaDesde,
    fechaHasta,
    sucursalIdArray,
    tipoDeTramiteArray
  ) {
    setImprimir(true);
    await generaPdfAtencionTurnosEnElDia(
      fechaDesde,
      fechaHasta,
      sucursalIdArray,
      tipoDeTramiteArray,
      enSucursal
    )
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        setImprimir(false);
        setHabilitado(false);
        setShowResults(false);
      })
      .catch((error) => {
        console.log({ error });
      });
  }
  async function handlePrintCSV(
    fechaDesde,
    fechaHasta,
    sucursalesId,
    tipoCajaId
  ) {
    await generaCsvAtencionTurnosEnElDia(
      fechaDesde,
      fechaHasta,
      sucursalesId,
      tipoCajaId,
      enSucursal
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "turnos.csv");
        document.body.appendChild(link);
        link.click();
        setHabilitado(false);
        setShowResults(false);
        return response.data;
      })
      .catch((error) => {
        if (error.response?.status !== 401) {
          window.alert("Hubo un error, por favor intentar en otro momento");
          setHabilitado(false);
          setShowResults(false);
          setIsLoading(false);
        }
      });
  }

  const totalDeTurnosAgendados = resultados?.content?.length;

  const turnosCancelados = resultados?.content?.reduce(
    (contador, resultado) => {
      if (resultado.cancelado === true) return contador + 1;
      else return contador;
    },
    0
  );
  const turnosAtendidos = resultados?.content?.reduce((contador, resultado) => {
    if (resultado.horaFinAtencion !== null) return contador + 1;
    else return contador;
  }, 0);

  const turnosNoPresentados = resultados?.content?.reduce(
    (contador, resultado) => {
      if (resultado.horaFinAtencion === null && resultado.cancelado === false)
        return contador + 1;
      else return contador;
    },
    0
  );

  const porcentajeTurnosCancelados =
    (turnosCancelados * 100) / totalDeTurnosAgendados;
  const porcentajeTurnosAtendidos =
    (turnosAtendidos * 100) / totalDeTurnosAgendados;
  const porcentajeTurnosNoPresentados =
    (turnosNoPresentados * 100) / totalDeTurnosAgendados;

  const per_Page = 50;
  const resultadosParaPaginar = resultados?.content;
  const count = Math.ceil(resultadosParaPaginar?.length / per_Page);
  const resultadoPaginado = usePagination(resultadosParaPaginar, per_Page);

  const handleChange = (e, p) => {
    setPage(p);
    resultadoPaginado.jump(p);
  };

  useEffect(() => {
    getTurnosByFecha_Caja_SucursalFunc();
  }, []);
  useEffect(() => {
    if (flag === true) {
      setShowResults(false);
    }
  }, [sucursalIdArray, tipoDeTramiteArray, fechaDesde, fechaHasta]);

  return (
    <div style={{ width: "100%", marginLeft: "1%", marginBottom: "3%" }}>
      {isLoading ? (
        <LoaderSpinner />
      ) : (
        <>
          <Grid
            container
            className="botonera__reportes-csv"
            style={{ zIndex: "100", marginBottom: "-3%" }}
          >
            {imprimir === false ? (
              <>
                <Grid item xs>
                  <Button
                    onClick={() =>
                      handlePrintCSV(
                        fechaDesde,
                        fechaHasta,
                        sucursalIdArray,
                        tipoDeTramiteArray
                      )
                    }
                    style={{
                      textTransform: "none",
                      marginLeft: "61%",
                      alignSelf: "center",
                      backgroundColor: "#0055a6",
                      color: "white",
                      width: "15%",
                      zIndex: "100",
                      marginBottom: "-6%",
                    }}
                    variant="contained"
                  >
                    CSV
                  </Button>
                </Grid>
                <Grid item xs>
                  <Button
                    className="btn__generar-reporte-multiple"
                    style={{ marginLeft: "28%" }}
                    onClick={() => {
                      handlePrint(
                        fechaDesde,
                        fechaHasta,
                        sucursalIdArray,
                        tipoDeTramiteArray
                      );
                    }}
                    variant="contained"
                  >
                    Pdf
                  </Button>
                </Grid>
              </>
            ) : (
              <CircularProgress style={{ marginLeft: "50%" }} />
            )}
          </Grid>

          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Grid item xs={12}>
              <p
                style={{
                  marginTop: "5px",
                  fontSize: "18px",
                  fontFamily: "Roboto",
                  fontWeight: 400,
                }}
                className="title__texto-listado"
              >
                {`Reporte de atención realizada en el día  ${fechaDesde
                  .split("-")
                  .reverse()
                  .join("/")} hasta el día ${fechaHasta
                  .split("-")
                  .reverse()
                  .join("/")}`}
              </p>
              <div className="turno__contador">
                <p className="turno__subtitle">
                  Turnos Agendados : {totalDeTurnosAgendados}
                </p>
                <p className="turno__subtitle">
                  Turnos Atendidos/Presentados : {turnosAtendidos} ={" "}
                  {porcentajeTurnosAtendidos.toFixed(2)} %
                </p>
                <p className="turno__subtitle">
                  Turnos no Presentados : {turnosNoPresentados} ={" "}
                  {porcentajeTurnosNoPresentados.toFixed(2)} %
                </p>
                <p className="turno__subtitle">
                  Turnos Cancelados : {turnosCancelados} ={" "}
                  {porcentajeTurnosCancelados.toFixed(2)} %
                </p>
              </div>
            </Grid>
          </Grid>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: "#EDEBEC" }}>
                  <TableCell id="tableCell" align="center">
                    Fecha
                  </TableCell>
                  <TableCell id="tableCell" align="center">
                    Tipo de Trámite
                  </TableCell>
                  <TableCell id="tableCell" align="center">
                    Sucursal
                  </TableCell>
                  <TableCell id="tableCell" align="center">
                    ID Turno
                  </TableCell>

                  <TableCell id="tableCell" align="center">
                    Nombre y Apellido
                  </TableCell>
                  <TableCell id="tableCell" align="center">
                    Usuario Atención
                  </TableCell>
                  <TableCell id="tableCell" align="center">
                    Hora Inicio Atención
                  </TableCell>
                  <TableCell id="tableCell" align="center">
                    Hora Fin Atención
                  </TableCell>
                  <TableCell id="tableCell" align="center">
                    Duración de la Atención
                  </TableCell>
                  <TableCell id="tableCell" align="center">
                    Estado del Turno
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resultadoPaginado.currentData().map(
                  (turno) =>
                    turno.cancelado === false && (
                      <TableRow key={turno.id}>
                        <TableCell
                          id="tableCell"
                          component="th"
                          scope="row"
                          align="center"
                        >
                          {turno.fecha?.split("-").reverse().join("/")}
                        </TableCell>
                        <TableCell
                          id="tableCell"
                          component="th"
                          scope="row"
                          align="center"
                        >
                          {turno.caja?.tipo?.nombre}
                        </TableCell>
                        <TableCell id="tableCell" align="center">
                          {turno.sucursal.localidad.nombre}
                        </TableCell>
                        <TableCell id="tableCell" align="center">
                          {turno.id}
                        </TableCell>
                        <TableCell id="tableCell" align="center">
                          {`${turno.cliente.nombre.toUpperCase()} ${turno.cliente.apellido.toUpperCase()}`}
                        </TableCell>
                        <TableCell id="tableCell" align="center">
                          {turno.emailAtencion}
                        </TableCell>
                        <TableCell id="tableCell" align="center">
                          {turno.horaInicioAtencion}
                        </TableCell>
                        <TableCell id="tableCell" align="center">
                          {turno.horaFinAtencion}
                        </TableCell>
                        <TableCell id="tableCell" align="center">
                          {turno.duracionAtencion}
                        </TableCell>
                        <TableCell id="tableCell" align="center">
                          {turno?.estado ? turno?.estado : "Sin dato"}
                        </TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
              marginTop: "1%",
            }}
          >
            {" "}
            {resultados?.content?.length > 50 && (
              <Box component="span">
                <Pagination
                  count={count}
                  page={page}
                  onChange={handleChange}
                  defaultPage={1}
                  color="primary"
                  showFirstButton
                  showLastButton
                />
              </Box>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ReporteAtencionEnElDiaTabla;
