import React, { useState, useEffect } from "react";
import { Grid, Container, Paper, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import {
  getFeriados,
  getSucursalesByTipoCaja,
  getTipoCaja,
  findCajasBySucursalTipoTramite,
  updateCaja,
  getBloqueoHorario,
  deleteBloqueoHorario,
  addBloque,
  turnosAfectados,
  cancelarTurno,
} from "api";
import { useFormik } from "formik";
import {
  Typography,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
} from "@material-ui/core";
import { filterSucursales } from "backOfficeUsuariosCau/components/utils/filterSucursales";
import { sucursalesActualizarPuestosFiltradaArray } from "const/constantes";
import Header from "usuariosOnline/components/Header/Header";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ErrorIcon from "@material-ui/icons/Error";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert2";
import "./AdministrarBreak.css";
import TablaExcepciones from "./TablaExcepciones";
import NuevoBreakSesion from "./NuevoBreakSesion";
import { differenceInDays } from "date-fns";
import SesionExpirada from "backOfficeUsuariosCau/components/SesionExpirada/SesionExpirada";
import ButtonEscape from "const/ButtonEscape";

const AdministrarBreak = () => {
  const welcome = "Bienvenido !";

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
    },
    textField: {
      marginTop: theme.spacing(6),
    },
    table: {},
    customOutline: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "grey",
        borderRadius: "1px",
        padding: "1px",
      },
    },
    root: {
      display: "flex",
      flexWrap: "wrap",
      marginBottom: "2%",
      "& > *": {
        width: "55%",
        height: theme.spacing(15),
        marginLeft: "23%",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "-24%",
        width: "196%",
      },
    },
    paper: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        with: "100%",
        height: theme.spacing(18),
      },
    },
    wrapper: {
      display: "flex",
      justifyContent: "space-between",
      width: "80%",
      marginTop: "2%",
    },
    texto1: {
      color: "#0055A6",
      justifyContent: "center",
      marginTop: "5%",
    },
    texto2: {
      justifyContent: "center",
      marginTop: "5%",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "2%",
      },
    },
    texto3: {
      color: "red",
      fontSize: "13px",
      margin: "5px",
      padding: "0 7%",
    },
  }));

  const classes = useStyles();
  let arrayIds = [];
  const [sucursales, setSucursales] = useState([]);
  const [tipoDecajas, setTipoDecajas] = useState([]);
  const [sucId, setSucId] = useState("");
  const [tramId, setTramId] = useState("");
  const [confirmar, setConfirmar] = useState(false);
  const [deshabilitarButton, setDeshabilitarButton] = useState(false);
  const [buscarNuevosHorariosButton, setBuscarNuevosHorariosButton] =
    useState(false);
  const [buscarButton, setBuscarButton] = useState(true);
  const [result, setResult] = useState(false);
  const [habilitado, setHabilitado] = useState(false);
  const [feriados, setFeriados] = useState([]);
  const [horarioDeBreakDeSucursal, setHorarioDeBreakDeSucursal] = useState("");
  const [excepcionesArray, setExcepcionesArray] = useState([]);
  const [turnosAfectadosArray, setTurnosAfectadosArray] = useState([]);
  const [turnosAfectadosIdsArray, setTurnosAfectadosIdsArray] = useState([]);
  const [sucursalNombre, setSucursalNombre] = useState("");
  const [horaInicio, setHoraInicio] = useState("09:00");
  const [horaFin, setHoraFin] = useState("09:00");
  const [nuevoBreakSesion, setNuevoBreakSesion] = useState(false);
  const [nuevosHorariosDeBreak, setNuevosHorariosDeBreak] = useState(false);
  const [habilitadoHorasInicioFin, setHabilitadoHorasInicioFin] =
    useState(false);
  const [idCajaBysucursal, setIdCajaBysucursal] = useState("");

  async function getSucursalesFunc(caja) {
    let suc = [];
    const res = await getSucursalesByTipoCaja(caja);
    suc = res.data;

    setSucursales(
      filterSucursales(sucursalesActualizarPuestosFiltradaArray, suc)
    );
  }

  async function getFeriadosFunc() {
    const res = await getFeriados();
    setFeriados(res.data);
  }

  function populateFeriados(holiday) {
    const feriadoData = [];
    if (holiday) {
      for (let a = 0; a < holiday.length; a++) {
        const day = holiday[a].fecha.replace(/-/g, "/");
        feriadoData.push(new Date(day));
      }
    }
    return feriadoData;
  }

  async function getTipoCajaFunc() {
    const res = await getTipoCaja(true);
    setTipoDecajas(res.data);
  }

  async function cancelarTurnoFunc() {
    for (var i = 0; i < turnosAfectadosIdsArray.length; i++) {
      await cancelarTurno(turnosAfectadosIdsArray[i]);
    }
  }

  async function getBloqueoHorarioFunc(
    sucursalId,
    tipoCajaID,
    fechaDesde,
    fechaHasta,
    tipoBloqueo
  ) {
    const res = await getBloqueoHorario(
      sucursalId,
      tipoCajaID,
      fechaDesde,
      fechaHasta,
      2
    );
    setExcepcionesArray(res.data);
  }

  async function turnosAfectadosFunc(
    fechaInicio,
    fechaFin,
    sucursalId,
    tipoCajaId,
    horaInicio,
    horaFin
  ) {
    const res = await turnosAfectados(
      fechaInicio,
      fechaFin,
      sucursalId,
      tipoCajaId,
      horaInicio,
      horaFin
    );
    setTurnosAfectadosArray(res.data);
    res.data.map((item) => arrayIds.push(item.id));
    setTurnosAfectadosIdsArray(arrayIds);
  }

  async function addBloqueoHorarioFunc(obj) {
    try {
      await addBloque(obj);
      swal.fire({
        icon: "success",
        title: "Operación Éxitosa",
        text: ` El Break se ha actualizado éxitosamente.`,
        button: "Aceptar",
        confirmButtonColor: "#009bdb",
      });
      cancelarTurnoFunc();
      formik.handleSubmit();
      setResult(true);
      setNuevoBreakSesion(false);
    } catch (e) {
      if (e.response.status !== 401) {
        swal.fire({
          icon: "warning",
          title: "Error",
          text: ` Hubo un error, por favor intente mas tarde.`,
          button: "Cerrar",
          confirmButtonColor: "#b7b7b7",
        });
      }
    }
  }

  async function findCajasBySucursalTipoTramiteFunc(tramiteId, sucursalId) {
    const res = await findCajasBySucursalTipoTramite(tramiteId, sucursalId);
    setHorarioDeBreakDeSucursal(
      res.data.horaDeInicioDeBreak + "/" + res.data.horaDeFinDeBreak
    );
    setIdCajaBysucursal(res.data.id);
  }

  async function eliminarExcepcionFunc(id) {
    try {
      await deleteBloqueoHorario(
        formik.values.sucursalId,
        formik.values.tipoCaja,
        id
      );
      getBloqueoHorarioFunc(
        formik.values.sucursalId,
        formik.values.tipoCaja,
        formik.values.fechaDesde,
        formik.values.fechaHasta,
        2
      );
      swal.fire({
        icon: "success",
        title: "Operación Éxitosa",
        text: ` La excepción se ha eliminado éxitosamente.`,
        button: "Aceptar",
        confirmButtonColor: "#009bdb",
      });
    } catch (e) {
      if (e.response.status !== 401) {
        swal.fire({
          icon: "warning",
          title: "Error",
          text: ` Hubo un error, por favor intente mas tarde.`,
          button: "Cerrar",
          confirmButtonColor: "#b7b7b7",
        });
      }
    }
  }

  function nuevoHorarioBreakButton() {
    if (excepcionesArray.length === 0) {
      setResult(false);
      setNuevoBreakSesion(true);
      setBuscarButton(false);
      setNuevosHorariosDeBreak(false);
      setHabilitadoHorasInicioFin(false);
      setBuscarNuevosHorariosButton(false);
      setHoraInicio("09:00");
      setHoraFin("09:00");
    } else if (excepcionesArray.length !== 0) {
      swalWarning("generarNuevoBreak", null);
    }
  }

  useEffect(() => {
    try {
      getTipoCajaFunc();
      getSucursalesFunc(tramId || 1);
    } catch (err) {
      console.log(err);
    }
  }, [tramId, sucId]);

  const validation = Yup.object({
    sucursalId: Yup.string().required("requerido"),
    fechaDesde: Yup.string().required("requerido"),
    fechaHasta: Yup.string().required("requerido"),
    tipoCaja: Yup.string().required("requerido"),
  });

  const formik = useFormik({
    initialValues: {
      sucursalId: "",
      fechaDesde: "",
      fechaHasta: "",
      tipoCaja: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      const { sucursalId, tipoCaja, fechaDesde, fechaHasta } = values;
      if (
        formik.values.fechaHasta == "" ||
        numeroDeDiasParaGenerarReporte < 0
      ) {
        formik.setFieldError("fechaHasta", " ");
      } else {
        getBloqueoHorarioFunc(sucursalId, tipoCaja, fechaDesde, fechaHasta, 2);
        findCajasBySucursalTipoTramiteFunc(tramId, sucId);
        setResult(true);
        setHabilitado(true);
      }
    },
  });

  const rangoDeFechaParaGenerarReporte = () => {
    const toDate = (dateStr) => {
      const [year, month, day] = dateStr.split("-");
      return new Date(year, month - 1, day);
    };
    const daysBetween = differenceInDays(
      toDate(formik.values.fechaHasta),
      toDate(formik.values.fechaDesde)
    );
    return daysBetween;
  };
  const numeroDeDiasParaGenerarReporte = rangoDeFechaParaGenerarReporte();

  function swalWarning(type, values) {
    const confirmarOperacionTitle = "Confirmar Operación";
    const confirmarOperacionText = `¿Está seguro/a que desea actualizar la hora del Break ? (${horaInicio}/${horaFin}) para el día ${formik.values.fechaDesde
      .split("-")
      .reverse()
      .join("/")} al día ${formik.values.fechaHasta
      .split("-")
      .reverse()
      .join("/")}`;
    const generarNuevoBreakTitle = "Para generar un nuevo Break";
    const generarNuevoBreakText =
      "Deberás eliminar las excepciones existentes.";
    return swal
      .fire({
        position: "center",
        title:
          (type == "generarNuevoBreak" && generarNuevoBreakTitle) ||
          (type == "confirmarNuevoBreak" && confirmarOperacionTitle),
        text:
          (type == "generarNuevoBreak" && generarNuevoBreakText) ||
          (type == "confirmarNuevoBreak" && confirmarOperacionText),
        icon: "warning",
        confirmButtonColor: "#009bdb",
        confirmButtonText:
          (type == "generarNuevoBreak" && "Cerrar") ||
          (type == "confirmarNuevoBreak" && "Sí"),
        showCancelButton:
          (type == "generarNuevoBreak" && false) ||
          (type == "confirmarNuevoBreak" && true),
        cancelButtonText: "No",
        cancelButtonColor: "#b7b7b7",
      })
      .then((resultado) => {
        if (resultado.value) {
          if (type == "generarNuevoBreak") {
          }
          if (type == "confirmarNuevoBreak") {
            addBloqueoHorarioFunc(values);
          }
        } else {
        }
      });
  }

  return (
    <Container fixed>
      <div>
        <Header welcome={welcome} button={<ButtonEscape />} />
      </div>
      <SesionExpirada timer={900000} />
      <div
        style={{
          marginTop: "3%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Grid
          container
          style={{ marginLeft: "2%" }}
          className="grid__container-header-reporte"
        >
          <Grid item xs={3}>
            <Link to="/backOfficeL" style={{ textDecoration: "none" }}>
              <Button
                startIcon={<ArrowBackIcon />}
                style={{
                  textTransform: "none",
                  backgroundColor: "white",
                  color: "#009BDB",
                }}
              >
                Volver
              </Button>
            </Link>
          </Grid>
          <Grid item xs={9}>
            <Typography
              style={{
                marginLeft: "2%",
                color: "#4A4A4A",
                fontFamily: "Roboto",
                fontWeight: "bold",
                fontSize: "18px",
                lineHeight: "21.09px",
              }}
              className="title__reportes-header"
            >
              Administrar Break
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            item
            xs={12}
          >
            <form
              onSubmit={formik.handleSubmit}
              style={{ alignSelf: "center", width: "45%", marginTop: "1%" }}
              className="form__Reportes-multiple"
            >
              <FormControl className={classes.formControl}>
                <InputLabel style={{ fontSize: "16pt", fontFamily: "Roboto" }}>
                  Tipo Atención
                </InputLabel>
                <Select
                  id="tipoCaja"
                  name="tipoCaja"
                  value={formik.values.tipoCaja}
                  onChange={formik.handleChange}
                  helperText={formik.errors.tipoCaja || "*Campo requerido"}
                  error={formik.errors.tipoCaja}
                  disabled={habilitado}
                >
                  {tipoDecajas &&
                    tipoDecajas.map((tipo) => (
                      <MenuItem
                        onClick={() => {
                          setTramId(tipo.id);
                        }}
                        key={`tipo_${tipo.id}`}
                        value={tipo.id}
                      >
                        {tipo.nombre}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl className={classes.formControl}>
                <InputLabel style={{ fontSize: "16pt", fontFamily: "Roboto" }}>
                  Elegí el Centro de Atención
                </InputLabel>
                <Select
                  id="sucursalId"
                  name="sucursalId"
                  style={{
                    marginBottom: "15px",
                  }}
                  disabled={habilitado}
                  onChange={formik.handleChange}
                  value={formik.values.sucursalId}
                  error={formik.errors.sucursalId}
                >
                  {sucursales &&
                    sucursales.map((sucursal) => (
                      <MenuItem
                        style={{ fontSize: "11pt", fontFamily: "Roboto" }}
                        key={`sucursal_${sucursal.id}`}
                        value={sucursal.id}
                        onClick={() => {
                          setDeshabilitarButton(false);
                          setConfirmar(false);
                          setSucId(sucursal.id);
                          setSucursalNombre(
                            sucursal.nombre +
                              " - " +
                              sucursal.localidad.nombre +
                              " - " +
                              sucursal.direccion
                          );
                        }}
                      >
                        {sucursal.localidad.nombre === "GRANDES USUARIOS"
                          ? ` ${sucursal.nombre} - ${sucursal.localidad.nombre}`
                          : ` ${sucursal.nombre} - ${sucursal.localidad.nombre} - ${sucursal.direccion}`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </form>
          </Grid>
        </Grid>

        <div style={{ width: "100%" }}>
          <FormControl
            style={{ width: "50%", marginLeft: "28%" }}
            className="form__Reportes-fechas"
          >
            <Grid container>
              <Grid item xs={6} className="fecha__desde">
                <Grid container>
                  <Grid item xs={4}>
                    <Typography
                      style={{
                        fontSize: "16px",
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        color: "#0055A6",
                      }}
                    >
                      Fecha desde
                    </Typography>
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    item
                    xs={8}
                  >
                    <FormControl style={{ marginTop: "-2%", width: "70%" }}>
                      <TextField
                        id="fechaDesde"
                        type="date"
                        value={formik.values.fechaDesde}
                        selected={formik.values.fechaDesde}
                        onChange={formik.handleChange}
                        helperText={formik.errors.fechaDesde}
                        error={formik.errors.fechaDesde}
                        excludeDates={populateFeriados(feriados)}
                        disabled={habilitado}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} className="fecha__desde">
                <Grid container>
                  <Grid item xs={4}>
                    <Typography
                      style={{
                        fontSize: "16px",
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        color: "#0055A6",
                      }}
                    >
                      Fecha hasta
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    {" "}
                    <FormControl style={{ marginTop: "-2%", width: "70%" }}>
                      <TextField
                        id="fechaHasta"
                        type="date"
                        value={formik.values.fechaHasta}
                        selected={formik.values.fechaHasta}
                        onChange={formik.handleChange}
                        helperText={formik.errors.fechaHasta}
                        error={formik.errors.fechaHasta}
                        excludeDates={populateFeriados(feriados)}
                        disabled={habilitado}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FormControl>
        </div>

        <br />
        {buscarButton == true && (
          <div className="btn__generar-reporte-container">
            <Button
              style={{
                textTransform: "none",
                width: "50%",
                alignSelf: "flex-end",
                backgroundColor: "#0055a6",
                color: "white",
              }}
              className="btn__generar-reporte"
              onClick={() => {
                formik.handleSubmit();
              }}
              variant="contained"
              disabled={habilitado}
            >
              Buscar
            </Button>
          </div>
        )}
      </div>
      {result === true && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Grid
            container
            style={{
              width: "100%",
            }}
          >
            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
              item
              xs={1}
            ></Grid>
            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
              item
              xs={10}
              className="grid__actualizar-puestos"
            >
              <Box className={classes.root}>
                <Paper className={classes.paper}>
                  <div className={classes.wrapper}>
                    <b className={classes.texto1}>
                      Horario de Break de la Sucursal
                    </b>
                    <p className={classes.texto2}>
                      {" "}
                      {horarioDeBreakDeSucursal}
                    </p>
                  </div>
                  <p className={classes.texto3}>
                    *Para el caso de poseer excepciones registradas en la fecha
                    consultada el horario de break es el correspondiente a la
                    excepcion
                  </p>
                </Paper>
              </Box>
            </Grid>
          </Grid>
          <br />

          {excepcionesArray.length == 0 ? (
            <div style={{ alignSelf: "center" }}>
              {" "}
              <ErrorIcon style={{ color: "red", marginLeft: "45%" }} />{" "}
              <p style={{ color: "red" }}>
                No hay excepciones al Horario de break de la Sucursal
              </p>
            </div>
          ) : (
            <div style={{ width: "80%", alignSelf: "center" }}>
              <TablaExcepciones
                excepcionesArray={excepcionesArray}
                eliminarExcepcionFunc={eliminarExcepcionFunc}
              />
            </div>
          )}

          <br />
          <div style={{ marginRight: "27%", alignSelf: "flex-end" }}>
            <Button
              style={{
                textTransform: "none",
                backgroundColor:
                  excepcionesArray.length == 0 ? "#009BDB" : "#C4C4C4",
                color: "white",
              }}
              className="btn__generar-reporte nuevo__horario"
              onClick={() => {
                nuevoHorarioBreakButton();
              }}
              variant="contained"
            >
              Nuevo horario de Break
            </Button>
          </div>
        </div>
      )}
      {nuevoBreakSesion === true && (
        <div
          style={{
            marginTop: "5%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ alignSelf: "center", width: "50%" }}>
            <NuevoBreakSesion
              habilitadoHorasInicioFin={habilitadoHorasInicioFin}
              horaInicio={horaInicio}
              setHoraInicio={setHoraInicio}
              horaFin={horaFin}
              setHoraFin={setHoraFin}
              buscarNuevosHorariosButton={buscarNuevosHorariosButton}
              params={formik.values}
              turnosAfectadosFunc={turnosAfectadosFunc}
              setBuscarNuevosHorariosButton={setBuscarNuevosHorariosButton}
              setHabilitadoHorasInicioFin={setHabilitadoHorasInicioFin}
              setNuevosHorariosDeBreak={setNuevosHorariosDeBreak}
              turnosAfectadosArray={turnosAfectadosArray}
              nuevosHorariosDeBreak={nuevosHorariosDeBreak}
              swalWarning={swalWarning}
              horarioDeBreakDeSucursal={horarioDeBreakDeSucursal}
              idCajaBysucursal={idCajaBysucursal}
            />
          </div>
        </div>
      )}
    </Container>
  );
};
export default AdministrarBreak;
