import React from "react";
import { Grid, Paper, Box } from "@material-ui/core";
import { Typography, MenuItem, Select, Button } from "@material-ui/core";
import {
  TableRow,
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableBody,
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import { makeStyles } from "@material-ui/core/styles";
import { arraySelectDuracion } from "./utils";

const NuevoBreakSesion = ({
  habilitadoHorasInicioFin,
  horaInicio,
  setHoraInicio,
  horaFin,
  setHoraFin,
  buscarNuevosHorariosButton,
  params,
  turnosAfectadosFunc,
  setBuscarNuevosHorariosButton,
  setHabilitadoHorasInicioFin,
  setNuevosHorariosDeBreak,
  turnosAfectadosArray,
  nuevosHorariosDeBreak,
  swalWarning,
  horarioDeBreakDeSucursal,
  idCajaBysucursal,
}) => {
  const { fechaDesde, fechaHasta, sucursalId, tipoCaja } = params;
  const useStyles2 = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      marginBottom: "2%",

      width: "114%",
      height: theme.spacing(15),
      marginLeft: "7%",

      [theme.breakpoints.down("sm")]: {
        marginLeft: "-24%",
        width: "210%",
        marginBottom: "10%",
      },
    },
    paper: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: theme.spacing(18),
      },
    },
    wrapper: {
      display: "flex",
      justifyContent: "space-between",
      width: "80%",
      marginTop: "2%",
    },
    texto1: {
      color: "#0055A6",
      justifyContent: "center",
      marginTop: "5%",
    },
    texto2: {
      justifyContent: "center",
      marginTop: "5%",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "2%",
      },
    },
    texto3: {
      color: "red",
      fontSize: "13px",
      margin: "5px",
      padding: "0 7%",
    },
  }));
  const classes2 = useStyles2();

  const arrayDuracionDesde = arraySelectDuracion?.filter(
    (item) => item !== "17:00"
  );
  const arrayDuracionHasta = arraySelectDuracion?.filter(
    (item) => item >= horaInicio
  );

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div style={{ width: "90%", alignSelf: "center" }}>
        <Grid container>
          <Grid item xs={6} className="container__hora">
            <Grid container>
              <Grid item xs={6} className="container__hora">
                <Typography
                  style={{
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    fontWeight: 700,
                  }}
                >
                  Nueva hora inicio Break
                </Typography>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
                item
                xs={6}
                className="container__hora"
              >
                <Select
                  disabled={habilitadoHorasInicioFin}
                  name="horaInicio"
                  placeholder="Hora inicio"
                  value={horaInicio}
                  style={{ width: "65%", border: "1px solid grey" }}
                  id="horaDesde"
                  onChange={(event) => {
                    setHoraInicio(event.target.value);
                  }}
                >
                  {arrayDuracionDesde &&
                    arrayDuracionDesde.map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} className="container__hora">
            <Grid container>
              <Grid item xs={6} className="container__hora">
                <Typography
                  style={{
                    marginLeft: "25%",
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    fontWeight: 700,
                  }}
                  className="nomarginleft"
                >
                  Nueva hora fin Break
                </Typography>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
                item
                xs={6}
                className="container__hora"
              >
                <Select
                  disabled={habilitadoHorasInicioFin}
                  name="horaFin"
                  placeholder="Hora fin"
                  value={horaFin}
                  style={{
                    width: "65%",
                    border: "1px solid grey",
                    alignSelf: "flex-end",
                  }}
                  className="noflexend"
                  id="horaHasta"
                  onChange={(event) => {
                    setHoraFin(event.target.value);
                  }}
                >
                  {arrayDuracionHasta &&
                    arrayDuracionHasta.map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <br />
      <div
        style={{
          marginRight: "5%",
          alignSelf: "flex-end",
          marginBottom: "2%",
          width: "18%",
        }}
        className="btn__generar-reporte-container"
      >
        <Button
          disabled={horaFin === "09:00" ? true : false}
          style={{
            textTransform: "none",
            backgroundColor:
              buscarNuevosHorariosButton === false ? "#0055a6" : "#C4C4C4",
            color: "white",
            width: "100%",
          }}
          className="btn__generar-reporte"
          onClick={() => {
            setNuevosHorariosDeBreak(true);
            setHabilitadoHorasInicioFin(true);
            setBuscarNuevosHorariosButton(true);
            turnosAfectadosFunc(
              fechaHasta,
              fechaDesde,
              horaFin,
              horaInicio,
              sucursalId,
              tipoCaja
            );
          }}
          variant="contained"
        >
          Buscar
        </Button>
      </div>
      {nuevosHorariosDeBreak == true && (
        <div
          style={{
            width: "95%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Grid
            container
            style={{
              width: "100%",
            }}
          >
            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
              item
              xs={10}
              className="grid__actualizar-puestos"
            >
              <Box className={classes2.root} /* id="texto__contenedor" */>
                <Paper className={classes2.paper}>
                  <div className={classes2.wrapper}>
                    <b className={classes2.texto1}>
                      Horario de Break de la Sucursal
                    </b>
                    <p className={classes2.texto2}>
                      {horarioDeBreakDeSucursal}
                    </p>{" "}
                  </div>
                  <p className={classes2.texto3}>
                    *Para el caso de poseer excepciones registradas en la fecha
                    consultada el horario de break es el correspondiente a la
                    excepcion
                  </p>
                </Paper>
              </Box>
            </Grid>
          </Grid>
          <br />
          <div
            style={{
              alignSelf: "center",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {turnosAfectadosArray.length != 0 ? (
              <div>
                <p>Turnos afectados</p>
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="simple table">
                    <TableHead variant="head">
                      <TableRow>
                        <TableCell id="tableCell" align="center">
                          Trámite
                        </TableCell>
                        <TableCell id="tableCell" align="center">
                          Hora
                        </TableCell>
                        <TableCell id="tableCell" align="center">
                          Contacto
                        </TableCell>
                        <TableCell id="tableCell" align="center">
                          Documento
                        </TableCell>
                        <TableCell id="tableCell" align="center">
                          E-mail
                        </TableCell>

                        <TableCell id="tableCell" align="center">
                          Telefono
                        </TableCell>
                        <TableCell id="tableCell" align="center">
                          Cancelado
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {turnosAfectadosArray &&
                        turnosAfectadosArray.map((item) => (
                          <TableRow>
                            <TableCell
                              id="tableCell"
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {item.caja.tipo.nombre}
                            </TableCell>
                            <TableCell id="tableCell" align="center">
                              {item.hora}
                            </TableCell>
                            <TableCell id="tableCell" align="center">
                              {item.cliente.nombre} {item.cliente.apellido}
                            </TableCell>
                            <TableCell id="tableCell" align="center">
                              {item.cliente.dni}
                            </TableCell>
                            <TableCell id="tableCell" align="center">
                              {item.cliente.email}
                            </TableCell>
                            <TableCell id="tableCell" align="center">
                              {item.cliente.telefono}
                            </TableCell>
                            <TableCell id="tableCell" align="center">
                              {item.cancelado ? "CANCELADO" : "NO"}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : (
              <div>
                {" "}
                <ErrorIcon style={{ color: "red", marginLeft: "45%" }} />{" "}
                <p style={{ color: "#F86436" }}>
                  No hay turnos agendados en el horario indicado.
                </p>
              </div>
            )}
            {turnosAfectadosArray.length != 0 ? (
              <p style={{ color: "#009BDB", alignSelf: "flex-end" }}>
                *Al presionar el botón <strong>Actualizar Break</strong> se
                cancelaran todos los turnos
              </p>
            ) : null}
          </div>
          <div style={{ width: "100%" }}>
            <Grid container className="botones__container">
              <Grid item xs={6} className="botones__vacio"></Grid>
              <Grid item xs={3} className="botones__cancelar">
                <Button
                  style={{
                    textTransform: "none",

                    backgroundColor: "white",
                    color: "#4A4A4A",
                  }}
                  className="btn__generar-reporte"
                  onClick={() => {
                    window.location.reload();
                  }}
                  variant="contained"
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={3} className="botones__generar">
                <Button
                  style={{
                    textTransform: "none",
                    marginRight: "1%",
                    backgroundColor: "#009BDB",
                    color: "white",
                  }}
                  className="btn__generar-reporte"
                  onClick={() => {
                    const obj = {
                      descripcion: "Break Especial",
                      fechaDesde: fechaDesde,
                      fechaHasta: fechaHasta,
                      horaDesde: horaInicio,
                      horaHasta: horaFin,
                      caja: idCajaBysucursal,
                      tipoBloqueo: 2,
                    };
                    swalWarning("confirmarNuevoBreak", obj);
                  }}
                  variant="contained"
                >
                  Actualizar Break
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </div>
  );
};

export default NuevoBreakSesion;
