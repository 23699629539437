import React, { useState, useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { getSucursales } from "api";
import { filterSucursales } from "backOfficeUsuariosCau/components/utils/filterSucursales";
import { reportesProximoTurnoDisponibleSucursales } from "const/constantes";
import { MenuProps, useStyles } from "./utilies";
import { useDispatch } from "react-redux";
import { setSucursalesValues } from "store/slices/sucursalesSlice";
import "./MultiselectProximoTurnoSucursales.css";

function MultipleSelectProximoTurnoSucursales(props) {
  const [options, setOptions] = useState([]);
  const [sucursales, setSucursales] = useState([]);
  // const [error, setError] = useState("");

  const dispatch = useDispatch();

  let sucursalesMultipleSelect = [];

  async function getSucursalesFunc() {
    let suc = [];
    const res = await getSucursales(true);
    suc = res.data;
    const sucursalesFiltradas = filterSucursales(
      reportesProximoTurnoDisponibleSucursales,
      suc
    );

    setSucursales(sucursalesFiltradas);
    filterSucursales(reportesProximoTurnoDisponibleSucursales, suc).map(
      (item) => {
        let string =
          item.nombre +
          " " +
          item.localidad.nombre +
          " " +
          item.direccion +
          " ";
        sucursalesMultipleSelect.push(string);
      }
    );
    setOptions(sucursalesMultipleSelect);
  }

  let sucursalesIdArray = [];

  function addSucursalesId() {
    selected.map((item) => {
      sucursales.map((suc) => {
        let string =
          suc.nombre + " " + suc.localidad.nombre + " " + suc.direccion + " ";
        if (item == string) {
          const newArray = [...sucursalesIdArray];
          newArray.push(suc.id);
          sucursalesIdArray = newArray;
        }
      });
    });
    dispatch(setSucursalesValues(sucursalesIdArray));
  }

  const handleChange = (event) => {
    const value = event.target.value;
    props.onChange(value);
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : options);
      return;
    }
    setSelected(value);
    addSucursalesId();
  };

  useEffect(() => {
    try {
      getSucursalesFunc();
      addSucursalesId();
    } catch (err) {
      console.log(err);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    try {
      addSucursalesId();
    } catch (err) {
      console.log(err);
    }
  }, [sucursalesIdArray]); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles();

  const [selected, setSelected] = useState([]);
  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel
        style={{ fontSize: "20px" }}
        id="mutiple-select-label"
        className="grid__label"
      >
        {props.title}
      </InputLabel>
      <Select
        disabled={props.habilitado}
        labelId="mutiple-select-label"
        multiple
        value={selected}
        onChange={handleChange}
        renderValue={(selected) => selected.join(", ")}
        error={props.error}
        MenuProps={MenuProps}
      >
        <MenuItem
          value="all"
          classes={{
            root: isAllSelected ? classes.selectedAll : "",
          }}
        >
          <ListItemIcon>
            <Checkbox
              style={{ color: "blue" }}
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                selected.length > 0 && selected.length < options.length
              }
            />
          </ListItemIcon>

          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary="TODAS LAS SUCURSALES"
          />
        </MenuItem>

        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <ListItemIcon>
              <Checkbox
                style={{ color: "blue" }}
                checked={selected.indexOf(option) > -1}
              />
            </ListItemIcon>

            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MultipleSelectProximoTurnoSucursales;
