import React, { useState, useEffect } from "react";
import { Grid, Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { Typography, FormControl, Button } from "@material-ui/core";
import Header from "usuariosOnline/components/Header/Header";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles } from "@material-ui/core/styles";
import SesionExpirada from "backOfficeUsuariosCau/components/SesionExpirada/SesionExpirada";
import MultipleSelectCaja from "backOfficeUsuariosCau/components/MultiSelect/MultipleSelectCaja.js";
import MultipleSelectReporteSucursales from "backOfficeUsuariosCau/components/Reportes/MultipleSelectReporteSucursales";
import TablaVerTurnosEnElDia from "./TablaVerTurnosEnElDia";
import Cronometro from "./Cronometro";
import "./VerTurnosEnElDia.css";
import { format } from "date-fns";
import { usuarioAtencionActiva } from "api";
import PopupTramiteEnCurso from "./PopupTramiteEnCurso";
import ButtonEscape from "const/ButtonEscape";

const VerTurnosEnElDia = () => {
  const welcome = "Bienvenido !";
  const [errorSucursales, setErrorSucursales] = useState("");
  const [changeSucursal, setChangeSucursal] = useState([]);
  const [errorTipoTramite, setErrorTipoTramite] = useState("");
  const [changeTramite, setChangeTramite] = useState([]);
  //const [habilitado, setHabilitado] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [turnoSeleccionado, setTurnoSeleccionado] = useState("");
  const [finalizarTramite, setFinalizarTramite] = useState(false);
  const [turnoActivo, setTurnoActivo] = useState(false);
  const [reload, setReload] = useState(false);
  const [resetTabla, setResetTabla] = useState(false);
  const fecha = format(new Date(), "yyyy-MM-dd");
  const [tramiteEnCurso, setTramiteEnCurso] = useState(false);

  const sucursalesRender = JSON.parse(
    localStorage.getItem("sucursalesFiltradasReRender")
  );
  const sucursalesReRenderizadas = sucursalesRender?.length + 1;

  const cajasRender = JSON.parse(localStorage.getItem("tipoCajaReRender"));
  const cajasReRenderizadas = cajasRender?.length + 1;

  const handleChangeSucursales = (value) => {
    setChangeSucursal(value);
  };

  const handleChangeTramites = (value) => {
    setChangeTramite(value);
  };

  const formik = useFormik({
    initialValues: {
      sucursalId: [],
      tipoCaja: [],
    },

    onSubmit: (values) => {
      const { sucursalId, tipoCaja, fechaDesde, fechaHasta } = values;
    },
  });

  const handleGenerarReporte = async () => {
    if (
      changeSucursal.length === 0 ||
      changeSucursal.length === sucursalesReRenderizadas
    ) {
      setErrorSucursales(" ");
    }

    if (
      changeTramite.length === 0 ||
      changeTramite.length === cajasReRenderizadas
    ) {
      setErrorTipoTramite(" ");
    }
    if (
      changeSucursal.length > 0 &&
      changeTramite.length > 0 &&
      changeTramite.length !== cajasReRenderizadas &&
      changeSucursal.length !== sucursalesReRenderizadas
    ) {
      try {
        setShowResults(true);
        // setHabilitado(true);
      } catch (err) {
        if (err.response.status !== 400 && err.response.status !== 401) {
          window.alert(
            "No se puede obtener la información solicitada en estos momentos, por favor intentá nuevamente más tarde."
          );
        }
      }
    }
  };

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
    },
    textField: {
      marginTop: theme.spacing(6),
    },
    table: {},
    customOutline: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "grey",
        borderRadius: "1px",
        padding: "1px",
      },
    },
    root: {
      display: "flex",
      flexWrap: "wrap",
      marginBottom: "2%",
      "& > *": {
        width: "55%",
        height: theme.spacing(15),
        marginLeft: "23%",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "-24%",
        width: "196%",
      },
    },
    paper: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        with: "100%",
        height: theme.spacing(18),
      },
    },
    wrapper: {
      display: "flex",
      justifyContent: "space-between",
      width: "80%",
      marginTop: "2%",
    },
    texto1: {
      color: "#0055A6",
      justifyContent: "center",
      marginTop: "5%",
    },
    texto2: {
      justifyContent: "center",
      marginTop: "5%",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "2%",
      },
    },
    texto3: {
      color: "red",
      fontSize: "13px",
      margin: "5px",
      padding: "0 7%",
    },
  }));
  const classes = useStyles();

  async function usuarioInfoTramiteEnCurso() {
    const res = await usuarioAtencionActiva();
    setTramiteEnCurso(res.data.bloqueo);
  }

  const fechaQuellega = tramiteEnCurso?.fecha
    ?.slice(0, 10)
    .split("-")
    .reverse()
    .join("/");
  const horaQuellega = tramiteEnCurso?.fecha?.slice(11, 19);

  useEffect(() => {
    usuarioInfoTramiteEnCurso();
  }, []);
  return (
    <>
      {tramiteEnCurso && (
        <div className="container__cronometro">
          <div className="cronometro">
            <PopupTramiteEnCurso
              numeroTurno={tramiteEnCurso.idReferencia}
              horaTurno={horaQuellega}
              fechaTurno={fechaQuellega}
            />
          </div>
        </div>
      )}

      {turnoActivo && (
        <div className="container__cronometro">
          <div className="cronometro">
            <Cronometro
              setTurnoActivo={setTurnoActivo}
              numeroTurno={turnoSeleccionado.id}
              horaTurno={turnoSeleccionado.hora}
              reload={reload}
              setReload={setReload}
              setShowResults={setShowResults}
            />
          </div>
        </div>
      )}
      <Container fixed>
        <div>
          <Header welcome={welcome} button={<ButtonEscape />} />
        </div>
        <SesionExpirada timer={3600000} />
        <div
          style={{
            marginTop: "3%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Grid
            container
            style={{ marginLeft: "2%" }}
            className="grid__container-header-reporte"
          >
            <Grid item xs={3}>
              <Link to="/backOfficeL" style={{ textDecoration: "none" }}>
                <Button
                  startIcon={<ArrowBackIcon />}
                  style={{
                    textTransform: "none",
                    backgroundColor: "white",
                    color: "#009BDB",
                  }}
                >
                  Volver
                </Button>
              </Link>
            </Grid>
            <Grid item xs={9}>
              <Typography
                style={{
                  marginLeft: "2%",
                  color: "#4A4A4A",
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                  fontSize: "18px",
                  lineHeight: "21.09px",
                }}
                className="title__reportes-header"
              >
                Atención de turnos al {fecha?.split("-").reverse().join("/")}
              </Typography>
            </Grid>
          </Grid>
        </div>

        <Grid container>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            item
            xs={12}
          >
            <form
              onSubmit={formik.handleSubmit}
              style={{ alignSelf: "center", width: "45%", marginTop: "1%" }}
              className="form__Reportes-multiple"
            >
              <FormControl className={classes.formControl}>
                <MultipleSelectCaja
                  habilitado={false}
                  title={"Tipo de Trámite "}
                  error={errorTipoTramite}
                  onChange={(value) => {
                    handleChangeTramites(value);
                    setErrorTipoTramite();
                  }}
                  cajaHabilitada={true}
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <MultipleSelectReporteSucursales
                  habilitado={false}
                  title={"Elegí el Centro de Atención"}
                  error={errorSucursales}
                  onChange={(value) => {
                    handleChangeSucursales(value);
                    setErrorSucursales("");
                  }}
                  true={""}
                />
              </FormControl>
            </form>
          </Grid>
        </Grid>

        <br />
        <div
          style={{ width: "100%", marginBottom: "5%" }}
          className="btn__generar-reporte-container"
        >
          <Button
            style={{
              textTransform: "none",
              marginLeft: "76%",
              alignSelf: "center",
              backgroundColor: "#0055a6",
              color: "white",
              width: "15%",
            }}
            className="btn__generar-reporte"
            onClick={handleGenerarReporte}
            variant="contained"
            // disabled={habilitado}
          >
            Buscar
          </Button>
        </div>
        {showResults === true && (
          <TablaVerTurnosEnElDia
            fecha={fecha}
            setTurnoActivo={setTurnoActivo}
            resetTabla={resetTabla}
            setFinalizarTramite={setFinalizarTramite}
            reload={reload}
            setReload={setReload}
            setTurnoSeleccionado={setTurnoSeleccionado}
            turnoSeleccionado={turnoSeleccionado}
            setShowResults={setShowResults}
            handleGenerarReporte={handleGenerarReporte}
            showResults={showResults}
          />
        )}
      </Container>
    </>
  );
};

export default VerTurnosEnElDia;
