import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sucursalesArray: [],
};

export const sucursalesSlice = createSlice({
  name: "sucursales",
  initialState: initialState,
  reducers: {
    setSucursalesValues: (state, action) => {
      state.sucursalesArray = action.payload;
    },
    resetSucursalesValues: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const { setSucursalesValues, resetSucursalesValues } =
  sucursalesSlice.actions;

export default sucursalesSlice.reducer;
