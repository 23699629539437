import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import { Button, Paper, Divider } from "@material-ui/core";
import FormContacto from "usuariosOnline/components/FormContacto/FormContacto";
import FormTurnos from "usuariosOnline/components/FormTurnos/FormTurnos";
import TurnoConfirmado from "usuariosOnline/components/TurnoConfirmado/TurnoConfirmado";
import { resetCajaValues } from "store/slices/cajaSlice";
import { resetUserValues } from "store/slices/userSlice";
import { resetTurnoValues } from "store/slices/turnoSlice";
import { resetTurnoConfirmadoValues } from "store/slices/turnoConfirmadoSlice";
import SelectTipoCaja from "usuariosOnline/components/SelectTipoCaja/SelectTipoCaja";
import swal from "sweetalert2";
import ReactGA from "react-ga";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  button: {
    marginTop: theme.spacing(1),
    border: "1px solid",
    fontFamily: "Roboto",
  },
  nextButton: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(3),
    fontFamily: "Roboto",
    color: "white",
    background: "#009bdb",
    "&:hover": {
      background: "#009bdb",
    },
  },
  newTurnButton: {
    marginTop: theme.spacing(1),
    fontFamily: "Roboto",
    color: "white",
    background: "#009bdb",
    "&:hover": {
      background: "#009bdb",
    },
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
  },
}));

function getSteps() {
  return ["Datos de contacto", "Tipo de trámite", "Centro de Atención"];
}

function getStepContent(step, userStep) {
  switch (step) {
    case 0:
      return <FormContacto />;
    case 1:
      return (
        (userStep && <SelectTipoCaja />) || "Complete información de contacto"
      );
    case 2:
      return (userStep && <FormTurnos />) || "Complete el paso anterior";
    case 3:
      return <TurnoConfirmado />;
    default:
      return "Unknown step";
  }
}

if (
  navigator.userAgent.toLowerCase().indexOf("MSIE") > -1 ||
  navigator.userAgent.indexOf("Trident") > -1
) {
  window.alert(
    "Recomendamos abrir esta aplicación con Google Chrome u otro navegador"
  );
}

export const Home = () => {
  const userStep = useSelector((state) => state.user.submitted);
  const tipoCajaStep = useSelector((state) => state.caja.submitted);
  const turnoConfirmado = useSelector(
    (state) => state.turnoConfirmado.submitted
  );
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const dispatch = useDispatch();

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const isUsuarioApp = query.get("userApp");

  const handleReset = () => {
    setActiveStep(0);
    dispatch(resetUserValues());
    dispatch(resetCajaValues());
    dispatch(resetTurnoValues());
    dispatch(resetTurnoConfirmadoValues());
  };

  const handleCancel = () => {
    swal
      .fire({
        title: "Cancelar Operación",
        text: "¿Está seguro que desea salir de esta operación?",
        icon: "warning",
        confirmButtonColor: "#009bdb",
        confirmButtonText: "Sí",
        showCancelButton: true,
        cancelButtonText: "No",
        cancelButtonColor: "#b7b7b7",
        animation: true,
      })
      .then((result) => {
        if (result.value) {
          handleReset();
          window.location.reload();
        }
      });
  };
  const checkStep = (step) => {
    if (step === 0 && !userStep) {
      return true;
    } else if (step === 1 && !tipoCajaStep) {
      return true;
    } else if (step === 2 && !turnoConfirmado) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    ReactGA.initialize("UA-183061571-1");
    ReactGA.pageview("/");
  }, []);
  return (
    <div className={classes.root}>
      <div>
        <div
          style={{ marginTop: "2%", marginBottom: "2%", paddingLeft: "10%" }}
        >
          <img
            src="https://www.aysa.com.ar/assets/Menu/img/logo.png"
            alt="aysa logo"
          />
        </div>
        <Divider />
        <h1
          style={{ fontFamily: "Roboto", paddingLeft: "10%", fontWeight: 100 }}
        >
          Reservá tu turno para ir al Centro de Atención
        </h1>
      </div>
      <div style={{ paddingLeft: "10%" }}>
        <Divider />
      </div>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div style={{ paddingLeft: "10%" }}>
        <Divider />
      </div>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Paper square elevation={0} className={classes.resetContainer}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <TurnoConfirmado displayNone={true} />
              </div>
              {isUsuarioApp === null && (
                <p>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      onClick={handleReset}
                      className={classes.newTurnButton}
                      variant="contained"
                      color="primary"
                      style={{
                        width: "250px",
                        height: "37px",
                        marginTop: "-6px",
                      }}
                    >
                      Solicitar nuevo turno
                    </Button>
                  </div>
                </p>
              )}
            </Paper>
          </div>
        ) : (
          <div>
            <div>{getStepContent(activeStep, userStep)}</div>
            <div className={classes.actionsContainer}>
              <div style={{ marginTop: "1%", alignSelf: "center" }}>
                <Button
                  variant="outlined"
                  onClick={handleCancel}
                  className={classes.button}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  className={classes.nextButton}
                  disabled={checkStep(activeStep)}
                >
                  Siguiente
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
