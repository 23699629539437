import React, { useState, useEffect } from "react";
import { Grid, Container, Paper, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  getSucursalesByTipoCaja,
  getTipoCaja,
  findCajasBySucursalTipoTramite,
  updateCaja,
} from "api";
import { useFormik } from "formik";
import {
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
} from "@material-ui/core";
import { filterSucursales } from "backOfficeUsuariosCau/components/utils/filterSucursales";
import { sucursalesActualizarPuestosFiltradaArray } from "const/constantes";
import Header from "usuariosOnline/components/Header/Header";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert2";
import "./AdministrarPuestosTotales.css";
import SesionExpirada from "backOfficeUsuariosCau/components/SesionExpirada/SesionExpirada";
import ButtonEscape from "const/ButtonEscape";

const AdministrarPuestosTotales = () => {
  const welcome = "Bienvenido !";

  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        display: "flex",
        flexWrap: "wrap",
        marginBottom: "2%",
        "& > *": {
          width: "50%",
          height: theme.spacing(9),
          marginLeft: "25%",
        },
        [theme.breakpoints.down("sm")]: {
          marginLeft: "-24%",
          width: "196%",
        },
      },
      paper: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
          with: "100%",
        },
      },
      texto1: {
        color: "#0055A6",
        justifyContent: "start",
        marginLeft: "4%",
      },
      texto2: {
        justifyContent: "end",
        marginRight: "6%",
      },
      texto3: {
        width: "43%",
        marginLeft: "29%",
        fontSize: "12px",
        marginTop: "4%",
        color: "#F86436",
        [theme.breakpoints.down("sm")]: {
          width: "73%",
          marginLeft: "15%",
          marginTop: "10%",
        },
      },
    })
  );

  const classes = useStyles();

  const [sucursales, setSucursales] = useState([]);
  const [tipoCajas, setTipoCajas] = useState([]);
  const [sucId, setSucId] = useState(1);
  const [tramId, setTramId] = useState(2);
  const [deshabilitarButton, setDeshabilitarButton] = useState(false);
  const [buscarButton, setBuscarButton] = useState(true);
  const [result, setResult] = useState(false);
  const [cantidad, setCantidad] = useState("");
  const [idCaja, setIdCaja] = useState();
  const [cantidadTotal, setCantidadTotal] = useState();
  const [cantidadHabilitada, setCantidadHabilitada] = useState();
  const [duracion, setDuracion] = useState();
  const [errorTextfield, setErrorTextfield] = useState();
  const [helperText, setHelperText] = useState("");
  const [sucursalIdHelperText, setSucursalIdHelperText] = useState("");
  const [tramiteIdHelperText, setTramiteIdHelperText] = useState("");
  const [tramiteIdError, setTramiteIdError] = useState(false);
  const [sucursalIdError, setSucursalIdError] = useState(false);
  const [sucursalNombre, setSucursalNombre] = useState("");

  async function getSucursalesFunc(caja) {
    let suc = [];
    const res = await getSucursalesByTipoCaja(caja);
    suc = res.data;

    setSucursales(
      filterSucursales(sucursalesActualizarPuestosFiltradaArray, suc)
    );
  }

  async function getTipoCajaFunc() {
    const res = await getTipoCaja(true);
    setTipoCajas(res.data);
  }

  async function findCajasBySucursalTipoTramiteFunc(trameId, sucurlId) {
    try {
      const res = await findCajasBySucursalTipoTramite(trameId, sucurlId);
      setCantidad(res.data.cantidadHabilitada);
      setIdCaja(res.data.id);
      setCantidadTotal(res.data.cantidadTotal);
    } catch (e) {
      setResult(false);
      setBuscarButton(true);
    }
  }
  async function actualizarCajaFunc(obj) {
    try {
      await updateCaja(obj);
      setCantidad(cantidadHabilitada);
      setCantidadTotal(cantidadHabilitada);
      successUpdateCajaSwal();
    } catch (e) {
      if (e.response.status !== 401) {
        swalErrorBackendEndPoint();
      }
    }
  }

  useEffect(() => {
    try {
      getTipoCajaFunc();
      getSucursalesFunc(tramId);
      findCajasBySucursalTipoTramiteFunc(tramId, sucId);
    } catch (err) {
      console.log(err);
    }
  }, [tramId, sucId]);

  const formik = useFormik({
    initialValues: {
      sucursalId: "",
      tramiteId: "",
    },
    onSubmit: (values) => {
      const { sucursalId, tramiteId } = values;
      if (tramiteId !== "" && sucursalId !== "") {
        findCajasBySucursalTipoTramiteFunc(tramiteId, sucursalId);
        setResult(true);
      } else {
        setBuscarButton(true);
      }
    },
  });

  function actualizarButton() {
    if (document.getElementById("textfield").value === "") {
      setErrorTextfield(true);
      setHelperText();
    } else {
      setHelperText("");
      setErrorTextfield(false);
      setDeshabilitarButton(true);
      swalWarning();
    }
  }

  function validarCampos() {
    if (formik.values.sucursalId === "" || formik.values.tramiteId === "") {
      setTramiteIdError(true);
      setSucursalIdError(true);
      setSucursalIdHelperText("Requerido");
      setTramiteIdHelperText("Requerido");
    } else {
      setTramiteIdError(false);
      setSucursalIdError(false);
      setBuscarButton(false);
      formik.handleSubmit();
    }
  }

  function swalErrorBackendEndPoint() {
    swal.fire({
      icon: "warning",
      title: "Advertencia",
      text: `ANTES DE DISMINUIR LA CANTIDAD DE PUESTOS TOTALES, DEBERÁ ACTUALIZAR LOS VALORES DEL MODULO: "ADMINISTRAR PUESTOS" en el Apartado: Cantidad de puestos a habilitar de lunes a viernes, A LA CANTIDAD QUE DESEE ACTUALIZAR`,
      confirmButtonColor: "#009bdb",
    });
    setResult(true);
  }

  function successUpdateCajaSwal() {
    swal
      .fire({
        icon: "success",
        title: "Actualización Éxitosa",
        text: ` La actualización de puestos en ${sucursalNombre} se ha realizado éxitosamente.`,
        confirmButtonColor: "#009bdb",
      })
      .then(() => {
        setResult(true);
      });
  }

  function swalWarning() {
    swal
      .fire({
        position: "center",
        title: "Confirmar Operación",
        text: "¿Está seguro/a que desea actualizar los puestos de atención?",
        icon: "warning",
        confirmButtonColor: "#009bdb",
        confirmButtonText: "Sí",
        showCancelButton: true,
        cancelButtonText: "No",
        cancelButtonColor: "#b7b7b7",
      })
      .then((resultado) => {
        if (resultado.value) {
          const obj = {
            id: idCaja,
            cantidadTotal: Number(cantidadHabilitada),
            cantidadHabilitada: Number(cantidadHabilitada),
          };
          actualizarCajaFunc(obj);
          setResult(false);
          setDeshabilitarButton(false);
        } else {
          setDeshabilitarButton(false);
        }
      });
  }

  return (
    <Container fixed>
      <div>
        <Header welcome={welcome} button={<ButtonEscape />} />
      </div>
      <SesionExpirada timer={900000} />
      <div
        style={{
          marginTop: "2%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <br />
        <Grid
          container
          style={{
            marginLeft: "2%",
          }}
          className="title__header"
        >
          <Grid item xs={2}>
            <Link to="/backOfficeL" style={{ textDecoration: "none" }}>
              <Button
                startIcon={<ArrowBackIcon />}
                style={{
                  textTransform: "none",
                  backgroundColor: "white",
                  color: "#009BDB",
                }}
              >
                Volver
              </Button>
            </Link>
          </Grid>
          <Grid item xs={10}>
            <p
              style={{
                marginTop: "5px",
                fontSize: "19px",
                marginLeft: "12.5%",
                fontWeight: "bold",
              }}
            >
              Administrar Puestos Totales
            </p>
          </Grid>
        </Grid>
        <br />

        <Grid
          container
          style={{
            width: "100%",
          }}
        >
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            item
            xs={1}
          ></Grid>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            item
            xs={10}
          >
            <form
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
              onSubmit={formik.handleSubmit}
            >
              <FormControl
                style={{
                  alignSelf: "center",
                  width: "50%",
                  marginTop: "-2%",
                }}
                className="label__actualizar-puestos"
              >
                <InputLabel>
                  <p
                    style={{
                      marginTop: "-2%",
                      fontSize: "19px",
                    }}
                  >
                    Tipo atención
                  </p>
                </InputLabel>
                <Select
                  error={tramiteIdError}
                  helperText={tramiteIdHelperText}
                  id="tramiteId"
                  name="tramiteId"
                  style={{
                    marginBottom: "15px",
                    width: "100%",
                  }}
                  onChange={formik.handleChange}
                  value={formik.values.tramiteId}
                >
                  {tipoCajas &&
                    tipoCajas.map((tipoCaja) => (
                      <MenuItem
                        style={{ fontSize: "11pt", fontFamily: "Roboto" }}
                        key={`tipoCaja_${tipoCaja.id}`}
                        value={tipoCaja.id}
                        onClick={() => {
                          setDeshabilitarButton(false);
                          setTramId(tipoCaja.id);

                          if (result === false && buscarButton === false) {
                            setResult(true);
                          }
                        }}
                      >
                        {tipoCaja.nombre}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </form>
          </Grid>
        </Grid>
        <br />

        <Grid
          style={{
            width: "100%",
          }}
          container
        >
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            item
            xs={1}
          ></Grid>
          <Grid item xs={10}>
            <form
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
              onSubmit={formik.handleSubmit}
            >
              <FormControl
                style={{
                  alignSelf: "center",
                  width: "50%",
                  marginTop: "-2%",
                }}
                className="label__actualizar-puestos"
              >
                <InputLabel>
                  <p
                    style={{
                      marginTop: "-2%",
                      fontSize: "19px",
                    }}
                  >
                    Elegí el Centro de Atención
                  </p>
                </InputLabel>
                <Select
                  error={sucursalIdError}
                  helperText={sucursalIdHelperText}
                  id="sucursalId"
                  name="sucursalId"
                  style={{
                    marginBottom: "15px",
                    width: "100%",
                  }}
                  onChange={formik.handleChange}
                  value={formik.values.sucursalId}
                >
                  {sucursales &&
                    sucursales.map((sucursal) => (
                      <MenuItem
                        style={{ fontSize: "11pt", fontFamily: "Roboto" }}
                        key={`sucursal_${sucursal.id}`}
                        value={sucursal.id}
                        onClick={() => {
                          setDeshabilitarButton(false);
                          setSucId(sucursal.id);
                          setHelperText("");
                          setErrorTextfield(false);
                          setSucursalNombre(
                            sucursal.nombre +
                              " - " +
                              sucursal.localidad.nombre +
                              " - " +
                              sucursal.direccion
                          );

                          if (result === false && buscarButton === false) {
                            setResult(true);
                          }
                        }}
                      >
                        {sucursal.localidad.nombre === "GRANDES USUARIOS"
                          ? ` ${sucursal.nombre} - ${sucursal.localidad.nombre}`
                          : ` ${sucursal.nombre} - ${sucursal.localidad.nombre} - ${sucursal.direccion}`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <Grid container>
                <Grid item xs={9}></Grid>

                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                  item
                  xs={3}
                >
                  {buscarButton === true && (
                    <Button
                      style={{
                        marginRight: "150%",
                        alignSelf: "center",
                        backgroundColor: "#0055a6",
                        color: "white",
                        width: "50%",
                        marginTop: "5%",
                      }}
                      className="btn__buscar-actualizar-puestos"
                      onClick={() => {
                        validarCampos();
                      }}
                      variant="contained"
                    >
                      Buscar
                    </Button>
                  )}{" "}
                </Grid>
              </Grid>
              <br />
            </form>
          </Grid>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            item
            xs={1}
          ></Grid>
        </Grid>
        <br />
        <br />

        {result === true && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignSelf: "center",
              width: "100%",
            }}
          >
            <Grid
              container
              style={{
                width: "100%",
              }}
            >
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
                item
                xs={1}
              ></Grid>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
                item
                xs={10}
                className="grid__actualizar-puestos"
              >
                <Box className={classes.root}>
                  <Paper className={classes.paper}>
                    <b className={classes.texto1}>
                      {" "}
                      Cantidad de puestos máximos por día:
                    </b>
                    <p className={classes.texto2}> {cantidadTotal}</p>
                  </Paper>
                </Box>
                <Box className={classes.root}>
                  <div className={classes.paper}>
                    <b className={classes.texto1}>
                      Nueva cantidad máxima de puestos por día:
                    </b>
                    <FormControl
                      className="form-control__administrar-puestos-totales"
                      style={{
                        color: "white",
                        textTransform: "none",
                        padding: "1%",
                        marginTop: "1%",
                        width: "16%",
                        textAlign: "center",
                      }}
                    >
                      <TextField
                        name="input"
                        type="number"
                        placeholder="0"
                        InputProps={{
                          inputProps: { min: 1, max: 900 },
                        }}
                        onChange={(event) => {
                          setCantidadHabilitada(event.target.value);
                          setErrorTextfield(false);
                          setHelperText("");
                        }}
                        error={errorTextfield}
                        helperText={helperText}
                        disabled={deshabilitarButton}
                        id="textfield"
                        variant="outlined"
                      />
                    </FormControl>
                  </div>
                </Box>
              </Grid>
            </Grid>

            <div
              style={{
                alignSelf: "end",
                marginRight: "29%",
                marginTop: "2%",
              }}
              className="btn__act-actualizar-puestos"
            >
              <Button
                disabled={deshabilitarButton}
                style={{
                  backgroundColor: "#0055a6",
                  color: "white",

                  textTransform: "none",
                }}
                onClick={() => actualizarButton()}
                variant="contained"
              >
                Guardar
              </Button>
            </div>
          </div>
        )}

        <br />
      </div>
    </Container>
  );
};
export default AdministrarPuestosTotales;
