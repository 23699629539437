import axios from "axios";

export const api = axios.create({
  baseURL: "/api/v1/",
  headers: {
    "Content-Type": "application/json",
  },
});

export const dataTurnoOnlineParaCancelar = async (token) => {
  console.log(token, "get");
  const url = `turnos/cancelar?token=${token}`;
  return await api.get(url);
};

export const cancelarTurnoOnline = async (token) => {
  console.log(token, "delete");
  const url = `turnos/cancelar?token=${token}`;
  return await api.delete(url);
};
