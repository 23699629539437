import React from "react";
import { Typography, Button } from "@material-ui/core";

const ButtonWithProgress = ({
  onClick,
  pendingApiCall,
  disabled = false,
  text,
  type = "button",
}) => {
  return (
    <Button
      style={{ width: " 35%", backgroundColor: "#0055A6", color: "white" }}
      variant="contained"
      color="primary"
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {pendingApiCall && (
        <div className="spinner-border text-light spinner-border-sm mr-1">
          <Typography
            variant="body2"
            style={{
              fontWeight: 700,
              fontSize: "14px",
              textTransform: "none",
              fontFamily: "Roboto",
            }}
            component={"span"}
          >
            <span className="sr-only">Loading...</span>
          </Typography>
        </div>
      )}
      <Typography
        variant="body2"
        style={{
          fontSize: "16pt",
          textTransform: "none",
          fontFamily: "Roboto",
        }}
        component={"span"}
      >
        {text}
      </Typography>
    </Button>
  );
};

export default ButtonWithProgress;
